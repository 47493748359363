import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import ResetIcon from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { showMessage } from "../actions/index";
import { compose } from "recompose";
import { connect } from "react-redux";
import { apiRequest, byName } from "../utils/Utils";
import AlertDialog from "../components/AlertDialog";
import TableForMetadata from "../components/TableForMetadata.jsx";
import DeleteIcon from "@material-ui/icons/Delete";
import SubmitIcon from "@material-ui/icons/CheckCircleOutline";
import GoBackIcon from "@material-ui/icons/ArrowBack"

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
    paddingTop: 10,
    paddingBottom: 10
  },
  input: {
    width: "85%",
    padding: "3px",
    borderRadius: "5px",
    outline: "none",
    border: "1px solid lightgrey"
  },
  textField: { width: "100%" },
  menu: {
    width: "100%"
  }
});

class MasterDataManagement extends Component {
  newManufacture = true;
  newModel = true;
  newType = true;
  state = {
    manufacturer: {
      id: "",
      name: ""
    },
    model: {
      id: "",
      name: ""
    },
    type: {
      id: "",
      name: ""
    },
    default: {
      id: "",
      name: ""
    },
    open: false,
    rename_type: "",
    rename_value: "",
    manufacturerData: [],
    modelData: [],
    typeData: {id: "", name: ""},
    openAlert: false,
    openError: false,
    errorMessages: [],
    originalModel: "",
    actionType: "",
    name: "",
    manufacturermodels: [],
    tooltipTypeData: [],
  };

  componentWillMount() {
    this.fetchData("assettypes");
  }

  errorText( functionName, itemCategory ){
    return `Unable to ${functionName} ${itemCategory} as this item already exists`;
  }

  addErrorMessage( addMessage ){
    var errMsg = this.state.errorMessages;
    errMsg.push(addMessage);
    this.setState({ errorMessages: errMsg });
    this.props.showMessageBox(addMessage);
  }

  addShowErrorMessage( addMessage ){
    this.addErrorMessage( addMessage );
    //this.setState({ openError: true });
  }

  redirectNoError(){
    if( !this.state.openError ){
      console.log("redirecting...");
      this.delay(300).then( () => {
        this.props.showMessageBox("SUCCESS");
        this.props.history.push("/master/data/manage");
      });
    }
  }

  delay( time ) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  fetchData(dataType, id = "") {
    const That = this;
    let url =
      dataType === "assetmodels"
        ? `/masters/${dataType}/${id}`
        : `/masters/${dataType}`;

    console.log( "fetchData - type:", dataType);

    apiRequest(url, "get")
      .then(response => {
        const data = response.data.map(c => {
          return {
            id: c.ID,
            name: c.Value
          };
        });
        switch (dataType) {
          case "manufactures":
            this.setState(
              {
                manufacturerData: data.sort(byName)
              },
              () => {
                let id = this.state.manufacturer.id;
                if (id !== "") this.fetchData("assetmodels", id);
              }
            );
            break;
          case "assetmodels":
            this.setState({
              modelData: data.sort(byName)
            });
            break;
          case "assettypes":
            if(this.props.match.params.id == 0) {
              this.setState({typeData: {id: "0", name: ""}});
            } else {
              this.setState({typeData: data.find(type=> type.id == this.props.match.params.id)});
            }
              
            //data already contains 'id' and 'name'
            this.setState({tooltipTypeData: data.sort(byName)});
            
            break;
          case "manufacturermodels":
            this.setState({manufacturermodels: response.data})
        }
      })
      .catch(error => console.log(error));
  }


  handleSave() {
    const { typeData } = this.state;
    const that = this;

    if(typeData.name === ""){
      console.log("ERROR empty type");
      this.addShowErrorMessage("Unable to add empty type");
    }
    else if(this.props.match.params.id == 0) {
      //CREATE NEW
      apiRequest(`/masters/addassettypes?name=${typeData.name}`, "post")
      .then(x => {
        //if (x.status > 0) that.fetchData("assettypes");
        if (x.status > 0 && x.message == null) {
          console.log(`"save" type create resolve`);
          return Promise.resolve();
        }
        else{
          console.log(`"save" type create reject`);
          return Promise.reject( x.message ); 
        }
      })
      .then( x => {
        console.log(`"save" type create redirect`);
        this.redirectNoError();
      })
      .catch(err => {
        console.log(`ERROR "save" type creation fail: ${err}`);
        this.addShowErrorMessage( /*this.errorText("add", "type")*/err );
      });

    } else {
      //EDIT
      const { typeData } = this.state;
      const that = this;
      apiRequest(`/masters/assettypes/${typeData.id}?name=${typeData.name}`, "put")
        .then(x => {
          //if (x.status > 0) that.fetchData(name);
          if (x.status > 0 && x.message == null) {
            console.log(`"save" type edit resolve`);
            return Promise.resolve();
          }
          else{
            console.log(`"save" type edit reject`);
            return Promise.reject( x.message );
          }
        })
        .then(x => {
          console.log(`"save" type edit redirect`);
          this.redirectNoError();
        })
        .catch(err => {
          console.log(`ERROR "save" type edit fail: ${err}`);
          this.addShowErrorMessage( /*this.errorText("rename", "type")*/err );
        });
    }
  }
  
  delete = () => {
    this.setState(
      {
        openAlert: false
      },
      () => {
        this.setState({
          actionType: ""
        });
      }
    );
    const { typeData } = this.state;
    this.handleDeleteRequest("assettypes", typeData);
    this.newType = true;
    // let a = document.querySelectorAll("input");
    // a.forEach(element => {
    //   element.value = "";
    // });
  };

  handleDeleteRequest(name, data) {
    const that = this;
    apiRequest(`/masters/${name}/${data.id}`, "delete")
      .then(x => {
        //if (x.status > 0) that.fetchData(name);
        if (x.status > 0 && x.message == null){
          console.log(`"delete" type resolve`);
          return Promise.resolve();
        } 
        else{
          console.log(`"delete" type reject`);
          return Promise.reject( x.message );
        }
      })
      .then( X => {
        console.log(`"delete" type redirect`);
        this.redirectNoError();
      })
      .catch(err => {
        console.log(`ERROR "delete" type: ${err}`);
        this.addShowErrorMessage( err );
      });
  }
  onChange(e) {
    this.setState({typeData: {id: this.props.match.params.id, name: e.target.value}});
    // this.setState(prevState =>({
    //   typeData: {
    //     ...prevState.typeData,
    //     name: e.target.value
    //   }
    // }));

    //console.log("tooltipTypeData", this.state.tooltipTypeData);
  }

  handleClickOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleReset = (index, name) => {
    if (name === "manufacturer") this.newManufacture = true;
    else if (name === "model") this.newModel = true;
    else if (name === "type") this.newType = true;

    this.setState(
      {
        [name]: { ...this.state.default }
      },
      () => {
        if (name === "manufacturer") {
          this.setState({
            modelData: []
          });
          //this.handleReset(1,"model");
          let a = document.querySelectorAll("input");
          a[index + 1].value = "";
        }
      }
    );

    let a = document.querySelectorAll("input");
    a[index].value = "";
  };

  render() {
    const { classes } = this.props;
    const { manufacturerData, modelData, typeData, tooltipTypeData, manufacturermodels } = this.state;

    console.log("state-tooltipTypeData", this.state.tooltipTypeData);
    console.log("tooltipTypeData", tooltipTypeData);

    return (
      <div
        id="content"
        style={{
          //paddingTop: "60px",
          transition: "0.25s",
          paddingLeft: "0px",
        }}
      >

        {/** ERROR dialog for displaying error messages */}
        <AlertDialog
          open ={ this.state.openError }
          isError ={ true }
          subText ={ " " }
          close ={  ()=> { 
            this.setState({ openError: false });
            this.setState({ errorMessages:[] });
          }}
        >
          { this.state.errorMessages.length > 0 &&
            this.state.errorMessages.map( (msg) => {
              return <div>{msg}</div>
            })
          }
        </AlertDialog>

        <AlertDialog
          open={this.state.openAlert}
          onClickYes={
            this.state.actionType == "ADD"
              ? this.add
              : this.state.actionType == "RENAME"
              ? this.rename
              : this.delete
          }
          action={
            this.state.actionType == "ADD"
              ? "Save"
              : this.state.actionType == "RENAME"
              ? "Rename"
              : "Delete"
          }
          subText={
            this.state.actionType == "ADD"
              ? ""
              : `This action will affect all the existing devices having this ${
                  this.state.actionType == "RENAME"
                    ? this.state.rename_type
                    : this.state.name
                } name.
                Do you still want to delete it?`
          }
          close={() => {
            this.setState({ openAlert: false });
          }}
        />


        <Grid
              container
              spacing={2}
              style={{margin: "0px 27px", width: "auto"}
              }
            >
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <div className="float-left">
                  <h5
                    style={{
                      color: "#4b2e83",
                      width: "100%",
                      // paddingLeft: 2,
                      // paddingTop: 8
                    }}
                  >
                    {" "}
                    <Tooltip title="Go Back">
                      <IconButton
                        style={{
                          color: "#4b2e83",
                          opacity: "1",
                          outline: "none"
                        }}
                        aria-label="Go Back"
                        onClick={() => this.props.history.push("/master/data/manage")}
                      >
                        <GoBackIcon />
                      </IconButton>
                    </Tooltip>
                    Go back to previous screen
                  </h5>
                </div>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} style={{display: "flex", justifyContent: "end"}}>
              <Tooltip title={this.props.match.params.id == 0 ? "Create" : "Save"}>
                <IconButton
                  // style={{
                  //   color: "#4b2e83",
                  //   opacity: "1",
                  //   outline: "none"
                  // }}
                  color="primary"
                  aria-label="Create"
                  onClick={()=>this.handleSave()}
                >
                  <SubmitIcon />
                </IconButton>
              </Tooltip>
                </Grid>
            </Grid>


        <Paper
          style={{
            padding: "18px 30px",
            margin: "15px 30px",
          }}
        >
          <Grid
            container
            spacing={2}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid item xs={12} md={2} lg={2}>
              <h5>Type</h5>
            </Grid>
            <Grid item xs={12} md={5} lg={5}>
              <input
                list="text_editors3"
                type="text"
                className={classes.input}
                onChange={e=> this.onChange(e)}
                // onChange={evt=> {
                //   console.log("nya",evt);
                //   this.setState(prevState =>({
                //     typeData: {
                //       ...prevState.typeData,
                //       //name: evt.target.value
                //     }
                //   }))
                // }}
                value={typeData.name}
                disabled={this.props.match.params.id !=0}
              />
              {
                <datalist id="text_editors3">
                <select multiple>
                  {this.state.tooltipTypeData.map((user) => (
                    <option key={user.id} value={user.name}>
                      {user.name}
                    </option>
                  ))}
                </select>
              </datalist>}

              {/* <Tooltip title="Reset">
                <IconButton
                  style={{ color: "#4b2e83", opacity: "1", outline: "none" }}
                  aria-label="Reset"
                  onClick={() => {
                    this.handleReset(2, "type");
                  }}
                >
                  <ResetIcon />
                </IconButton>
              </Tooltip> */}



            </Grid>
            <Grid item xs={12} md={5} lg={5}>

            {this.props.match.params.id != 0 &&
              <Tooltip title="Delete">
                        <IconButton
                          aria-label="Delete"
                          color="primary"
                          onClick={() => {
                            this.setState({
                              actionType: "DELETE",
                              name: "type",
                              openAlert: true,
                            });
                          }}
                          //hidden={this.state.disableAllButtons}
                          //disabled={this.state.disableAllButtons}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>}

            {/* <Tooltip title={this.props.match.params.id == 0 ? "Create" : "Save"}>
                <IconButton
                  // style={{
                  //   color: "#4b2e83",
                  //   opacity: "1",
                  //   outline: "none"
                  // }}
                  color="primary"
                  aria-label="Create"
                  onClick={()=>this.handleSave()}
                >
                  <SubmitIcon />
                </IconButton>
              </Tooltip> */}

              {/* <Button
                variant="contained"
                color="primary"
                style={{
                  margin: "0px 7px",
                  backgroundColor: "white",
                  color: "#4b2e83",
                  outline: "none",
                }}
                className="button1"
                disabled={!this.newType}
                onClick={() => {
                  if (
                    this.state.type.name == "" ||
                    this.state.type.name == null
                  ) {
                    this.props.showMessageBox("Field is empty");
                    return;
                  }
                  this.setState({
                    actionType: "ADD",
                    name: "type",
                    openAlert: true,
                  });
                }}
              >
                Add
              </Button> */}

              {/* <Button
                variant="contained"
                color="primary"
                style={{
                  margin: "0px 7px",
                  borderColor: "#4b2e83",
                  backgroundColor: "white",
                  color: "#4b2e83",
                  outline: "none",
                }}
                className="button1"
                disabled={this.newType || this.state.type.name === ""}
                onClick={() => {
                  this.setState({
                    rename_type: "type",
                  });
                  this.handleClickOpen();
                }}
              >
                Rename
              </Button> */}

              {/* <Button
                variant="contained"
                color="primary"
                style={{
                  margin: "0px 7px",
                  backgroundColor: "white",
                  color: "#4b2e83",
                  outline: "none",
                }}
                className="button1"
                disabled={this.newType || this.state.type.name === ""}
                onClick={() => {
                  this.setState({
                    actionType: "DELETE",
                    name: "type",
                    openAlert: true,
                  });
                }}
              >
                Delete
              </Button> */}


            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    showMessageBox: message => dispatch(showMessage(message))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(MasterDataManagement);
