import React from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TablePagination from "@material-ui/core/TablePagination";
import PropTypes from 'prop-types';

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import MapIcon from "@material-ui/icons/Map";

import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/HighlightOff";
import SvgIcon from '@material-ui/core/SvgIcon';
import Page2Map from "../components/Page2Map.jsx";

import Button from "@material-ui/core/Button";
import { CSVLink } from "react-csv";

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { apiRequest, getOption, byPropertyCalled, fetchRequest } from "../utils/Utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

var moment = require('moment');
var mtz = require('moment-timezone');
var abbs = mtz.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('z').toString();
const headCells = [
  { id: "Created", label: `TIMESTAMP (${abbs})`},
  { id: "Activity", label: "ACTIVITY"},
  { id: "CreatedBy", label: "USER" },
  { id: "Category", label: "CATEGORY"},
  { id: "Action", label: "ACTION" },
  { id: "Status", label: "STATUS" }
];

function desc(a, b, orderBy) {
  let cmpa= a[orderBy] ? a[orderBy].toLowerCase(): '';
  let cmpb= b[orderBy] ? b[orderBy].toLowerCase(): '';
  if (cmpb < cmpa) {
    return -1;
  }
  if (cmpb > cmpa) {
    return 1;
  }
  return 0;
}

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            //align={headCell.numeric ? 'right' : 'left'}
            //padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ color: "#4b2e83", whiteSpace: "nowrap" }}
          >
            <Tooltip
              title={
                orderBy === headCell.id ?
                order === "desc"
                  ? "Sorted descending"
                  : "Sorted ascending"
                  : ""
              }
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                style={{ color: "#4b2e83" }}
              >
                {headCell.label}
              </TableSortLabel>
            </Tooltip>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  //classes: PropTypes.object.isRequired,
  //numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  //onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  //rowCount: PropTypes.number.isRequired,
};

class ActionLog extends React.Component {
    constructor(props) {
      super();
      this.rows = [];
      this.downloadData = [];
      this.state = {
        order: "desc",
        orderBy: "Created",
        days: 0,
        rowsPerPage: 50,
        page: 0,
        isLoading: true,
        users: [],
        //filteredLog: []
        selectedUsers: [],
        selectedCategories: [],
        selectedActions: [],
        selectedStatus: []
      };
      this.daysList=[
        //{id: 0, name: "All", detail: "day", value: 1000, days: 1000}, // ALL MAX 1000day
        {id: 1, name: "Last 24 hours", detail: "day", value: 1, days: 1},
        {id: 2, name: "Last 7 days", detail: "day", value: 7, days: 7},
        {id: 3, name: "Last 30 days", detail: "day", value: 30, days: 30},
        {id: 4, name: "Last 90 days", detail: "day", value: 90, days: 90},
        {id: 5, name: "Last 6 months", detail: "month", value: 6, days: 182},
        {id: 6, name: "Last 12 months", detail: "month", value: 12, days: 365},
        {id: 7, name: "Last 15 months", detail: "month", value: 15, days: 455},
      ];
      this.childDiv = React.createRef();
  }
  componentDidMount() {
    apiRequest(`/masters/activitylog`, "get")
      .then(res=> {
        this.rows = res.data;
        this.downloadData = res.data.map(r=> ({
          [`TIMESTAMP (${abbs})`]: moment.utc(r.Created).local().format('DD MMMM YYYY H:mm'),
          "ACTIVITY": r.Activity,
          "USER": r.CreatedBy,
          "CATEGORY": r.Category,
          "ACTION": r.Action,
          "STATUS": r.Status
        }));
        this.setState({isLoading: false});
      });
    apiRequest("/activeusers", "get")
      .then(res=> {
        this.setState({users: res.data});
      });
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }
    this.setState({ order, orderBy });
  }
  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }
  
  getSorting(order, orderBy) {
    return order === "desc"
      ? (a, b) => desc(a, b, orderBy)
      : (a, b) => -desc(a, b, orderBy);
  }
  handleChangePage = (event, page) => {
    this.setState({ page });
  }

  handleChangeRowsPerPage = (filteredLog,event) => {
    if(event.target.value * this.state.page > filteredLog.length && this.state.page != 0)
      this.setState({ rowsPerPage: event.target.value, page: 0 });
    else
      this.setState({ rowsPerPage: event.target.value });
  }

  selectDateToUTC(datePickerValue) {
    let d = new Date();
    if(datePickerValue.detail == "day") {
      //SET DAY
      d.setDate(d.getDate() - datePickerValue.value + 1);
    }
    else {
      //SET MONTH
      d.setMonth(d.getMonth() - datePickerValue.value);
    }
    d.setUTCHours(0,0,0,0);
    let utc = d.toISOString();
    return utc;
  }

  compareUTCDate(picker, asset) {
    let pickerDate = new Date(picker);
    let assetDate = new Date(asset);
    return assetDate >= pickerDate;
  }

  render() {
    let dayObj = this.daysList.find(d=> d.days == this.state.days);
    dayObj = dayObj == undefined ? null : dayObj;
    let e = new Date();
    let selectedDate = e.getTime() - (this.state.days * 24 *60*60*1000) - 60 * -1000 * e.getTimezoneOffset();
    let filteredLog = this.rows;
    if(this.state.selectedUsers && this.state.selectedUsers.length > 0)
      filteredLog = filteredLog.filter(l=> this.state.selectedUsers.some(x=> x.Username == l.CreatedBy));
    if(this.state.selectedCategories && this.state.selectedCategories.length > 0)
      filteredLog = filteredLog.filter(l=> this.state.selectedCategories.some(x=> x == l.Category));
    if(this.state.selectedActions && this.state.selectedActions.length > 0)
      filteredLog = filteredLog.filter(l=> this.state.selectedActions.some(x=> x.name == l.Action ));
    if(this.state.selectedStatus && this.state.selectedStatus.length > 0)
      filteredLog = filteredLog.filter(l=> this.state.selectedStatus.some(x=> x == l.Status ));
    if(this.state.days)
      filteredLog = filteredLog.filter(l=> {
        let no = new Date(l.Created);
        let logDate = no.getTime()//setUTCHours(0,0,0,0);
        if(l.Activity.includes("test-col-kb updated"))
          console.log("azi", this.state.days, logDate, selectedDate);
        return logDate > selectedDate;
      });
    if(this.state.searchText)
      filteredLog = filteredLog.filter(l=> 
        l.Activity.toLowerCase().includes(this.state.searchText.toLowerCase()) ||
        l.Action.toLowerCase().includes(this.state.searchText.toLowerCase()) ||
        l.Category.toLowerCase().includes(this.state.searchText.toLowerCase()) ||
        moment.utc(l.Created).local().format('DD MMMM YYYY H:mm').toLowerCase().includes(this.state.searchText.toLowerCase()) ||
        l.CreatedBy.toLowerCase().includes(this.state.searchText.toLowerCase()) ||
        l.Status.toLowerCase().includes(this.state.searchText.toLowerCase()) 
      );
    return (
      <React.Fragment>
          <Grid
            container
            spacing={2}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid item xs={10}>
              <TextField
                style={{
                  width: "100%",
                  // paddingLeft: 30,
                  // paddingRight: 10,
                  paddingBottom: "1px",
                }}
                value={this.state.searchText}
                margin="dense"
                placeholder="Search"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  //className: classes.input,
                }}
                onChange={e=> this.setState({searchText: e.target.value})}
              />
            </Grid>
            <Grid
              item
              xs
              //xs={2}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <CSVLink
                data={this.downloadData}
                filename="ActivityLog.csv"
                style={this.state.isLoading ? { "pointer-events": "none", textDecoration: "none" } : { "pointer-events": "auto", textDecoration: "none" }}
              >
                <Button
                  disabled={this.state.isLoading}
                  variant="outlined"
                  //onClick={this.toggleHidden.bind(this)}
                  style={{
                    color: "#4b2e83",
                    //opacity: "1",
                    //outline: "none"
                  }}
                //className="buttonnobg float-center"
                >
                  Export
                </Button>
              </CSVLink>
            </Grid>
            <Grid item /*xs={6} md={4} lg={8}*/ style={{"text-align": "end", alignSelf: "center", width: "24px", marginLeft: "8px"}}>

            </Grid>
          </Grid>
          <Grid
          container
          spacing={1}
          style={{
            display: "flex",
            alignItems: "center",
            paddingBottom: "4px",
          }}
        >
          <Grid item style={{width: `${(60/5/72)*100}%`}} xs={6} md={6} lg={2}>
          <Autocomplete
            //disabled={true}
            //style={{ width: "200px" }}
            id="days"
            options={this.daysList}
            value={dayObj}
            //value={this.daysList.find(d=> d.id == this.state.days)}
            getOptionSelected={(option, value) => option.id == value.id}
            getOptionLabel={(option) => option.name}
            //onChange={this.handleChange("entity")}
            onChange={(evt, value) => {
              let days = value ? value.days : 0; //ALL MAX 1000day
              this.setState({ days: days, page: 0 });
            }}
            renderOption={(option) => (
              <div style={{ padding: "8px 2px" }}>{option.name}</div>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Days"
                variant="outlined"
                margin="dense"
                //className={this.props.classes.textField}
              />
            )}
          />
          </Grid>
          <Grid item style={{width: `${(60/5/72)*100}%`}} xs={6} md={6} lg={2}>
          <Autocomplete
            limitTags={2}
            multiple={true}
            //style={{ minWidth: "200px" }}
            id="us"
            value={this.state.selectedUsers}
            options={this.state.users}
            //getOptionSelected={(option, value) => option.id == value.id}
            getOptionLabel={(option) => option.Username}
            onChange={(evt, value) => {
              this.setState({ selectedUsers: value, page: 0 });
            }}
            renderOption={(option) => (
              <div style={{ padding: "8px 2px" }}>{option.Username}</div>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Users"
                variant="outlined"
                margin="dense"
                //className={this.props.classes.textField}
              />
            )}
          />
          </Grid>
          <Grid item style={{width: `${(60/5/72)*100}%`}} xs={6} md={6} lg={2}>
          <Autocomplete
            limitTags={2}
            multiple={true}
            //style={{ minWidth: "200px" }}
            id="cat"
            value={this.state.selectedCategories}
            options={["Asset", "Collection", "Department", "Session"]}
            //getOptionSelected={(option, value) => option.id == value.id}
            getOptionLabel={(option) => option}
            onChange={(evt, value) => {
              this.setState({ 
                selectedCategories: value, 
                selectedActions: value.length == 0 ? [] : this.state.selectedActions.filter(ac=> {
                  if(value.length > 0)
                  {
                    return value.some(c=> ac.cat.includes(c));
                  }
                  return true;
                }),
                page: 0 
              });
            }}
            renderOption={(option) => (
              <div style={{ padding: "8px 2px" }}>{option}</div>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Category"
                variant="outlined"
                margin="dense"
                //className={this.props.classes.textField}
              />
            )}
          />
          </Grid>
          <Grid item style={{width: `${(60/5/72)*100}%`}} xs={6} md={6} lg={2}>
          <Autocomplete
            limitTags={2}
            multiple={true}
            //style={{ minWidth: "200px" }}
            id="act"
            value={this.state.selectedActions}
            // options={["Add","Edit","Delete"
            //   ,"Assign","Remove", "Replace", "Login", "Logout" //Extra
            // ]}
            //getOptionSelected={(option, value) => option.id == value.id}
            //getOptionLabel={(option) => this.state.selectedActions.includes(option) ? option : ""}

            options={
              [
                {name: "Add", cat: "Asset - Collection - Department"},
                {name: "Edit", cat: "Asset - Collection - Department"},
                {name: "Delete", cat: "Asset - Collection - Department"},
                {name: "Assign", cat: "Asset"},
                {name: "Remove", cat: "Asset"},
                {name: "Replace", cat: "Asset"},
                {name: "Login", cat: "Session"},
                {name: "Logout", cat: "Session"},
              ]
              .filter(ac=> {
                if(this.state.selectedCategories.length > 0)
                {
                  return this.state.selectedCategories.some(c=> ac.cat.includes(c));
                }
                return true;
              })
            }
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.name == value.name}
            groupBy={(option) => option.cat}
            onChange={(evt, value) => {
              this.setState({ selectedActions: value, page: 0 });
            }}
            renderOption={(option) => (
              <div style={{ padding: "8px 2px" }}>{option.name}</div>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Action"
                variant="outlined"
                margin="dense"
                //className={this.props.classes.textField}
              />
            )}
          />
          </Grid>
          <Grid item style={{width: `${(60/5/72)*100}%`}} xs={6} md={6} lg={2}>
          <Autocomplete
            limitTags={2}
            multiple={true}
            //style={{ minWidth: "200px" }}
            id="st"
            value={this.state.selectedStatus}
            options={["Success", "Error"]}
            //getOptionSelected={(option, value) => option.id == value.id}
            getOptionLabel={(option) => option}
            onChange={(evt, value) => {
              this.setState({ selectedStatus: value, page: 0 });
            }}
            renderOption={(option) => (
              <div style={{ padding: "8px 2px" }}>{option}</div>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Status"
                variant="outlined"
                margin="dense"
                //className={this.props.classes.textField}
              />
            )}
          />
          </Grid>
          <Grid
            item
            //xs={2}
            xs
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              //disabled={true || this.state.isLoading}
              variant="outlined"
              onClick={()=> this.setState({searchText: "", days: 0, selectedUsers: [], selectedCategories: [], selectedActions: [], selectedStatus: []})}
              style={{
                color: "#4b2e83",
                //opacity: "1",
                //outline: "none"
              }}
              //className="buttonnobg float-center"
            >
              Clear
            </Button>
          </Grid>
          <Grid item /*xs={6} md={4} lg={8}*/ style={{"text-align": "end", alignSelf: "center", width: "24px", marginLeft: "8px"}}>
            
            </Grid>
        </Grid>
        {this.state.isLoading ?
            <div
                style={{
                      width: "100%",
                      marginTop: 100,
                      display: "flex",
                      justifyContent: "center",
                }}
              >
              <CircularProgress style={{ width: "80px", height: "80px" }}/>
            </div>
          :
          <React.Fragment>
            <TableContainer>
              <Table>
                <EnhancedTableHead
                  //classes={classes}
                  //numSelected={selected.length}
                  order={this.state.order}
                  orderBy={this.state.orderBy}
                  //onSelectAllClick={handleSelectAllClick}
                  onRequestSort={this.handleRequestSort}
                  //rowCount={rows.length}
                />
                <TableBody>
                  {this.stableSort(
                    filteredLog,
                    this.getSorting(this.state.order, this.state.orderBy)
                  )
                    .slice(
                      this.state.page * this.state.rowsPerPage,
                      (this.state.page + 1) * this.state.rowsPerPage //+ rowsPerPage
                    )
                    //.filter(r=> this.compareUTCDate(this.selectDateToUTC(dayObj), r.time))
                    .map((row) => (
                      <TableRow>
                        <TableCell>{moment.utc(row.Created).local().format('DD MMMM YYYY H:mm')}</TableCell>
                        <TableCell>{row.Activity}</TableCell>
                        <TableCell>{row.CreatedBy}</TableCell>
                        <TableCell>{row.Category}</TableCell>
                        <TableCell>{row.Action}</TableCell>
                        <TableCell>{row.Status}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100, 125, 150]}
              component="div"
              count={filteredLog.length}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              backIconButtonProps={{
                "aria-label": "Previous Page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page",
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={evt=> this.handleChangeRowsPerPage(filteredLog,evt)}
              style={{paddingBottom: "20px"}}
            />
          </React.Fragment>
        }
      </React.Fragment>
    );
  }
}
export default ActionLog;