import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { Button } from "@material-ui/core";
import { getLocations, getCollections, showMessage } from "../actions/index";
import { connect } from "react-redux";
import { compose } from "recompose";
import CircularProgress from "@material-ui/core/CircularProgress";
import { apiRequest } from "../utils/Utils";
import AlertDialog from "./AlertDialog";

const styles = theme => ({
  root: {
    width: "100%",
    overflowX: "auto"
  },
  table: {
    minWidth: 300
  },
  button: {
    margin: "0px",
    padding: "0px",
    color: "#4b2e83",
    border: "1px solid #4b2e83",

    "&:hover": {
      color: "#4b2e83",
      border: "1px solid #4b2e83",
    }
  },
  icons: {
    outline: "none !important",
    margin: "-10px -1px"
  },
  progress: {
    color: "#4b2e83"
  }
});

class TableForOption extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      header: props.header,
      tableName: props.tableName,
      page: 0,
      rowsPerPage: 5,
      showLoader: false,
      disableButton: false,
      disableAllButtons: false,
      access: props.access,
      openAlert: false,
      rowToDelete: {}
    };
  }
  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    if(event.target.value * this.state.page > this.state.data.length && this.state.page != 0)
      this.setState({ rowsPerPage: event.target.value, page: 0 });
    else
      this.setState({ rowsPerPage: event.target.value });
  };

  handleDelete = () => {
    const item = this.state.rowToDelete;
    const That = this;
    this.setState({
      showLoader: true,
      openAlert: false
    });
    let url = `/entities/${item.entityId}/locations/`;
    if (this.state.tableName == "Department") {
      url = `/collections/${item.id}`;
    } else {
      url += `${item.id}`;
    }
    const THAT = this;

    apiRequest(url, "delete")
      .then(x => {
        if (x.status > 0) {
          if (THAT.state.tableName === "Department") {
            if (this.props.type == "location")
              THAT.props.getCollection(item.entityId, item.locationId);
            else THAT.props.getCollection(item.entityId, 0); //All departments for that entity
          }
          if (THAT.state.tableName === "Campus")
            THAT.props.getLocation(item.entityId);
        }
        setTimeout(() => {
          THAT.setState({
            showLoader: false
          });
        }, 500);

        if (x.status > 0 && x.message == null)
          THAT.props.showMessageBox("SUCCESS");
        else THAT.props.showMessageBox(x.message);
      })
      .catch(err => {
        THAT.props.showMessageBox("ERROR");
        THAT.setState({
          showLoader: false
        });
      });
  };

  componentWillMount() {
    this.checkAccess();
  }

  componentWillReceiveProps(newProps) {
    let page = newProps.data.length > 0 ? Math.ceil(newProps.data.length / this.state.rowsPerPage)-1 : 0;
    this.setState(
      {
        data: newProps.data,
        access: newProps.access,
        page: this.state.page > page ? page : this.state.page
      },
      () => {
        this.checkAccess();
      }
    );
  }

  checkAccess() {
    const { entityId, locationId = "" } = this.props;
    let resourceType = "";
    let resourceId = "";
    if (locationId == "") {
      resourceType = "entity";
      resourceId = entityId;
    } else {
      resourceType = "location";
      resourceId = locationId;
    }
    apiRequest(
      `/accesscontrol?resourceType=${resourceType}&resourceId=${resourceId}`,
      "get"
    )
      .then(response => {
        if (response.data[0] == 300) {
          this.setState({
            disableButton: true
          });
        }
        if (response.data[0] <= 100) {
          this.setState({
            disableAllButtons: true,
            disableButton: true
          });
        }
      })
      .catch(error => console.log("error", error));
  }

  render() {
    const { classes } = this.props;
    const { data, header, rowsPerPage, page } = this.state;
    return (
      <Paper className={classes.root}>
        <AlertDialog
          open={this.state.openAlert}
          onClickYes={this.handleDelete}
          action={"Delete"}
          close={() => {
            this.setState({ openAlert: false });
          }}
        />
        <Table className={classes.table}>
          <TableHead>
            <TableRow style={{ height: 40 }}>
              <TableCell style={{ width: 200 }}>
                <Link
                  style={{
                      "text-decoration": "none"
                  }}
                  to={
                    this.state.tableName == "Campus"
                      ? "/options/Create/loc/0?xyx=1"
                      : "/options/Create/collection/0?xyz=1&type=dept"
                  }
                  hidden={this.state.disableButton}
                  disabled={this.state.disableButton}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{
                      color: "#4b2e83",
                      margin: 10,
                      height: 45,
                      "text-decoration": "none"
                    }}
                    className="button1"
                    //size="small"
                    disabled={this.state.disableButton}
                    hidden={this.state.disableButton}
                  >
                    New
                  </Button>
                </Link>
              </TableCell>

              {header.map(item => (
                <TableCell key={item.id} align="right" style={{ width: "33%" }}>
                  {item.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => (
                <TableRow style={{ height: 40 }} key={row.id}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ padding: "0 24px" }}
                  >
                    {this.state.showLoader &&
                    row.id == this.state.rowToDelete.id ? (
                      <CircularProgress
                        style={{ width: "20px", height: "20px" }}
                        className={classes.progress}
                      />
                    ) : (
                      <Tooltip title="Delete">
                        <IconButton
                          aria-label="Delete"
                          className={classes.icons}
                          onClick={() => {
                            this.setState({
                              openAlert: true,
                              rowToDelete: row
                            });
                          }}
                          hidden={this.state.disableAllButtons}
                          disabled={this.state.disableAllButtons}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Edit">
                      <Link
                        to={
                          this.state.tableName === "Campus"
                            ? `/options/Modify/loc/${row.id}`
                            : `/options/Modify/collection/${row.id}?type=dept`
                        }
                        hidden={this.state.disableAllButtons}
                      >
                        <IconButton
                          aria-label="Delete"
                          className={classes.icons}
                          disabled={this.state.disableAllButtons}
                        >
                          <Edit fontSize="small" />
                        </IconButton>
                      </Link>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="right">{row.id}</TableCell>
                  <TableCell align="right">{row.name}</TableCell>
                  {this.state.tableName === "Department" && (
                    <TableCell align="right">{row.location}</TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={false}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

const mapStateToProps = state => {
  const { entities } = state;
  return {
    entities
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getLocation: entityId => getLocations(dispatch, entityId),
    getCollection: (entityId, locId) =>
      getCollections(dispatch, entityId, locId),
    showMessageBox: message => dispatch(showMessage(message))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(TableForOption);
