import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import "./ManageDevices.css";
import TableMapped from "./TableMappedDevices.jsx";
import TableUnMapped from "./TableUnmappedDevices.jsx";
import ResetIcon from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import {
  getEntity,
  getLocations,
  getCollections,
  getDeviceFlags,
  getAssetType,
  getAssetModel,
  getAssetManufacturer,
  cleanData,
  showMessage,
  getAssets
} from "../actions/index";
import { compose } from "recompose";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { apiRequest } from "../utils/Utils";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import Collapse from "@material-ui/core/Collapse";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {  byName } from "../utils/Utils";

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
    paddingTop: 10,
    paddingBottom: 10
  },
  input: {
    height: 40
  },
  label: {
    marginTop: "-5px"
  },
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
    flexGrow: 1
  },
  root2: {
    ...theme.mixins.gutters(),
    paddingBottom: theme.spacing.unit * 1
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  textField: {
    width: "100%"
  },
  dense: {
    marginTop: 16
  },
  menu: {
    width: 200
  },
  ip: {
    height: "40px"
  }
});

class ManageDevices extends Component {
  state = {
    entity:sessionStorage.getItem("entityID") ? sessionStorage.getItem("entityID") : "",
    dept: sessionStorage.getItem("collectionID") ? sessionStorage.getItem("collectionID") : "",
    loc: "",
    manufacturer: "",
    model: "",
    type: "",
    flag: "",
    search: "",
    showFilters: false,
    Filters: true,
    selectedRows: "0",
    entityData: [],
    locationData: [],
    collectionData: [],
    mappedAssets: [],
    unmappedAssets: [],
    flagData: [],
    assetManufacturerData: [],
    assetModelData: [],
    assetTypeData: [],
    macId: "",
    showMappingTable: true,
    loadingunMapped: true,
    loadingMapped: true,
    enableFilterBtn: false,
    params: "",
    deptType: "",
    hasAccess: false,
    isFilterOpen: false,
    searchText: ""
  };

  componentWillMount() {
    this.k = 1;
    this.loadunMapped();
    //this.props.getEntities();
    this.props.getFlags();
    this.props.getAssetTypes();
    this.props.getAssetManufacturers();
    this.props.getAssetModels();
  }
  componentDidMount() {
    if(this.state.entity && this.state.dept)
    {
      console.log("itt");
      this.checkAccess();
      this.onSubmit();
    }
  }
  componentDidUpdate() {
    console.log("cdu");
  }
  loadunMapped() {
    let search = "";

    search = `searchType=forcollection&collection_id=${this.state.dept}`;

    this.props.getAsset(search);
    // if(this.state.entity != "")
    // this.props.getCollection(this.state.entity);
    
  }

  mapDevices(macID) {
    if (this.state.dept == "") {
      this.props.showMessageBox("Select Collection first");
      return;
    }
    let That = this;
    That.setState({
      loadingMapped: false,
      loadingunMapped: false,
      searchText: "",
      type: "",
      flag: "",
      model: "",
      manufacturer: "",
    });
    const data = {
      mac: macID,
      CollectionId: this.state.dept
    };

    apiRequest("/assetmaps", "post", data)
      .then(x => {
        if (x.status > 0) {
          That.onSubmit();
          That.loadunMapped();
        }

        if (x.status > 0 && x.message == null)
          That.props.showMessageBox("SUCCESS");
        else {
          That.setState({
            loadingMapped: true,
            loadingunMapped: true
          });
          That.props.showMessageBox(x.message);
        }
      })
      .catch(err => {
        That.setState({
          loadingMapped: true,
          loadingunMapped: true
        });
        That.props.showMessageBox("ERROR");
      });
  }

  getMacIdtoBeRemoved(that, id) {
    let That = this;
    const MacIds = id.map(item => item.MacId);
    const data = {
      entityId: That.state.entity,
      mac: MacIds
    };
    That.setState({
      loadingMapped: false,
      loadingunMapped: false
    });

    if (That.state.entity == "") {
      that.props.showMessageBox("Please selecte the Organization");
    } else if (id == "") {
      that.props.showMessageBox("Please selecte the Device(s) to be assigned");
    } else {
      apiRequest(`/assets/ownership`, "delete", data)
        .then(x => {
          if (x.status > 0) {
            That.onSubmit();
            That.loadunMapped();
          }
          if (x.status > 0 && x.message == null)
            That.props.showMessageBox("SUCCESS");
          else {
            That.setState({
              loadingMapped: true,
              loadingunMapped: true
            });
            That.props.showMessageBox(x.message);
          }
        })
        .catch(err => {
          That.onSubmit();
          That.loadunMapped();
          That.setState({
            loadingMapped: true,
            loadingunMapped: true
          });
          That.props.showMessageBox("ERROR");
        });
    }
  }

  onSubmit = () => {
    let That = this;
    That.setState({
      loadingMapped: false
    });
    //this.loadunMapped();
    this.handleFilter();
    if(this.state.dept) {
      apiRequest(`/collections/${this.state.dept}/assets`, "get")
      .then(response => {
        const newState = Object.assign({}, this.state, {
          mappedAssets: response.data
        });
        this.setState(newState);
        That.setState({
          loadingMapped: true
        });
      })
      .catch(error => console.log(error));
    }
    this.setState({
      Filters: false,
      showFilters: true
    });
  };

  handleChange = (name) => (event,value) => {
    console.log("heloka",event, value);
    var param ="";
    if(value !== null)
    {
      switch(name) {
        case "manufacturer":
          param = value.name;
          break;
        // case "batteryLevel":
        //   param = value.value;
        //   break;
        case "model":
          param = value.name;
          break;
        case "type":
          param = value.name;
          break;
        case "flag":
          param = value.name
          break;
        default:
          break;
      }
    }
    this.setState(
      {
        //[name]: event.target.value
        [name]: param
      },
      () => {
        if (name === "entity") {
          sessionStorage.setItem("entityID", event.target.value);
          this.props.getLocation(event.target.value);
          this.props.getCollection(event.target.value);
        } else if (name === "loc") {
          this.props.getCollection(this.state.entity, event.target.value);
        } else if (name === "dept") {
          this.checkAccess();
          this.onSubmit();
          this.setState({
            showFilters: true
          });
        }
        else if(name === "manufacturer" || name === "type" || name === "flag" || name === "model")
        {
          this.handleFilter();
        }
      }
    );
  };

  handleSearch = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleReset = () => {
    this.setState({
      showFilters: false,
      Filters: true,
      //entity: "",
      dept: "",
      type: "",
      model: "",
      flag: "",
      manufacturer: "",
      loc: "",
      params: ""
    });
    //this.props.resetData();
  };

  handleResetFilters = () => {
    this.setState({
      search: "",
      type: "",
      flag: "",
      model: "",
      manufacturer: "",
      Filters: false
    },()=> this.handleFilter());
    //this.onSubmit();
    //this.handleFilter();
  };

  handleSelected = Value => {
    this.setState({ selectedRows: Value });
  };

  componentWillReceiveProps({
    entities,
    locations,
    collections,
    deviceFlags,
    assetTypes,
    assetModel,
    assetManufacturer,
    assets,
    params = "",
    values
  }) {
    if(values) {
      let old_entity = this.state.entity;
      if(
        values.entity !== this.state.entity ||
        values.coll !== this.state.dept) {
        this.setState({
          entity: values.entity,
          dept: values.coll
        }, ()=> {
          if(old_entity != this.state.entity)
          {
            this.setState({hasAccess: false,showFilters: false});
            //this.loadunMapped();
          }
          else if(this.state.entity!=="" && this.state.dept !== "") {
            this.checkAccess();
            this.onSubmit();
          }
          else {
            this.setState({hasAccess: false,showFilters: false});
          }
        });
      }
    }
    if (assets != this.state.unmappedAssets) {
      this.setState({
        loadingunMapped: true
      });
    }
    this.setState({
      entityData: entities,
      locationData: locations,
      collectionData: collections,
      flagData: deviceFlags.sort(byName),
      assetTypeData: assetTypes.sort(byName) || [],
      assetModelData: assetModel.sort(byName),
      assetManufacturerData: assetManufacturer.sort(byName),
      unmappedAssets: assets,
      params
    });
    if (assetManufacturer.length > 0) {
      this.setState({
        loadingunMapped: true
      });
    }
    if (this.state.params != "") {
      if (this.k == 1) {
        this.setState(
          {
            entity: parseInt(params.substring(4, params.indexOf("&"))),
            flag: "NOT_MAPPED"
          },
          () => {
            if (this.k == 1) {
              this.k = 2;
              this.props.getLocation(params.substring(4, params.indexOf("&")));
              this.props.getCollection(
                params.substring(4, params.indexOf("&"))
              );
            }
          }
        );
      }
    }
    console.log("WRP");
  }

  getMacID(that, id) {
    that.mapDevices(id);
  }

  //to unmap a device
  getMappedMacID(that, id) {
    let That = this;
    That.setState({
      loadingMapped: false,
      loadingunMapped: false,
      searchText: "",
      type: "",
      flag: "",
      model: "",
      manufacturer: "",
    });

    const AssetMapIds = id.map(item => item.AssetMapId);
    apiRequest(`/assetmaps`, "delete", { ids: AssetMapIds })
      .then(x => {
        if (x.status > 0) {
          That.onSubmit();
          That.loadunMapped();
        }
        if (x.status > 0 && x.message == null)
          That.props.showMessageBox("SUCCESS");
        else {
          That.setState({
            loadingMapped: true,
            loadingunMapped: true
          });
          That.props.showMessageBox(x.message);
        }
      })
      .catch(err => {
        That.onSubmit();
        That.loadunMapped();
        That.setState({
          loadingMapped: true,
          loadingunMapped: true
        });
        That.props.showMessageBox("ERROR");
      });
  }

  handleFilter = () => {
    if (
      this.state.model == "" &&
      this.state.type == "" &&
      this.state.manufacturer == "" &&
      this.state.flag == ""
    ) {
      //this.handleResetFilters();
      this.loadunMapped();
      return;
    }
    //this.searchParam = `entity_id=${this.state.entity}&`;
    this.searchParam = ``;
    if (this.state.flag !== "")
      this.searchParam += `device_flag=${this.state.flag}&`;
    if (this.state.model !== "")
      this.searchParam += `model=${this.state.model}&`;
    if (this.state.manufacturer !== "")
      this.searchParam += `manufacturer=${this.state.manufacturer}&`;
    if (this.state.type !== "") this.searchParam += `type=${this.state.type}&`;
    if (this.state.dept !== "") this.searchParam += `collection_id=${this.state.dept}&`; 
    //this.searchParam += `searchType=formapping`;
    this.searchParam+= `searchType=forcollection`;
    if (this.searchParam !== "") this.props.getAsset(this.searchParam);
  };

  checkAccess() {
    let type = "collection";
    let id = this.state.dept;

    apiRequest(`/accesscontrol?resourceType=${type}&resourceId=${id}`, "get")
      .then(response => {
        if (response.data[0] >= 500) {
          this.setState({
            hasAccess: true
          });
        } else {
          this.setState({
            hasAccess: false
          });
        }
      })
      .catch(error => console.log("error", error));
  }
  filteredData(data) {
    const search = this.state.searchText.toLowerCase();
    if(search == "")
    {
      return data;
    }
    else {
      return data.filter(item=> 
        item.MacId.toLowerCase().includes(search) ||
        item.Name.toLowerCase().includes(search)
        //|| item.DisplayName.toLowerCase().includes(search)
        )
    }
  }

  render() {
    const { classes } = this.props;
    var manuObj = this.state.assetManufacturerData.find(e=> e.name == this.state.manufacturer);
    manuObj = manuObj == undefined ? null : manuObj;
    var mObj = this.state.assetModelData.find(e=> e.name == this.state.model);
    mObj = mObj == undefined ? null : mObj;
    var tObj = this.state.assetTypeData.find(e=> e.name == this.state.type);
    tObj = tObj == undefined ? null : tObj;
    var fObj = this.state.flagData.find(e=> e.name == this.state.flag);
    fObj = fObj == undefined ? null : fObj;
    return (
      <div>
      <div>
        {/*<Paper
          className={classes.root}
          style={{
            padding: "2px 10px 2px 10px",
            width: "100%",
            height: "100%",
            flexDirection: "column",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <div
            style={{
              paddingBottom: "5px",
              paddingTop: "5px",
              width: "100%"
            }}
          >
            <Grid container spacing={8}>
              <Grid
                item
                xs={1}
                style={{ display: "flex", alignItems: "center" }}
              >
                <h4
                  style={{
                    color: "#4b2e83",
                    width: "100%",
                    paddingLeft: 2
                  }}
                >
                  Select
                </h4>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  width="100%"
                  id="outlined-select"
                  select
                  style={{}}
                  required
                  margin="dense"
                  disabled={this.state.showFilters}
                  label="Organization"
                  className={classes.textField}
                  value={this.state.entity}
                  onChange={this.handleChange("entity")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  InputProps={{ className: classes.input }}
                  InputLabelProps={{
                    className: classes.label
                  }}
                  variant="outlined"
                >
                  {this.state.entityData.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={3}>
                <TextField
                  width="100%"
                  id="outlined-select"
                  select
                  disabled={
                    this.state.showFilters ||
                    this.state.collectionData.length === 0
                  }
                  required
                  style={{}}
                  margin="dense"
                  label="Collection"
                  className={classes.textField}
                  value={this.state.dept}
                  onChange={this.handleChange("dept")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  InputProps={{ className: classes.input }}
                  InputLabelProps={{
                    className: classes.label
                  }}
                  variant="outlined"
                >
                  {this.state.collectionData.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid
                item
                xs={1}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Tooltip title="Reset">
                  <IconButton
                    style={{ color: "#4b2e83" }}
                    aria-label="Reset"
                    onClick={this.handleReset}
                  >
                    <ResetIcon />
                  </IconButton>
                </Tooltip>
                
              </Grid>
              <Grid
                item
                xs={1}
                style={{ display: "flex", alignItems: "center" }}
              >
               
              </Grid>
            </Grid>
          </div>
        </Paper>
                  */}

          <div
            style={{
              width: "100%",
            }}
          >
                          <Grid
              container
              spacing={1}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Grid item xs={8}>
                <TextField
                  style={{
                    width: "100%",
                    // paddingLeft: 30,
                    // paddingRight: 10,
                     paddingBottom: "4px"
                  }}
                  value={this.state.searchText}
                  margin="dense"
                  placeholder="Search"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    className: classes.input,
                  }}
                  onChange={input=> this.setState({searchText: input.target.value})}
                />
              </Grid>
              <Grid
                item
                xs
                //xs={4}
                style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}
              >
                <Button
                  variant="outlined"
                  //onClick={this.handleFilter}
                  onClick={()=> this.setState({isFilterOpen: !this.state.isFilterOpen})}
                  style={{
                    color: "#4b2e83",
                    //opacity: "1",
                    //outline: "none"
                  }}
                  //className="buttonnobg float-center"
                >
                  Filter
                </Button>
              </Grid>
              <Grid item /*xs={6} md={4} lg={8}*/ style={{"text-align": "end", alignSelf: "center", width: "24px", marginLeft: "8px"}}>

              </Grid>
            </Grid>
            <Collapse in={this.state.isFilterOpen}>
            <Grid
              container
              spacing={1}
              style={{ display: "flex", alignItems: "center", paddingBottom: "4px" }}
            >
              <Grid item xs={2}>
              <Autocomplete
              id="manufacturerbox"
              options={this.state.assetManufacturerData}
              value={manuObj}
              getOptionSelected={((option, value)=> option.name == value.name)}
              getOptionLabel={(option) => option.name}
              renderOption={(option) => (
                <div style={{padding: "8px 2px"}}>
                  {option.name}
                </div>
              )}
              onChange={
                this.handleChange("manufacturer")}
              renderInput={(params) => (
                <TextField {...params} label="Asset Manufacturer" variant="outlined" margin="dense" className={classes.textField} />
              )}
            />
              </Grid>
              <Grid item xs={2}>
          <Autocomplete
              id="modelbox"
              options={this.state.assetModelData}
              value={mObj}
              getOptionSelected={((option, value)=> option.name == value.name)}
              getOptionLabel={(option) => option.name}
              renderOption={(option) => (
                <div style={{padding: "8px 2px"}}>
                  {option.name}
                </div>
              )}
              onChange={
                this.handleChange("model")}
              renderInput={(params) => (
                <TextField {...params} label="Asset Model" variant="outlined" margin="dense" className={classes.textField} />
              )}
            />
            {/* <TextField
              // disabled={this.state.istypedisabled}
              width="100%"
              margin="dense"
              id="outlined-select"
              select
              label="Asset Model"
              className={classes.textField}
              value={this.state.model}
              InputLabelProps={{
                className: classes.inputLabel,
              }}
              InputProps={{ className: classes.input }}
              onChange={this.handleChange("model")}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              // helperText="Please select the Asset Type"
              variant="outlined"
            >
              {this.state.assetModelData.map((option) => (
                <MenuItem key={option.id} value={option.name}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField> */}
          </Grid>
          <Grid item xs={2}>
          <Autocomplete
              id="typebox"
              options={this.state.assetTypeData}
              value={tObj}
              getOptionSelected={((option, value)=> option.name == value.name)}
              getOptionLabel={(option) => option.name}
              renderOption={(option) => (
                <div style={{padding: "8px 2px"}}>
                  {option.name}
                </div>
              )}
              onChange={
                this.handleChange("type")}
              renderInput={(params) => (
                <TextField {...params} label="Asset Type" variant="outlined" margin="dense" className={classes.textField} />
              )}
            />
            {/* <TextField
              // disabled={this.state.istypedisabled}
              width="100%"
              margin="dense"
              id="outlined-select"
              select
              label="Asset Type"
              className={classes.textField}
              value={this.state.type}
              InputLabelProps={{
                className: classes.inputLabel,
              }}
              InputProps={{ className: classes.input }}
              onChange={this.handleChange("type")}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              // helperText="Please select the Asset Type"
              variant="outlined"
            >
              {this.state.assetTypeData.map((option) => (
                <MenuItem key={option.id} value={option.name}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField> */}
          </Grid>
          <Grid item xs={2}>
          <Autocomplete
              id="flagbox"
              options={this.state.flagData}
              value={fObj}
              getOptionSelected={((option, value)=> option.name == value.name)}
              getOptionLabel={(option) => option.name == "NOT_MAPPED" ? "NO DEPARTMENT" : option.name}
              renderOption={(option) => (
                <div style={{padding: "8px 2px"}}>
                  {option.name == "NOT_MAPPED" ? "NO DEPARTMENT" : option.name}
                </div>
              )}
              onChange={
                this.handleChange("flag")}
              renderInput={(params) => (
                <TextField {...params} label="Device Flag" variant="outlined" margin="dense" className={classes.textField} />
              )}
            />
              </Grid>


              <Grid
                item
                xs
                //xs={4}
                style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}
              >
                <Button
                  variant="outlined"
                  onClick={this.handleResetFilters}
                  style={{
                    color: "#4b2e83",
                    //opacity: "1",
                    //outline: "none"
                  }}
                  //className="buttonnobg float-center"
                >
                  Clear
                </Button>
              </Grid>
              <Grid item /*xs={6} md={4} lg={8}*/ style={{"text-align": "end", alignSelf: "center", width: "24px", marginLeft: "8px"}}>
                
              </Grid>
            </Grid>
            </Collapse>
          </div>
        
        {this.state.loadingunMapped || this.state.loadingMapped ? (
          <div style={{ marginTop: "10px" }}>
            <TableUnMapped
              data={this.handleSelected}
              unmappedDevices={this.filteredData(this.state.unmappedAssets)}
              mappedDevices={this.filteredData(this.state.mappedAssets)}
              getMacId={(id) => this.getMacID(this, id)}
              assignTo={"Collection"}
              hasAccess={this.state.hasAccess}
              page={0}
            />
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              marginTop: 150,
              marginBottom: 150,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress
              style={{ width: "80px", height: "80px", color: "#4b2e83" }}
              className={classes.progress}
            />
            <h4 style={{ marginTop: "25px", marginLeft: "5px" }}>
              Loading Unmapped Devices
            </h4>
          </div>
        )}

        {this.state.showFilters ? (
          <div>
            {this.state.loadingMapped ? (
              <div style={{ marginTop: "10px" }}>
                <TableMapped
                  mappedDevices={this.filteredData(this.state.mappedAssets)}
                  getMappedMacId={(id) => this.getMappedMacID(this, id)}
                  getIdtoberemoved={(id) =>
                    this.getMacIdtoBeRemoved(this, id)
                  }
                  entityid={this.state.entity}
                  hasAccess={this.state.hasAccess}
                  page={0}
                />{" "}
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  marginTop: 150,
                  marginBottom: 150,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress
                  style={{ width: "80px", height: "80px", color: "#4b2e83" }}
                  className={classes.progress}
                />
                <h4 style={{ marginTop: "25px", marginLeft: "5px" }}>
                  Loading Mapped Devices
                </h4>
              </div>
            )}
          </div>
        ) : (
          <Paper
            style={{
              marginTop: "20px",
              width: "100%",
              height: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 30,
            }}
          >
            <h4>
              Please select the Organization and Collection to see mapped devices
            </h4>
          </Paper>
        )}
      </div>
    </div>
  );
  }
}

ManageDevices.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const {
    entities,
    collections,
    locations,
    deviceFlags,
    assetTypes,
    assetManufacturer,
    assetModel,
    assets
  } = state;
  return {
    entities,
    collections,
    locations,
    deviceFlags,
    assetTypes,
    assetManufacturer,
    assetModel,
    assets
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getEntities: () => getEntity(dispatch),
    getLocation: entityId => getLocations(dispatch, entityId),
    getCollection: (entityId, locationId = "") =>
      getCollections(dispatch, entityId, locationId),
    getFlags: () => getDeviceFlags(dispatch),
    getAssetTypes: () => getAssetType(dispatch),
    getAssetManufacturers: () => getAssetManufacturer(dispatch),
    getAssetModels: () => getAssetModel(dispatch),
    resetData: () => dispatch(cleanData()),
    showMessageBox: message => dispatch(showMessage(message)),
    getAsset: params => getAssets(dispatch, params)
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(ManageDevices);
