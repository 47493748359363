import * as React from 'react';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Wizzard from './Wizzard';
import { WizzardContext } from './Wizzard';
import { apiRequest, byName, atLastPageScrollToTop, meridianLocationId } from "../utils/Utils";
import { withStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import Button from "@material-ui/core/Button";
import Dropzone from 'react-dropzone';
import { CSVLink } from "react-csv";
import Edit from "@material-ui/icons/Edit";
//import DownloadIcon from "@material-ui/icons/Download";
import Done from '@material-ui/icons/DoneTwoTone';
import Add from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import PropTypes from "prop-types";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Papa from 'papaparse';
import CircularProgress from "@material-ui/core/CircularProgress";
import { showMessage } from "../actions/index";
import { compose } from "recompose";
import { connect } from "react-redux";
import './Bulk.css';

const rows = [
  { id: "Name", disablePadding: false, label: "NAME" },
  //{ id: "External Ref ID", disablePadding: false, label: "EXTERNAL REF ID" },
  { id: "MAC", disablePadding: false, label: "MAC" },
  { id: "Issue", disablePadding: false, label: "ISSUE" },
  { id: "Delete", disablePadding: false, label: "" },
];

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

function desc(a, b, orderBy) {
  let cmpa = a[orderBy] ? a[orderBy].toLowerCase() : "";
  let cmpb = b[orderBy] ? b[orderBy].toLowerCase() : "";
  if (cmpb < cmpa) {
    return -1;
  }
  if (cmpb > cmpa) {
    return 1;
  }
  return 0;
}

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      columns
    } = this.props;
    return (
      <TableHead>
        <TableRow style={{ height: 45 }}>
          {rows.map(
            row => (
              <TableCell
                style={{ color: "#4b2e83", whiteSpace: "nowrap" }}
                key={row.id}
                align={row.numeric ? "right" : "left"}
                padding={row.disablePadding ? "none" : "default"}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    style={{ color: "#4b2e83" }}
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  numSelected: PropTypes.number.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};



const styles = theme => ({
    root: {
      width: "100%",
      marginTop: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 2,
    },
    rootcheckbox: {
      color: "#4b2e83",
      "&$checked": {
        color: "#4b2e83"
      }
    },
    table: {
      minWidth: 50
    },
    tableWrapper: {
      overflowX: "auto"
    },
    button: {
      color: "#4b2e83",
      height: "40px",
      width: "100%",
      padding: "0px !important"
    },
    input: {
      height: 38
    },
    label: {
      marginTop: "-5px"
    },
    textField: { width: "100%", margin: "0px !important" },
    meta: {display: "flex",flexDirection: "column", width: 400, gap: "20px"},
    progress: {
      color: "#4b2e83"
    }
  });

class Upload extends React.Component {
    constructor(props) {
      super(props);
      this.tags_err = [];
      this.state = {
        manufacturerData: [],
        modelData: [],
        typeData: [],
        file: "",
        data: [],
        existingTags:  [],

        order: "asc",
        orderBy: "sno",
        page: 0,
        rowsPerPage: 5,
      }
    }

  componentWillMount() {
    apiRequest("/assets", "get")
    .then(response => {
      this.setState({existingTags: response.data});
    });
    this.fetchData("manufactures");
    this.fetchData("assettypes");
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page }, ()=>{
      atLastPageScrollToTop(this.state.data.length, this.state.rowsPerPage, page);
    });
  };

  handleChangeRowsPerPage = event => {
    if(event.target.value * this.state.page > this.state.data.length && this.state.page != 0)
      this.setState({ rowsPerPage: event.target.value, page: 0 });
    else
      this.setState({ rowsPerPage: event.target.value });
  };

  fetchData(dataType, id = "") {
    let url =
      dataType === "assetmodels"
        ? `/masters/${dataType}/${id}`
        : `/masters/${dataType}`;

    apiRequest(url, "get")
      .then(response => {
        const data = response.data.map(c => {
          return {
            id: c.ID,
            name: c.Value
          };
        });
        switch (dataType) {
          case "manufactures":
            this.setState({
              manufacturerData: data.sort(byName)
            });
            break;
          case "assetmodels":
            this.setState({
              modelData: data.sort(byName)
            });
            break;
          case "assettypes":
            this.setState({
              typeData: data.sort(byName)
            });
            break;
        }
      })
      .catch(error => console.log(error));
  }

  clearValidation() {
    this.tags_err.forEach((e, id) => { 
      this.tags_err[id].mac = "";
      this.tags_err[id].name = "";
    });
    this.state.data.forEach((r, id) => {
      r.error.name = "";
      r.error.mac = "";
      r.error.externalId = "";
    });
  }
  validationCheck(setDisable) {
    this.clearValidation();
    let errorsno = 0;
    if (this.state.data && this.state.data.length > 0) {
        this.state.data.forEach((r, id) => {
            if (r["MAC"].length == 0) {
                errorsno++;
                this.tags_err[id].mac = "MAC is required";
                r.error.mac = "MAC is required";
            }
            if (r["Name"].trim().length == 0) {
              errorsno++;
              this.tags_err[id].name = "Name is required";
              r.error.name = "Name is required";
            }
            if (r["MAC"].length != 0 && this.state.data.find((d, index) => d.MAC.toLowerCase() == r.MAC.toLowerCase() && id != index)) {
                errorsno++;
                this.tags_err[id].mac = "Duplication detected, please remove one";
                r.error.mac = "Duplication detected, please remove one";
            }
            if (r["Name"].trim().length != 0 && this.state.data.find((d, index) => d.Name == r.Name && id != index)) {
              errorsno++;
              this.tags_err[id].name = "Duplication detected, please remove one";
              r.error.name = "Duplication detected, please remove one";
            }
            if (r["MAC"].length != 0 && this.state.existingTags.find(t => t.MacId.toLowerCase() == r["MAC"].toLowerCase())) {
                errorsno++;
                this.tags_err[id].mac = "This MAC already exists";
                r.error.mac = "This MAC already exists";
            }
            if (r["Name"].trim().length != 0 && this.state.existingTags.find(t => t.Name == r["Name"].trim())) {
              errorsno++;
              this.tags_err[id].name = "This Name already exists";
              r.error.name ="This Name already exists";
            }

            if (r["MAC"].length != 0 && !(r["MAC"].match(/^[0-9a-fA-F]+$/))) {
              errorsno++;
              this.tags_err[id].mac = "Mac is not valid";
              r.error.mac = "Mac is not valid";
            }
            if (r["MAC"].length != 0 && r["MAC"].length != 12) {
              errorsno++;
              this.tags_err[id].mac = "MAC must be 12 characters";
              r.error.mac ="MAC must be 12 characters";
            }
            if (r["Name"].length != 0 && r["Name"].length > 500) {
              errorsno++;
              this.tags_err[id].mac = "Name can not be longer than 500 characters";
              r.error.name ="Name can not be longer than 500 characters";
            }
            // if (r["External Ref ID"].length != 0 && r["External Ref ID"].length > 500) {
            //   errorsno++;
            //   r.error.externalId ="External Ref ID can not be longer than 500 characters";
            // }
            // if (r["MAC"].trim().length != 0 && r["MAC"].trim().length > 12) {
            //   errorsno++;
            //   this.tags_err[id].mac = "MAC can not be longer than 12 characters";
            //   r.error.mac = "MAC can not be longer than 12 characters";
            // }
            // if (r["MAC"].trim().length != 0 && r["MAC"].trim().length < 12) {
            //   errorsno++;
            //   this.tags_err[id].mac = "MAC can not be less than 12 characters";
            //   r.error.mac ="MAC can not be less than 12 characters";
            // }    
        });
        this.setState({ numberOfIssue: errorsno });
    }
    if(errorsno == 0 && this.state.data.length > 0)
      setDisable({disabled:false, issues: 0});
    else
      setDisable({disabled:true, issues: errorsno});
    return errorsno;
}

  onSave = () => {
    for(let i = 0; i< this.state.data.length;i++) {
      let data = {
        MeridianLocationId: meridianLocationId,
        MacId: this.state.data[i].MAC,
        Name:  this.state.data[i].Name.trim(),
        //ExternalId: this.state.data[i]["External Ref ID"].trim(),
        Manufacturer: this.state.manufacturerObject ? this.state.manufacturerObject.name : "",
        DeviceModel: this.state.modelObject ? this.state.modelObject.name : "",
        DeviceType: this.state.typeObject ? this.state.typeObject.name : ""
      };
      apiRequest("/asset/create", "post", data)
        .then(x => {
          // if (x.status > 0) {
          //   console.log("good");
          // }
          // else {
          //   console.log("issue");
          // }
          if(i == this.state.data.length-1) {
            // if (x.status > 0 && x.message == null)
            //   this.props.showMessageBox("SUCCESS");
            // else
            //   this.props.showMessageBox(x.message);
            this.props.showMessageBox("Tag Import action completed");
          }
      })
      .catch(err => {
        this.props.showMessageBox("ERROR");
      });
      this.props.history.push("/devices/map");
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <Wizzard steps={[
        { name: 'Upload file', isSkippable: false },
        { name: 'Metadata Configuration', isSkippable: false },
        { name: 'Summary', isSkippable: false }]} onSave={this.onSave}>
        <WizzardContext.Consumer>
          {(data) => {
            if (data.activeStep == 0)
              return (
                <Box gap="small">
                  <h3>Add Tags</h3>
                  Add multiple tags by browsing to a file on your device.
                  <Box>
                    <Box direction="row" justify="between" style={{ maxWidth: "600px" }}>
                      <Box style={{ maxWidth: "600px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "end" }}>
                      <Tooltip id="download_template_info" placement="top-start" title="Download the template file, add your tag information and upload it.">
                        <CSVLink data={[["Name", /*"External Ref ID",*/ "MAC"],]} filename="tags-template">
                          <IconButton>
                            <Edit color="primary" />
                          </IconButton>
                        </CSVLink>
                        </Tooltip>
                        <Box>
                          <div>Download Template</div>
                        </Box>
                      </Box>
                    </Box>
                    <Box /*border="all"*/ style={{ maxWidth: "600px" }}>
                      {!this.state.file &&
                        <Dropzone   accept= {{
                          'text/csv': ['.csv'],
                          //'application/vnd.ms-excel': ['.xls'],
                        }} maxFiles={1} onDrop={acceptedFiles => {
                          ///reader start
                          acceptedFiles.forEach((file) => {
                            const reader = new FileReader();
                            reader.onprogress = (evt) => {
                              this.setState({ progressValue: parseInt(((evt.loaded / evt.total) * 100), 10) });
                            };
                            reader.onabort = () => console.log('file reading was aborted');
                            reader.onerror = () => console.log('file reading has failed');
                            reader.onload = () => {
                              // Do whatever you want with the file contents
                              const binaryStr = reader.result;
                              console.log(binaryStr);
                            }
                            reader.readAsArrayBuffer(file);
                          })
                          ///reader end

                          this.setState({ file: acceptedFiles[0] });
                          if (/*acceptedFiles[0].type == "application/vnd.ms-excel" ||*/ acceptedFiles[0].type == "text/csv") {
                            Papa.parse(acceptedFiles[0], {
                              delimiter: "",
                              header: true,
                              skipEmptyLines: "greedy",
                              //step: (row, parser) => {
                              //    console.log("Row data:", row.data);
                              //    console.log("Row errors:", row.errors);
                              //    this.setState(prevState => ({
                              //        data: [...prevState.data, row.data]
                              //    }));
                              //},
                              complete: (results) => {
                                console.log(results);
                                if (results && results.data && results.data.length > 0 && results.data[0].hasOwnProperty("MAC") && results.data[0].hasOwnProperty("Name")) {
                                  for (let i = 0; i <= results.data.length; i += 1) {
                                    this.tags_err.push({ mac: "", name: "", externalId: "",id: i+1 });
                                  }
                                  this.setState({ data: results.data.map((x,i)=>({...x, id: i+1, error: { mac: "", name: "", externalId: "" } })),  uploadIssue: "" },()=> {
                                    this.validationCheck(data.setStepDisable);
                                    //data.setStepDisable(false)
                                  });
                                }
                                else
                                this.setState({ data: [], file: undefined, uploadIssue: "The uploaded file does not align with the latest template. Please download the latest template, edit it and upload again." });
                              }
                            });
                          }
                        }}>
                          {({ getRootProps, getInputProps }) => (
                            <div className="upload-container">
                              <div {...getRootProps()} className="drop-zone">
                                <Box align="center" margin={{ top: "10px" }}>
                                  <Add/>
                                </Box>
                                <input {...getInputProps()} />
                                <p>Drag and drop or browse to your files</p>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      }
                      {this.state.file &&
                        <Box style={{ maxWidth: "600px" }} /*align="center" border="all"*/ className="upload-container">
                          {this.state.progressValue == 100 ?
                            <Done /> //<DocumentCsv size="medium" />
                            :
                            <div>
                              {/*<CircularProgress style={{ width: "80px", height: "80px" }} className={classes.progress}/> */}
                            </div>
                          }
                          {this.state.file.name}
                          <Box width="80%">
                            <LinearProgress variant="determinate" value={this.state.progressValue} /*type="bar" round background="light-3" max={100} values={[{ value: this.state.progressValue, color: "brand" }]}*/ />
                            <Box style={{display: "flex", justifyContent: "end" }}>
                              Upload{this.state.progressValue == 100 ? "ed" : "ing"} {this.state.progressValue}%
                            </Box>
                          </Box>
                        </Box>
                      }
                      {this.state.uploadIssue}
                    </Box>
                  </Box>
                  <Box id="table" /*style={{"overflow-y": "overlay"}}*/>
                    {this.state.data && 
                    <Paper
                    className={classes.root1}
                    style={{
                      //padding: "17px",
                      //margin: "15px 12px",
                      // padding: "4px 10px 10px 10px",
                      // margin: "20px 12px"
                    }}
                  >
                    {this.state.data.length > 0 ? (
                      <div className={classes.tableWrapper}>
                        <Table
                          className={classes.table}
                          aria-labelledby="tableTitle"
                        >
                          <EnhancedTableHead
                            numSelected={this.state.data.length}
                            order={this.state.order}
                            orderBy={this.state.orderBy}
                            onRequestSort={this.handleRequestSort}
                            rowCount={this.state.data.length}
                            columns={Object.keys(this.state.data[0]).map(key=> key)}
                          />
  
                          <TableBody>
                            {stableSort(
                              this.state.data,
                              getSorting(this.state.order, this.state.orderBy)
                            )
                              .slice(
                                this.state.page * this.state.rowsPerPage,
                                this.state.page * this.state.rowsPerPage +
                                  this.state.rowsPerPage
                              )
                              .map((n,idx) => {
                                const isSelected = idx%2 == 0 ? true : false; //this.isSelected(n.UserId);
                                return (
                                  <TableRow
                                    //hover
                                    aria-checked={isSelected}
                                    tabIndex={-1}
                                    key={n.UserId}
                                    style={{
                                      height: 45 /*whiteSpace: "nowrap"*/
                                    }}
                                    selected={isSelected}
                                  >
                                    {false && Object.keys(n).map((key, index) => (
                                      <TableCell>
                                        <TextField
                                          error={this.tags_err[idx].name != ""}
                                          required={index != 1 ? true : false}
                                          
                                          label={key}
                                          value={n[key]}
                                          type="text"
                                          style={{
                                            width: "100%",
                                            borderColor: "#4b2e83"
                                          }}
                                          //disabled={this.state.disableFields || (this.props.params === "Modify" && !this.state.stateModel.Description)}
                                          onChange={evt => {
                                            this.setState({ Description: evt.target.value })
                                          }}
                                          margin="dense"
                                          //multiline
                                          //rows="3"
                                          variant="outlined"
                                          placeholder="Description"
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                        />
                                      </TableCell>
                                    ))}
                                    <TableCell>
                                      <TextField
                                        //error={this.tags_err[idx].name != ""}
                                        error={n.error.name}
                                        required={true}
                                        
                                        label="Name"
                                        value={n["Name"]}
                                        type="text"
                                        style={{
                                          width: "100%",
                                          borderColor: "#4b2e83"
                                        }}
                                        onChange={evt => {
                                          this.setState({
                                            data: this.state.data.map((item, i) => {
                                              if (/*n.Name == item["Name"] && i == idx*/ n.id == item.id)
                                                return {
                                                  ...item,
                                                  Name: evt.target.value,
                                                }
                                              else
                                                return item;
                                            })
                                          },()=> this.validationCheck(data.setStepDisable));
                                        }}
                                        margin="dense"
                                        //multiline
                                        //rows="3"
                                        variant="outlined"
                                        placeholder="Description"
                                        InputLabelProps={{
                                          shrink: true
                                        }}
                                      />
                                    </TableCell>
                                    {false && <TableCell>
                                      <TextField
                                        error={n.error.externalId}
                                        required={false}
                                        label="External Ref ID"
                                        value={n["External Ref ID"]}
                                        type="text"
                                        style={{
                                          width: "100%",
                                          borderColor: "#4b2e83"
                                        }}
                                        onChange={evt => {
                                          this.setState({
                                            data: this.state.data.map((item, i) => {
                                              if (/*n["External Ref ID"] == item["External Ref ID"] && i == idx*/n.id == item.id)
                                                return {
                                                  ...item,
                                                  "External Ref ID": evt.target.value,
                                                }
                                              else
                                                return item;
                                            })
                                          },()=> this.validationCheck(data.setStepDisable));
                                        }}
                                        margin="dense"
                                        //multiline
                                        //rows="3"
                                        variant="outlined"
                                        placeholder="External Ref ID"
                                        InputLabelProps={{
                                          shrink: true
                                        }}
                                      />
                                    </TableCell>}
                                    <TableCell>
                                      <TextField
                                        //error={this.tags_err[idx].mac != ""}
                                        error={n.error.mac}
                                        required={true}
                                        
                                        label="Mac"
                                        value={n["MAC"]}
                                        type="text"
                                        style={{
                                          width: "100%",
                                          borderColor: "#4b2e83"
                                        }}
                                        onChange={evt => {
                                          this.setState({
                                            data: this.state.data.map((item, i) => {
                                              if (/*n.MAC == item["MAC"] && i == idx*/ n.id == item.id)
                                                return {
                                                  ...item,
                                                  MAC: evt.target.value,
                                                }
                                              else
                                                return item;
                                            })
                                          },()=> this.validationCheck(data.setStepDisable));
                                        }}
                                        margin="dense"
                                        //multiline
                                        //rows="3"
                                        variant="outlined"
                                        placeholder="Mac"
                                        InputLabelProps={{
                                          shrink: true
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      {/*this.tags_err[idx].name || this.tags_err[idx].mac*/}
                                      {n.error.name || n.error.mac /*|| n.error.externalId*/}
                                    </TableCell>
                                    <TableCell>
                                      <IconButton
                                        onClick={() => {
                                          this.tags_err = this.tags_err.filter((item, i) => i != idx);
                                          this.setState({ data: this.state.data.filter((item, i) => /*i != idx*/item.id != n.id) }, () => this.validationCheck(data.setStepDisable));
                                        }}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
  
                        <TablePagination
                      rowsPerPageOptions={[5,10,25, 50, 100]}
                      component="div"
                      count={this.state.data/*data*/.length}
                      rowsPerPage={this.state.rowsPerPage}
                      page={this.state.page}
                      backIconButtonProps={{
                        "aria-label": "Previous Page"
                      }}
                      nextIconButtonProps={{
                        "aria-label": "Next Page"
                      }}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                      </div>
                    ) : (
                      <div
                        style={{
                          marginTop: "10px",
                          width: "100%",
                          //height: "500px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: 30
                        }}
                      >
                        <h4>No Data Uploaded.</h4>
                      </div>
                    )}
                  </Paper>
                    }
                  </Box>
                </Box>);
            if (data.activeStep == 1)
              return <div className={classes.meta}>
                  <h3>Metadata Configuration</h3>
                <Autocomplete
                  id="manu"
                  disabled={this.state.manufacturerData.length == 0}
                  options={this.state.manufacturerData.sort(byName)}
                  value={this.state.manufacturerObject}
                  getOptionSelected={(option, value) => option.id == value.id}
                  getOptionLabel={(option) => option.name}
                  onChange={(evt, obj) => {
                    if (obj == null) {
                      this.setState({ manufacturerObject: obj, modelObject: obj, modelData: [] });
                    }
                    else {
                      this.setState({ manufacturerObject: obj, modelObject: null }, () => {
                        if (obj)
                          this.fetchData("assetmodels", obj.id);
                      });
                    }
                  }
                  }
                  renderOption={(option) => (
                    <div style={{ padding: "8px 2px" }}>{option.name}</div>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Manufacturer"
                      variant="outlined"
                      margin="dense"
                      className={classes.textField}
                    />
                  )}
                />

                <Autocomplete
                  id="model"
                  disabled={!this.state.manufacturerObject || this.state.modelData.length == 0}
                  options={this.state.modelData.sort(byName)}
                  value={this.state.modelObject ? this.state.modelObject : null}
                  defaultValue={null}
                  getOptionSelected={(option, value) => option.id == value.id}
                  getOptionLabel={(option) => option.name}
                  onChange={(evt, obj) => { this.setState({ modelObject: obj }) }}
                  renderOption={(option) => (
                    <div style={{ padding: "8px 2px" }}>{option.name}</div>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Model"
                      variant="outlined"
                      margin="dense"
                      className={classes.textField}

                    />
                  )}
                />

                <Autocomplete
                  id="type"
                  disabled={this.state.typeData.length == 0}
                  options={this.state.typeData.sort(byName)}
                  value={this.state.typeObject}
                  getOptionSelected={(option, value) => option.id == value.id}
                  getOptionLabel={(option) => option.name}
                  onChange={(evt, obj) => { this.setState({ typeObject: obj }) }}
                  renderOption={(option) => (
                    <div style={{ padding: "8px 2px" }}>{option.name}</div>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Device Type"
                      variant="outlined"
                      margin="dense"
                      className={classes.textField}
                    />
                  )}
                />
              </div>
            if (data.activeStep == 2)
              return (
              <div class="summary">
                <h3>Review & Create</h3>
                <div><CheckIcon/><b>{this.state.data.length > 1 ? this.state.data.length + " tags added" : "1 tag added"} </b></div>
                <div><CheckIcon/><b>Manufacturer:</b> {this.state.manufacturerObject ? this.state.manufacturerObject.name : "No Selection"}</div>
                <div><CheckIcon/><b>Model:</b> {this.state.modelObject ? this.state.modelObject.name : "No Selection"}</div>
                <div><CheckIcon/><b>Type:</b> {this.state.typeObject ? this.state.typeObject.name : "No Selection"}</div>
                <div>When "Finish" is selected the tag(s) added will be created.</div>
                <div>Note this may take time, so please wait for the activity completion message. Once complete, the Assets table will be updated so please review the Assets table to verify that the tag(s) were added successfully.</div>
              </div>);
          }}
        </WizzardContext.Consumer>
      </Wizzard>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showMessageBox: message => dispatch(showMessage(message))
  };
};

export default compose(
  connect(
    //mapStateToProps,
    null,
    mapDispatchToProps
  ),
  withStyles(styles)
)(Upload);

//export default withStyles(styles)(Upload)