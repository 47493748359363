import React, { Component } from "react";
import PropTypes from "prop-types";
import ManageUsers from "../components/ManageUsersComp.jsx";
import { Link } from "react-router-dom";
import { getMaxEntityAccess } from "../utils/Utils";
import Grid from "@material-ui/core/Grid";
import {PFContext} from "../components/PrimaryFilterContext";

class DeviceManage extends Component {
  state = {
    showTabs: true
  };
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };
  componentDidMount() {
    // if (getMaxEntityAccess() == "700") {
    //   this.setState({
    //     showTabs: true
    //   });
    // } else {
    //   this.setState({
    //     showTabs: false
    //   });
    // }
  }
  render() {
    return (
      <div>
        <div
          id="content"
          style={{
            //paddingTop: "60px",
            transition: "0.25s",
            paddingLeft: "0px",
          }}
        >
          <div className="container-fluid">
            <div className="row">
              <div
                className="col-lg-12"
                style={{
                  height: "100%",
                  width: "100%",
                }}
              >
                {this.state.showTabs && (
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <div
                        style={{
                          textAlign: "center",
                          borderBottom: "2px solid lightgrey",
                        }}
                      >
                        <Link className="nav-link" to="/options/users">
                          <h5
                            style={{
                              color: "lightgrey",
                              marginTop: "0.5rem"
                            }}
                          >
                            User Management
                          </h5>
                        </Link>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div
                        style={{
                          textAlign: "center",
                          borderBottom: "2px solid #4b2e83",
                        }}
                      >
                        <div className="nav-link">
                          <h5
                            style={{
                              color: "#4b2e83",
                              marginTop: "0.5rem"
                            }}
                          >
                            Role Management
                          </h5>{" "}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                )}
                <PFContext.Consumer>
                  {(data) => <ManageUsers values={data}/>}
                </PFContext.Consumer>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}



export default DeviceManage;
