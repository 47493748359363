import React, { Component } from "react";
import "../pages/Home.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import OrphanedIcon from "@material-ui/icons/Error";
import { Link } from "react-router-dom";
import { apiRequest, isPortalAdmin, byPropertyCalled } from "../utils/Utils";
import {PFContext} from "../components/PrimaryFilterContext";

const strokewidth = 10;

class About extends Component {
  constructor(props) {
    super(props);
    this.entity = props.primaryFilter.entity;
    this.state = {
      collapse: false,
      isLoading: true,
      dashboardData: {
        orphan: 0,
        total: 0,
        battery_below_30: 0,
        entities: [],
      },
    };
  }
  componentWillReceiveProps(props) {
    if(props.primaryFilter && this.entity != props.primaryFilter.entity) {
      this.entity = props.primaryFilter.entity;
      this.setState({isLoading: true});
      var url;
      if (props.primaryFilter.entity != "") {
        url = `/dashboard?entity_id=${props.primaryFilter.entity}`;
      } else {
        url = `/dashboard`;
      }
      apiRequest(url, "get").then((x) => {
        let data = x.data[0];
        let dd = this.state.dashboardData;
        dd.orphan = data.total_orphans;
        dd.battery_below_30 = data.total_low_battery_30;
        dd.total = data.total_items;
        dd.entities = data.entities;
        dd.entities.sort(byPropertyCalled("entity_name"));
        this.setState({ dashboardData: dd, isLoading: false });
      });
    }
  }
  componentDidMount() {
    var url;
    if (sessionStorage.getItem("entityID")) {
      url = `/dashboard?entity_id=${sessionStorage.getItem("entityID")}`;
    } else {
      url = `/dashboard`;
    }
    apiRequest(url, "get").then((x) => {
      let data = x.data[0];
      let dd = this.state.dashboardData;
      dd.orphan = data.total_orphans;
      dd.battery_below_30 = data.total_low_battery_30;
      dd.total = data.total_items;
      dd.entities = data.entities;
      dd.entities.sort(byPropertyCalled("entity_name"));
      this.setState({ dashboardData: dd, isLoading: false });
    });
  }

  setDashboardData(option) {
    this.setState({ dashboardData: option }).bind(this);
  }

  render() {
    return (
      <div>
        <div>
          <div
            id="content"
            style={{
              //paddingTop: "60px",
              transition: "0.25s",
              paddingLeft: "0px",
            }}
          >
            <div className="container-fluid">
              <div
                className="col-lg-12"
                style={{
                  height: "100%",
                  width: "100%",
                }}
              />
              {!this.state.isLoading ? (
                <div className="pb-2 mb-3 uowDashboard-paper-wrapper">
                  <div
                    className="uowDashboard-block-title"
                    style={{ float: "right", padding: 10 }}
                  >
                    Total Devices: {this.state.dashboardData.total}
                  </div>
                  <div className="uowDashboard-paper">
                    <div className="uowDashboard-block-title">
                      Global Values
                    </div>
                    <div>
                      <div className="uowDashboard-wrapper">
                      <Link
                        to="/devices/map?flag=orphan"
                        style={{ color: "white" }}
                      >
                        <div className="uowDashboard-item item-1">
                          {/* <div className="uowDashboard-itemicon itemicon-battery"></div> */}
                          <div className="uowDashboard-item-row">
                              <div className="uowDashboard-thevalue">
                                {this.state.dashboardData.orphan}
                              </div>
                            <div className="uowDashboard-circular">
                              <CircularProgressbarWithChildren
                                value={
                                  this.state.dashboardData.total == 0
                                    ? 0
                                    : Math.floor(
                                        100 *
                                          (this.state.dashboardData.orphan /
                                            this.state.dashboardData.total)
                                      )
                                }
                                strokeWidth={strokewidth}
                                styles={{
                                  root: {
                                    height: "100px",
                                    width: "100px",
                                    fontSize: "10px",
                                  },
                                  path: {
                                    stroke: "white",
                                    strokeLinecap: "butt",
                                    transition:
                                      "stroke-dashoffset 0.5s ease 0s",
                                    transformOrigin: "center center",
                                  },
                                  trail: {
                                    stroke: "lightgrey",
                                    strokeLinecap: "butt",
                                    transformOrigin: "center center",
                                  },
                                  text: { fill: "#4b2e83", fontSize: "12px" },
                                  background: { fill: "#4b2e83" },
                                }}
                              >
                                <OrphanedIcon
                                  style={{
                                    color: "#f3f3f3",
                                    width: 25,
                                    height: 25,
                                    marginTop: -5,
                                  }}
                                />
                                <div
                                  style={{
                                    marginTop: 5,
                                    color: "#f3f3f3",
                                    fontWeight: "normal",
                                    fontFamily: "Arial",
                                    fontSize: "20px",
                                  }}
                                >
                                  <strong>
                                    {this.state.dashboardData.total == 0
                                      ? 0
                                      : Math.floor(
                                          100 *
                                            (this.state.dashboardData.orphan /
                                              this.state.dashboardData.total)
                                        )}{" "}
                                    %
                                  </strong>
                                </div>
                              </CircularProgressbarWithChildren>
                            </div>
                          </div>
                          <div className="uowDashboard-item-row">
                            <div className="uowDashboard-header">ORPHANS</div>
                            <div className="uowDashboard-description">
                              All devices in all entities
                            </div>
                          </div>
                        </div>
                      </Link>

                      <Link
                        onClick={()=> this.props.primaryFilter.handeChange("")}
                        style={{ color: "white" }}
                        to={`/devices/viewtable?flag=LOW_BATTERY_30`}
                      >
                        <div className="uowDashboard-item item-1">
                          {/* <div className="uowDashboard-itemicon itemicon-battery"></div> */}
                          <div className="uowDashboard-item-row">
                            {isPortalAdmin ? (
                                <div className="uowDashboard-thevalue">
                                  {this.state.dashboardData.battery_below_30}
                                </div>
                            ) : (
                              <div
                                className="uowDashboard-thevalue"
                                style={{ textDecoration: "none" }}
                              >
                                {this.state.dashboardData.battery_below_30}
                              </div>
                            )}
                            <div className="uowDashboard-circular">
                              <CircularProgressbarWithChildren
                                value={
                                  this.state.dashboardData.total == 0
                                    ? 0
                                    : Math.floor(
                                        100 *
                                          (this.state.dashboardData
                                            .battery_below_30 /
                                            this.state.dashboardData.total)
                                      )
                                }
                                strokeWidth={strokewidth}
                                styles={{
                                  root: {
                                    height: "100px",
                                    width: "100px",
                                    fontSize: "10px",
                                  },
                                  path: {
                                    stroke: "white",
                                    strokeLinecap: "butt",
                                    transition: "stroke-dashoffset 5s ease 5s",
                                    transformOrigin: "center center",
                                  },
                                  trail: {
                                    stroke: "lightgrey",
                                    strokeLinecap: "butt",
                                    transformOrigin: "center center",
                                  },
                                  text: { fill: "#4b2e83", fontSize: "12px" },
                                  background: { fill: "#4b2e83" },
                                }}
                              >
                                <OrphanedIcon
                                  style={{
                                    color: "#f3f3f3",
                                    width: 25,
                                    height: 25,
                                    marginTop: -5,
                                  }}
                                />
                                <div
                                  style={{
                                    marginTop: 5,
                                    color: "#f3f3f3",
                                    fontWeight: "normal",
                                    fontFamily: "Arial",
                                    fontSize: "20px",
                                  }}
                                >
                                  <strong>
                                    {this.state.dashboardData.total == 0
                                      ? 0
                                      : Math.floor(
                                          100 *
                                            (this.state.dashboardData
                                              .battery_below_30 /
                                              this.state.dashboardData.total)
                                        )}{" "}
                                    %
                                  </strong>
                                </div>
                              </CircularProgressbarWithChildren>
                            </div>
                          </div>
                          <div className="uowDashboard-item-row">
                            <div className="uowDashboard-header">
                              LOW CHARGE
                            </div>
                            <div className="uowDashboard-description">
                              Low Charge &lt; 30% remaining
                            </div>
                          </div>
                        </div>
                      </Link>
                      </div>
                    </div>
                  </div>

                  {this.state.dashboardData.entities.length > 0 ? (
                    <div className="uowDashboard-paper">
                      {this.state.dashboardData.entities.map((entity) => {
                        return (
                          <React.Fragment key={entity.entity_id}>
                            <Link
                              onClick={()=> this.props.primaryFilter.handeChange(entity.entity_id)}
                              style={{ "text-decoration": "none" }}
                              to={`/devices/viewtable?id=${entity.entity_id}&`}
                            >
                              <div className="uowDashboard-nav-title">
                                {`${entity.entity_name} (Total Devices:${entity.total_items})`}
                              </div>
                            </Link>
                            <div>
                              <div className="uowDashboard-wrapper">
                                {entity.items
                                  .filter((x) => x.Key != "ORPHAN")
                                  .map((item) => {
                                    let details = "";
                                    if (item.Key == "NOT_MAPPED") {
                                      details =
                                        "Devices not mapped to a Department";
                                    }
                                    if (item.Key == "NOT_TRACEABLE") {
                                      details = "Devices not traceable";
                                    }
                                    if (item.Key == "MISSING_SYNC") {
                                      details =
                                        "Devices missing since last sync";
                                    }
                                    if (item.Key == "HEALTHY") {
                                      details = "Healthy Devices";
                                    }
                                    if (item.Key == "LOW_BATTERY_30") {
                                      details = "Devices having battery <30";
                                    }
                                    return (
                                    <Link
                                      onClick={()=> this.props.primaryFilter.handeChange(entity.entity_id)}
                                      style={{ color: "white" }}
                                      to={
                                        item.Key == "NOT_MAPPED"
                                          ? `devices/manage/dept?id=${entity.entity_id}&flag=${item.Key}`
                                          : `/devices/viewtable?id=${entity.entity_id}&flag=${item.Key}`
                                      }
                                    >
                                      <div
                                        key={item.Key}
                                        className="uowDashboard-item item-1"
                                      >
                                        <div className="uowDashboard-item-row">
                                            <div className="uowDashboard-thevalue">
                                              {item.Value}
                                            </div>
                                          <div className="uowDashboard-circular">
                                            <CircularProgressbarWithChildren
                                              value={
                                                entity.total_items == 0
                                                  ? 0
                                                  : Math.floor(
                                                      100 *
                                                        (item.Value /
                                                          entity.total_items)
                                                    )
                                              }
                                              strokeWidth={strokewidth}
                                              styles={{
                                                root: {
                                                  height: "100px",
                                                  width: "100px",
                                                  fontSize: "10px",
                                                },
                                                path: {
                                                  stroke: "white",
                                                  strokeLinecap: "butt",
                                                  transition:
                                                    "stroke-dashoffset 0.5s ease 0s",
                                                  transformOrigin:
                                                    "center center",
                                                },
                                                trail: {
                                                  stroke: "lightgray",
                                                  strokeLinecap: "butt",
                                                  transformOrigin:
                                                    "center center",
                                                },
                                                text: {
                                                  fill: "#4b2e83",
                                                  fontSize: "12px",
                                                },
                                                background: { fill: "#4b2e83" },
                                              }}
                                            >
                                              <OrphanedIcon
                                                style={{
                                                  color: "#f3f3f3",
                                                  width: 25,
                                                  height: 25,
                                                  marginTop: -5,
                                                }}
                                              />
                                              <div
                                                style={{
                                                  marginTop: 5,
                                                  color: "#f3f3f3",
                                                  fontWeight: "normal",
                                                  fontFamily: "Arial",
                                                  fontSize: "20px",
                                                }}
                                              >
                                                <strong>
                                                  {entity.total_items == 0
                                                    ? 0
                                                    : Math.floor(
                                                        100 *
                                                          (item.Value /
                                                            entity.total_items)
                                                      )}{" "}
                                                  %
                                                </strong>
                                              </div>
                                            </CircularProgressbarWithChildren>
                                          </div>
                                        </div>
                                        <div className="uowDashboard-item-row">
                                          <div className="uowDashboard-header">
                                            {item.Key == "NOT_MAPPED"
                                              ? "NO DEPARTMENT"
                                              : item.Key == "LOW_BATTERY_30"
                                              ? "LOW CHARGE"
                                              : item.Key.replace("_", " ")}
                                          </div>
                                          <div className="uowDashboard-description">
                                            {details}
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                    );
                                  })}
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              ) : (
                <div
                  style={{
                    width: "100%",
                    marginTop: 150,
                    marginBottom: 150,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress
                    style={{
                      width: "80px",
                      height: "80px",
                      color: "#4b2e83",
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
