import React from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TablePagination from "@material-ui/core/TablePagination";
import PropTypes from 'prop-types';

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import MapIcon from "@material-ui/icons/Map";

import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/HighlightOff";
import SvgIcon from '@material-ui/core/SvgIcon';
import Page2Map from "../components/Page2Map.jsx";

import Button from "@material-ui/core/Button";
import { CSVLink } from "react-csv";

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { apiRequest, getOption, byPropertyCalled, fetchRequest } from "../utils/Utils";
import CircularProgress from "@material-ui/core/CircularProgress";

var moment = require('moment');
var mtz = require('moment-timezone');
var abbs = mtz.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('z').toString();
const headCells = [
  { id: "map", label: "MAP"},
  { id: "timeLH", label: `LAST HEARD (${abbs})`},
  { id: "time", label: `TIMESTAMP (${abbs})`},
  { id: "MeridianBuildingName", disablePadding: false, label: "CAMPUS"},
  //{ id: "LocationName", disablePadding: false, label: "Building" },
  //{ id: "FloorLabel", disablePadding: false, label: "FLOOR" },
  { id: "FloorNumber", disablePadding: false, label: "FLOOR" },
  { id: "ZoneName", disablePadding: false, label: "ZONE" },
  //{ id: "DeviceFlag", disablePadding: false, label: "DEVICE FLAG" },
  { id: "MacId", disablePadding: false, label: "MAC ADDRESS" }
];

function desc(a, b, orderBy) {
  let cmpa= a[orderBy] ? a[orderBy].toLowerCase(): '';
  let cmpb= b[orderBy] ? b[orderBy].toLowerCase(): '';
  if (cmpb < cmpa) {
    return -1;
  }
  if (cmpb > cmpa) {
    return 1;
  }
  return 0;
}

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            //align={headCell.numeric ? 'right' : 'left'}
            //padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ color: "#4b2e83", whiteSpace: "nowrap" }}
          >
            <Tooltip
              title={
                orderBy === headCell.id ?
                order === "desc"
                  ? "Sorted descending"
                  : "Sorted ascending"
                  : ""
              }
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                style={{ color: "#4b2e83" }}
              >
                {headCell.label}
              </TableSortLabel>
            </Tooltip>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  //classes: PropTypes.object.isRequired,
  //numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  //onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  //rowCount: PropTypes.number.isRequired,
};

class History extends React.Component {
    constructor(props) {
      super();
      this.rows = [];
      this.maps= [];
      this.zones = [];
      this.downloadData = [];
      this.state = {
        order: "desc",
        orderBy: "time",
        showMap: false,
        days: 1,
        rowsPerPage: 50,
        page: 0,
        maps: [],
        zones: {
          results: []
        },
        isLoading: true
      };
      this.daysList=[
        //{id: 0, name: "All", detail: "day", value: 1000, days: 1000}, // ALL MAX 1000day
        {id: 1, name: "Last 24 hours", detail: "day", value: 1, days: 1},
        {id: 2, name: "Last 7 days", detail: "day", value: 7, days: 7},
        {id: 3, name: "Last 30 days", detail: "day", value: 30, days: 30},
        {id: 4, name: "Last 90 days", detail: "day", value: 90, days: 90},
        {id: 5, name: "Last 6 months", detail: "month", value: 6, days: 182},
        {id: 6, name: "Last 12 months", detail: "month", value: 12, days: 365},
        {id: 7, name: "Last 15 months", detail: "month", value: 15, days: 455},
      ];
      this.childDiv = React.createRef();
  }
  componentDidMount() {
    this.getMaps(this.props.assetDetail.MeridianLocationId)
    .then(m=> 
      this.getZones(this.props.assetDetail.MeridianLocationId)
      .then(r=> 
        this.getAssetHistory(null,this.daysList[0].days)
          .then(r=> this.merge())
        ));

    // this.getAssetHistory(null,7)
    // .then(r=> this.merge());

  }

  getAssetHistory(next = null, days = 0) {
    let nexT = null;
    let url = days == 0? 
    `/AssetHistory/${this.props.mac}` :
    `/AssetHistory/${this.props.mac}?days=${days}`
    return fetchRequest(url, "get", next)
    .then(r=> {
      if(r.headers.get('x-ms-continuation-nextrowkey')) {
        nexT = {
          "x-ms-continuation-nextpartitionkey" : r.headers.get('x-ms-continuation-nextpartitionkey'),
          "x-ms-continuation-nextrowkey" : r.headers.get('x-ms-continuation-nextrowkey')
        };
      }
      if(r.ok)
        return r.json();
      return [];
    })
    .then(r=> {
      if(r.value)
        this.rows = this.rows.concat(r.value);
      if(nexT)
        return this.getAssetHistory(nexT, days);
      return this.rows;
    })
  }

  getMaps(locationId,next = null) {
    let nexT = null;
    let url = `/locations/${locationId}/maps`;
    return fetchRequest(url, "get", next)
    .then(r=> {
      if(r.headers.get('x-ms-continuation-nextrowkey')) {
        nexT = {
          "x-ms-continuation-nextpartitionkey" : r.headers.get('x-ms-continuation-nextpartitionkey'),
          "x-ms-continuation-nextrowkey" : r.headers.get('x-ms-continuation-nextrowkey')
        };
      }
      if(r.ok)
        return r.json();
      return [];
    })
    .then(r=> {
      if(r.value)
        this.maps = this.maps.concat(r.value);
      if(nexT)
        return this.getMaps(locationId,nexT);
      return this.maps;
    })
  }

  getZones(locationId,next = null) {
    let nexT = null;
    let url = `/locations/${locationId}/zones`;
    return fetchRequest(url, "get", next)
    .then(r=> {
      if(r.headers.get('x-ms-continuation-nextrowkey')) {
        nexT = {
          "x-ms-continuation-nextpartitionkey" : r.headers.get('x-ms-continuation-nextpartitionkey'),
          "x-ms-continuation-nextrowkey" : r.headers.get('x-ms-continuation-nextrowkey')
        };
      }
      if(r.ok)
        return r.json();
      return [];
    })
    .then(r=> {
      if(r.value)
        this.zones = this.zones.concat(r.value);
      if(nexT)
        return this.getZones(locationId,nexT);
      return this.zones;
    })
  }

  getMapsFromMeridian(location) {
    var url =`https://edit.meridianapps.com/api/locations/${location}/maps`;
    return fetch(url, getOption())
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          return [];
        }
      })
      .then(data => {
        this.setState({maps: data});
        return data;
      })
      .catch(err => {
        console.log("error:", err);
        return [];
      });
  }
  getZonesFromMeridian(locationId, zoneUrl = `https://edit.meridianapps.com/api/locations/${locationId}/tag-zones`) {
    //let zoneUrl = `https://edit.meridianapps.com/api/locations/${locationId}/tag-zones`;
    return fetch(zoneUrl, getOption())
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        //NO ZONE
        return [];
      }
    })
    .then(data => {
      if(data) {
        this.zones.results = this.zones.results.concat(data.results);
        console.log("blee",this.zones, data);
        if(data.next) {       
          this.getZonesFromMeridian(locationId, data.next);
        }
        else {
          console.log("bleeF",this.zones);
          this.setState({zones: this.zones});
          return this.maps;
        }
      }
      // this.setState({zones: data});
      // return data;
    })
  }

  merge() {
    if(this.maps) {
      this.downloadData = [];
      this.rows.forEach((item, i)=> {
        //item.time = item.time.substring(0,19).replace("T", " ");
        item.localTime = moment(item.time).format('DD MMMM YYYY H:mm');
        item.lastHeard = item.last_heard ? moment(item.last_heard*1000).format('DD MMMM YYYY H:mm') : "";
        item.timeLH = item.time;
        if(i == 0) {
          let map = this.maps.find(m=> m.id == item.map_id);
          if(map) {
            item.MeridianBuildingName = map.group_name;
            item.FloorNumber = map.name;
          }
          let zone = this.zones.find(zone=> zone.id == item.zone_id);
          if(zone) {
            item.ZoneName = zone.name;
          }
        }
        else if(i > 0 && item.map_id == this.rows[i-1].map_id) {
          item.MeridianBuildingName = this.rows[i-1].MeridianBuildingName;
          item.FloorNumber = this.rows[i-1].FloorNumber;
          
          let zone = this.zones.find(zone=> zone.id == item.zone_id);
          if(zone) {
            item.ZoneName = zone.name;
          }
        }
        else {
          let map = this.maps.find(m=> m.id == item.map_id);
          if(map) {
            item.MeridianBuildingName = map.group_name;
            item.FloorNumber = map.name;
          }
          let zone = this.zones.find(zone=> zone.id == item.zone_id);
          if(zone) {
            item.ZoneName = zone.name;
          }
        }
        this.downloadData.push(
          {
            [`LAST HEARD (${abbs})`] : item.lastHeard ? item.lastHeard.toString().replace("","  ") : "",
            [`TIMESTAMP (${abbs})`] : item.localTime.toString().replace("","  "),  //Replace needed, so Excel won't convert it
            "CAMPUS" : item.MeridianBuildingName,
            "FLOOR" : item.FloorNumber,
            "ZONE" : item.ZoneName,
            "ID" : item.mac ? item.mac : this.props.mac
          }
        );
      });
      this.setState({refresh: true, isLoading: false});
    }
  }

  getMergedData(days = 0) {
    this.rows = [];
    this.getAssetHistory(null, days)
    .then(r=> {
        this.merge();
    });
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }
    this.setState({ order, orderBy });
  }
  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }
  
  getSorting(order, orderBy) {
    return order === "desc"
      ? (a, b) => desc(a, b, orderBy)
      : (a, b) => -desc(a, b, orderBy);
  }
  handleChangePage = (event, page) => {
    this.setState({ page });
  }

  handleChangeRowsPerPage = event => {
    if(event.target.value * this.state.page > this.rows.length && this.state.page != 0)
      this.setState({ rowsPerPage: event.target.value, page: 0 });
    else
      this.setState({ rowsPerPage: event.target.value });
  }

  selectDateToUTC(datePickerValue) {
    let d = new Date();
    if(datePickerValue.detail == "day") {
      //SET DAY
      d.setDate(d.getDate() - datePickerValue.value + 1);
    }
    else {
      //SET MONTH
      d.setMonth(d.getMonth() - datePickerValue.value);
    }
    d.setUTCHours(0,0,0,0);
    let utc = d.toISOString();
    return utc;
  }

  compareUTCDate(picker, asset) {
    let pickerDate = new Date(picker);
    let assetDate = new Date(asset);
    return assetDate >= pickerDate;
  }

  render() {
    let dayObj = this.daysList.find(d=> d.id == this.state.days);
    dayObj = dayObj == undefined ? null : dayObj;
    return (
      <React.Fragment>
        {this.state.showMap && (
          <Paper
            ref={this.childDiv}
            style={{ transition: 0.5 }} /*className={classes.root}*/
          >
            <div>
              <Dialog
                fullScreen
                open={
                  this.state.isFullScreen
                } /*onClose={handleClose} TransitionComponent={Transition}*/
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingInline: "24px",
                  }}
                >
                  <Typography
                    style={{ color: "#4b2e83" }}
                    variant="h6"
                    id="tableTitle"
                  >
                    Device ID: {this.state.selectedItem.DeviceId}
                  </Typography>
                  <Tooltip title="Close Fullscreen">
                    <IconButton
                      onClick={() => this.setState({ isFullScreen: false })}
                    >
                      <SvgIcon>
                        <path
                          fill="none"
                          stroke="#4b2e83"
                          stroke-width="2"
                          d="M2,14 L10,14 L10,22 M1,23 L10,14 M23,1 L14,10 M22,10 L14,10 L14,2"
                        ></path>
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                </div>
                <Page2Map
                  id={this.state.selectedItem.DeviceId}
                  macid={this.state.selectedItem.DeviceId}
                  locid={this.state.selectedItem.MeridianLocationId}
                  floorid={this.state.selectedItem.MeridianMapId}
                  element={"meridian-map2"}
                  image={this.props.image}
                  x={this.state.selectedItem.X}
                  y={this.state.selectedItem.Y}
                />
              </Dialog>
              <Toolbar /*scr numSelected={selected.length}*/>
                <div className="w-100">
                  <Typography
                    style={{ color: "#4b2e83" }}
                    variant="h6"
                    id="tableTitle"
                  >
                    Device ID: {this.state.selectedItem.DeviceId}
                  </Typography>
                </div>
                <div style={{ flex: "1 1 100%" }} />
                <div style={{ display: "flex" }}>
                  <Tooltip title="See Fullscreen">
                    <IconButton
                      onClick={() => this.setState({ isFullScreen: true })}
                    >
                      <SvgIcon>
                        <path
                          fill="none"
                          stroke="#4b2e83"
                          stroke-width="2"
                          d="M10,14 L2,22 M1,15 L1,23 L9,23 M22,2 L14,10 M15,1 L23,1 L23,9"
                        ></path>
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Hide Map">
                    <IconButton
                      aria-label="Hide Map"
                      //onClick={this.MapHidden.bind(this, "", "", true)}
                      onClick={() => this.setState({ showMap: false })}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </Toolbar>

              <Page2Map
                id={this.state.selectedItem.DeviceId}
                macid={this.state.selectedItem.DeviceId}
                locid={this.state.selectedItem.MeridianLocationId}
                floorid={this.state.selectedItem.MeridianMapId}
                element={"meridian-map1"}
                image={this.props.image}
                x={this.state.selectedItem.X}
                y={this.state.selectedItem.Y}
              />
            </div>
          </Paper>
        )}

        <h5
          style={{
            color: "#4b2e83",
            marginTop: "10px",
          }}
        >
          {this.props.name}
        </h5>
        <div
          style={{
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Autocomplete
            style={{ width: "200px" }}
            id="days"
            options={this.daysList}
            value={dayObj}
            //value={this.daysList.find(d=> d.id == this.state.days)}
            getOptionSelected={(option, value) => option.id == value.id}
            getOptionLabel={(option) => option.name}
            //onChange={this.handleChange("entity")}
            onChange={(evt, value) => {
              this.setState({isLoading: true});
              let v = 1;
              if (value != null) v = value.id;
              this.setState({ days: v, rowsPerPage: 50, page: 0 });

              let days = value ? value.days : 1; //ALL MAX 1000day
              this.getMergedData(days);
            }}
            renderOption={(option) => (
              <div style={{ padding: "8px 2px" }}>{option.name}</div>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Days"
                variant="outlined"
                margin="dense"
                //className={this.props.classes.textField}
              />
            )}
          />
          <CSVLink
            data={this.downloadData}
            filename={`${this.props.mac}-History.csv`}
            style={ this.state.isLoading? {"pointer-events": "none", textDecoration: "none" }: {"pointer-events": "auto", textDecoration: "none" }}
          >
            <Button
              disabled={this.state.isLoading}
              variant="outlined"
              //onClick={this.toggleHidden.bind(this)}
              style={{
                color: "#4b2e83",
                //opacity: "1",
                //outline: "none"
              }}
              //className="buttonnobg float-center"
            >
              Export
            </Button>
          </CSVLink>
        </div>
        {this.state.isLoading ?
            <div
                style={{
                      width: "100%",
                      marginTop: 100,
                      display: "flex",
                      justifyContent: "center",
                }}
              >
              <CircularProgress style={{ width: "80px", height: "80px" }}/>
            </div>
          :
          <React.Fragment>
            <TableContainer>
              <Table>
                <EnhancedTableHead
                  //classes={classes}
                  //numSelected={selected.length}
                  order={this.state.order}
                  orderBy={this.state.orderBy}
                  //onSelectAllClick={handleSelectAllClick}
                  onRequestSort={this.handleRequestSort}
                  //rowCount={rows.length}
                />
                <TableBody>
                  {this.stableSort(
                    this.rows,
                    this.getSorting(this.state.order, this.state.orderBy)
                  )
                    .slice(
                      this.state.page * this.state.rowsPerPage,
                      (this.state.page + 1) * this.state.rowsPerPage //+ rowsPerPage
                    )
                    //.filter(r=> this.compareUTCDate(this.selectDateToUTC(dayObj), r.time))
                    .map((row) => (
                      <TableRow>
                        <TableCell>
                          <Tooltip title={row.mac ?"Show Map for Device: " +  row.mac : "Show Map for Device: " + this.props.mac}>
                            <IconButton
                              style={{ padding: 0 }}
                              onClick={() => {
                                // id={this.state.selectedItem.DeviceId}
                                // macid={this.state.selectedItem.DeviceId}
                                // locid={this.state.selectedItem.MeridianLocationId}
                                // floorid={this.state.selectedItem.MeridianMapId}
                                // element={"meridian-map2"}

                                // image={this.props.image}
                                // x={this.state.selectedItem.X}
                                // y={this.state.selectedItem.Y}

                                let selectedItem = {
                                  DeviceId: row.mac ? row.mac : this.props.mac,
                                  MeridianLocationId:
                                    this.props.assetDetail.MeridianLocationId,
                                  MeridianMapId: row.map_id,
                                  X: row.x,
                                  Y: row.y,
                                };
                                this.setState(
                                  { showMap: true, selectedItem: selectedItem },
                                  () =>
                                    this.childDiv.current.scrollIntoView({
                                      behavior: "smooth",
                                      block: "start",
                                    })
                                );
                              }}
                            >
                              <MapIcon style={{ color: "#4b2e83" }} />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell>{row.lastHeard}</TableCell>
                        <TableCell>{row.localTime}</TableCell>
                        <TableCell>{row.MeridianBuildingName}</TableCell>
                        <TableCell>{row.FloorNumber}</TableCell>
                        <TableCell>{row.ZoneName}</TableCell>
                        <TableCell>{row.mac}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100, 125, 150]}
              component="div"
              count={
                this
                  .rows /*.filter(r=> this.compareUTCDate(this.selectDateToUTC(dayObj), r.time))*/
                  .length
              }
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              backIconButtonProps={{
                "aria-label": "Previous Page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page",
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              style={{paddingBottom: "20px"}}
            />
          </React.Fragment>
        }
      </React.Fragment>
    );
  }
}
export default History;