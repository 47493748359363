import * as React from 'react';
import Box from '@material-ui/core/Box';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AlertContext from './AlertContext';
 
export const WizzardContext = React.createContext();

const HorizontalLinearStepper=(props)=> {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [isStepDisabled, setStepDisable] = React.useState({disabled: true, issues: 0});
  const steps = props.steps;

  const isStepOptional = (idx) => {
    return steps[idx].isSkippable;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if(activeStep < steps.length - 1) {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
    else {
      console.log("finish -save");
      props.onSave();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ width: '100%' }} padding="24px">
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
        //   const stepProps: { completed?: boolean } = {};
        //   const labelProps: {
        //     optional?: React.ReactNode;
        //   } = {};
          let stepProps={};
          let labelProps={};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label.name} {...stepProps}>
              <StepLabel {...labelProps}>{label.name}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <WizzardContext.Provider value={{activeStep: activeStep, setStepDisable: setStepDisable}}>
      {false && activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button variant="outlined" onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <AlertContext.Consumer>
          {(data) => {
            return (
          <div style={{minHeight: "300px", paddingTop: 20, /*height: "calc(100vh - 300px)"*/ height: data.hasActiveAlert? "calc(100vh - 300px)" : "calc(100vh - 250px)", overflow: "auto"}}>
        {props.children}
        </div>
        )}}
        </AlertContext.Consumer>
        <hr/>
        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center"}}>
          <Typography sx={{ mt: 2, mb: 1 }}>
            Step {activeStep + 1} of {steps.length} {isStepDisabled.issues != 0 ? isStepDisabled.issues == 1 ? `(${isStepDisabled.issues} issue found)`:  `(${isStepDisabled.issues} issues found)` : ""}
          </Typography>
          <Box style={{ display: 'flex', justifyContent: 'end', gap: 10/*, flexDirection: 'row', pt: 2 */}}>
            {activeStep > 0 && <Button
              variant="outlined"
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>}
            <Box /*style={{ flex: '1 1 auto' }}*/ />
            {isStepOptional(activeStep) && (
              <Button variant="outlined" color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )}
            <Button disabled={isStepDisabled.disabled} variant="outlined" onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </Box>
          </Box>
        </React.Fragment>
      )}
      </WizzardContext.Provider>
    </Box>
  );
}

export default HorizontalLinearStepper;
