import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import ResetIcon from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { showMessage } from "../actions/index";
import { compose } from "recompose";
import { connect } from "react-redux";
import { apiRequest, byName } from "../utils/Utils";
import AlertDialog from "../components/AlertDialog";
import TableForMetadata from "../components/TableForMetadata.jsx";
import SubmitIcon from "@material-ui/icons/CheckCircleOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import GoBackIcon from "@material-ui/icons/ArrowBack";

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
    paddingTop: 10,
    paddingBottom: 10
  },
  input: {
    width: "85%",
    padding: "3px",
    borderRadius: "5px",
    outline: "none",
    border: "1px solid lightgrey"
  },
  textField: { width: "100%" },
  menu: {
    width: "100%"
  }
});

class MasterDataManagementManufacturerModel extends Component {
  newManufacture = true;
  newModel = true;
  newType = true;
  state = {
    manufacturer: {
      id: "",
      name: ""
    },
    model: {
      id: "",
      name: ""
    },
    type: {
      id: "",
      name: ""
    },
    default: {
      id: "",
      name: ""
    },
    open: false,
    rename_type: "",
    rename_value: "",
    manufacturerData: [],
    modelData: [],
    typeData: [],
    openAlert: false,
    openError: false,
    errorMessages: [],
    originalModel: "",
    errorCategories: { manufactures: "manufacturer", assetmodels: "model", assettypes: "type" },
    actionType: "",
    name: "",
    manufacturermodels: [],
    manufacturerModel: {ManufacturerId: "0", ManufacturerName: "", ModelId: "0", ModelName: ""},
    responseData: [],
    toolTipManufacturerData: [],
    toolTipModelData: [],
  };

  componentWillMount() {
    this.fetchData("manufactures");
    // this.fetchData("assetmodels");
    // this.fetchData("assettypes");

    this.fetchData("manufacturermodels");

    //this.saveOriginalModel();
  }

  errorText( functionName, itemCategory ){
    return `Unable to ${functionName} ${itemCategory} as this item already exists`;
  }

  saveOriginalModel(){
    const oModel = this.state.manufacturerModel.ModelName;
    if(this.state.originalModel === ""){
      console.log("saving model to:", oModel);
      this.setState({ originalModel: oModel });
    }
  }

  addErrorMessage( addMessage ){
    var errMsg = this.state.errorMessages;
    errMsg.push(addMessage);
    this.setState({ errorMessages: errMsg });
    this.props.showMessageBox(addMessage);
  }

  addShowErrorMessage( addMessage ){
    this.addErrorMessage( addMessage );
    //this.setState({ openError: true });
  }

  clearErrorMessages(){
    this.setState({ openError: false });
    this.setState({ errorMessages:[] });
  }

  delay( time ) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  redirectNoError(){
    if( !this.state.openError ){
      console.log("redirecting...");
      this.delay(300).then( () => {
        this.props.showMessageBox("SUCCESS");
        this.props.history.push("/master/data/manage");
      });
    }
  }

  //it is called when input field changed
  //if the manufacturerFilterId is a valid manufacturer it will filter the data
  updateTooltipModel(manufacturerFilterId = 0){
    let modArr = [];

    if(manufacturerFilterId != 0){
      this.state.responseData.forEach( e => {
        if(e.ManufacturerId === manufacturerFilterId){

          const foundIndex = modArr.findIndex( o => o.id === e.ModelId);
          if(foundIndex === -1){
            modArr.push({ id: e.ModelId, name: e.ModelName });
          }

        }
      });
    }

    this.setState({toolTipModelData: modArr.sort(byName)});
  }

  fetchData(dataType, id = "") {
    const That = this;
    let url =
      dataType === "assetmodels"
        ? `/masters/${dataType}/${id}`
        : `/masters/${dataType}`;

    apiRequest(url, "get")
      .then(response => {
        const data = response.data.map(c => {
          return {
            id: c.ID,
            name: c.Value
          };
        });

        //update raw response data --> needed for tooltips
        this.setState({ responseData: response.data });

        switch (dataType) {
          case "manufactures":
            //update tooltips with all manufacturers name
            //this.setState({ toolTipManufacturerData: data.sort(byName) });

            if(this.props.match.params.id == 0) {
              //this.setState({typeData: {id: "0", name: ""}});
              this.setState({
                manufacturerData: data.sort(byName)
              });
            } else {
              this.setState({manufacturerData: data.find(type=> type.id == this.props.match.params.id)});
            }
            // this.setState(
            //   {
            //     manufacturerData: data.sort(byName)
            //   },
            //   () => {
            //     let id = this.state.manufacturer.id;
            //     if (id !== "") this.fetchData("assetmodels", id);
            //   }
            // );

            //set manufacturer tooltips
            this.setState({toolTipManufacturerData: data.sort(byName)});

            break;
          case "assetmodels":
            this.setState({
              modelData: data.sort(byName)
            });
            break;
          case "assettypes":
            this.setState({
              typeData: data.sort(byName)
            });
            break;
          case "manufacturermodels":

            if(this.props.match.params.id != 0){

              this.setState({manufacturerModel: response.data.find(e=> e.ManufacturerId == this.props.match.params.id && e.ModelId == this.props.match.params.modelId)}, ()=> {console.log("manufacturerModel",this.state.manufacturerModel);  this.saveOriginalModel();})
              
              //set model tooltips for the manufacturer
              this.updateTooltipModel( this.props.match.params.id );

            }
        }
      })
      .catch(error => console.log(error));
  }

  add = () => {
    this.setState({
      openAlert: false
    });
    const that = this;
    const name = this.state.name;

    if (name === "manufacturer") {
      const Manufacturer = this.state.manufacturer.name;

      apiRequest(`/masters/addmanufactures?name=${Manufacturer}`, "post")
        .then(x => {
          if (x.status > 0) 
            that.fetchData("manufactures");

          if (x.status > 0 && x.message == null){
            console.log(`"add" ${name}: resolve`);
            return Promise.resolve();
          }
          else{
            console.log(`"add" ${name}: reject`);
            return Promise.reject( x.message );
          }
        })
        .then( x => {
          console.log(`"add" ${name}: redirect`);
          this.redirectNoError();
        })
        .catch(err => {
          console.log(`ERROR "add" ${name}: "${err}"`);
          this.addShowErrorMessage( err );
        });

    } else if (name === "model") {
      const DeviceModel = this.state.model.name;

      apiRequest(
        `/masters/addassetmodels?manfactureId=${
          that.state.manufacturer.id
        }&model=${DeviceModel}`,
        "post"
      )
        .then(x => {
          if (x.status > 0)
            that.fetchData("assetmodels", that.state.manufacturer.id);

          if (x.status > 0 && x.message == null){
            console.log(`"add" ${name}: resolve`);
            return Promise.resolve();
          }
          else{
            console.log(`"add" ${name}: reject`);
            return Promise.reject( x.message );
          }
        })
        .then(x=> {
          console.log(`"add" ${name}: redirect`);
          this.redirectNoError();
        })
        .catch(err => {
          console.log(`ERROR "add" ${name}: "${err}"`);
          this.addShowErrorMessage( err );
        });

    } else if (name === "type") {
      const DeviceType = this.state.type.name;

      apiRequest(`/masters/addassettypes?name=${DeviceType}`, "post")
        .then(x => {
          if (x.status > 0) 
            that.fetchData("assettypes");

          if (x.status > 0 && x.message == null){
            console.log(`"add" ${name}: resolve`);
            return Promise.resolve();
          }
          else{
            console.log(`"add" ${name}: reject`);
            return Promise.reject( x.message );
          }
        })
        .then(x => {
          console.log(`"add" ${name}: redirect`);
          this.redirectNoError();
        })
        .catch(err => {
          console.log(`ERROR "add" ${name}: "${err}"`);
          this.addShowErrorMessage( err );
        });
    }
    setTimeout(() => {
      this.setState({
        actionType: ""
      });
    }, 1000);
  };

  rename = () => {
    this.setState({
      openAlert: false
    });
    const that = this;
    const { rename_type, rename_value } = this.state;
    if (rename_type === "manufacturer") {
      const old_data = this.state.manufacturer;
      this.handleRenameRequest("manufactures", old_data);
      this.newManufacture = true;
    } else if (rename_type === "model") {
      const old_data = this.state.model;
      this.handleRenameRequest("assetmodels", old_data);
      this.newModel = true;
    } else if (rename_type === "type") {
      const old_data = this.state.type;
      this.handleRenameRequest("assettypes", old_data);
      this.newType = true;
    }
    this.setState({
      actionType: "",
      rename_value: "",
      [rename_type]: this.state.default
    });
    this.handleClose();
    let a = document.querySelectorAll("input");
    a.forEach(element => {
      element.value = "";
    });
  };

  handleRenameRequest(name, data) {
    const { rename_value } = this.state;
    const that = this;

    apiRequest(`/masters/${name}/${data.id}?name=${rename_value}`, "put")
      .then(x => {
        //if (x.status > 0) that.fetchData(name);
        if (x.status > 0 && x.message == null){
          console.log(`"rename" ${name}: resolve`);
          return Promise.resolve();
        }
        else{
          console.log(`"rename" ${name}: reject`);
          return Promise.reject( x.message );
        }
      })
      .then(x => { 
        console.log(`"rename" ${name}: redirect`);
        this.redirectNoError();
      })
      .catch(err => {
        console.log(`ERROR "rename" ${name}: ${err}`);
        this.addShowErrorMessage( err );
      });
  }

  delete = () => {
    this.setState(
      {
        openAlert: false
      },
      () => {
        this.setState({
          actionType: ""
        });
      }
    );
    const { name } = this.state;
    const that = this;
    if (name === "manufacturer") {
      const new_data = this.state.manufacturer;
      this.handleDeleteRequest("manufactures", new_data);
      this.newManufacture = true;
    } else if (name === "model") {
      const new_data = this.state.model;
      this.handleDeleteRequest("assetmodels", new_data);
      this.newModel = true;
    } else if (name === "type") {
      const new_data = this.state.type;
      this.handleDeleteRequest("assettypes", new_data);
      this.newType = true;
    }
    let a = document.querySelectorAll("input");
    a.forEach(element => {
      element.value = "";
    });
  };

  handleDeleteRequest(name, data) {
    const that = this;

    apiRequest(`/masters/${name}/${data.id}`, "delete")
      .then(x => {
        if (x.status > 0) that.fetchData(name);
        if (x.status > 0 && x.message == null){
          console.log(`"delete" ${name}: resolve`);
          return Promise.resolve();
        }
        else {
          console.log(`"delete" ${name}: reject`);
          return Promise.reject( x.message );
        }
      })
      .then(x => {
        console.log(`"delete" ${name}: redirect`);
        this.redirectNoError();
      })
      .catch(err => {
        console.log(`ERROR "delete" ${name}: ${err}`);
        this.addShowErrorMessage( err );
      });
  }

  handleDelete(name, id) {
    console.log("handle delete...", name, id);
    const that = this;

    var manuDeletePromise;
    var manuAddPromise;

    //if deleting the model from last entry
    //then keep the manufacturer in the database
    //to do this manufacturer model is removed, then manufacturer removed then added back

    ///TODO: ERROR 6, 7, 8 not modified to be synchonous with the SUCCESS!

    if(name === 'assetmodels'){
      let isModelLast = false;
      let lastModelManufacturerId;
      let lastModelManufacturerName;
      //check if the assetmodel is the last with manufacturer
      apiRequest("/masters/manufacturermodels", "get").then( r => {
        let models = r.data.filter( item => item.ModelId === id);
        //console.log("checking models", models);
        lastModelManufacturerId = models[0].ManufacturerId;

        let manallmodels = r.data.filter( item => item.ManufacturerId === lastModelManufacturerId);
        //console.log("checking manufacturer models", manallmodels);

        if(manallmodels.length === 1){
          isModelLast = true;
          lastModelManufacturerName = models[0].ManufacturerName;
        }
      }).then(
        apiRequest(`/masters/${name}/${id}`, "delete")
        .then(x => {
          
          if (x.status > 0 && x.message == null){

            if(isModelLast){
              //if there is only one model with this manufacturer, delete the manucaturer and add it back
              manuDeletePromise = apiRequest(`/masters/manufactures/${lastModelManufacturerId}`, "delete")
              .then( x =>{
                if(x.status > 0 && x.message == null){
                  //deletion success
                  //add back without any model
                  manuAddPromise = apiRequest(`/masters/addmanufactures?name=${lastModelManufacturerName}`, "post")
                  .then( x => {
                    if(x.status > 0 && x.message == null){
                      that.fetchData("manufacturermodels");
                      console.log("Manufacturer Re-adding DONE!");
                    }
                    else this.addShowErrorMessage( x.message );
                  })
                  .catch(err => {
                    console.log("ERROR... 6");
                    that.props.showMessageBox("ERROR when Re-adding Manufacturer");
                  });
                  //end of readding manufacturer plainly
                }
                else this.addShowErrorMessage( x.message );

                //console.log("Waiting for manufacturer add...", manuAddPromise);
                //Promise.all([manuAddPromise]);
                //console.log("Manufacturer add done...", manuAddPromise);

              })
              .catch(err => {
                console.log("ERROR... 7");
                that.props.showMessageBox("ERROR When deleting Manufacturer");
              });
              //deletion done here

              //wait all promises working in here
              //console.log("Waiting manufacturer deletion and re-adding...")
              Promise.all([manuDeletePromise, manuAddPromise]).then( x=>{ 
                //console.log("Manufacturer deletion and re-adding done", manuDeletePromise, manuAddPromise);
                console.log("Async Redirect...");
                //this.props.history.push("/master/data/manage");
                this.redirectNoError();
              });
            }
            //if not last model in the manufacturer do nothing with manufacturer

            //SUCCESS to the deletion of the model
            this.redirectNoError();
          }
          else {
            this.addShowErrorMessage( x.message );
          }
          
          //redirect immediately after deletion if model was not last
          if(!isModelLast){
            console.log("Immediate Redirect...");
            //this.props.history.push("/master/data/manage");
            this.redirectNoError();
          }
        })
        //Error handling at deletion
        .catch(err => {
          console.log("ERROR... 8");
          that.props.showMessageBox("ERROR when deleting Manufacturer");
        })
      );

    }
    else{

      //If not the asset model is deleted no need further checks

      apiRequest(`/masters/${name}/${id}`, "delete")
      .then(x => {
        //if (x.status > 0) that.fetchData(name);
        if (x.status > 0 && x.message == null){
          console.log(`"delete manu" ${name}: resolve`);
          return Promise.resolve();
        }
        else{
          console.log(`"delete manu" ${name}: redirect`);
          return Promise.reject( x.message );  
        }
        //this.props.history.push("/master/data/manage");
      })
      .then(x =>{
        console.log(`"delete manu" ${name}: redirect`);
        this.redirectNoError();
      })
      .catch(err => {
        console.log(`ERROR "delete manu" ${name}: ${err}`);
        this.addShowErrorMessage( err );
      });

    }

  }

  deleteItem = () => {
    console.log("deleting item...");
    this.setState(
      {
        openAlert: false
      },
      () => {
        this.setState({
          actionType: ""
        });
      }
    );
    const { name, manufacturerModel } = this.state;
    if (name === "manufacturer") {
      this.handleDelete("manufactures", manufacturerModel.ManufacturerId);
    } else if (name === "model") {
      //delete model
      this.handleDelete("assetmodels", manufacturerModel.ModelId);
    }
    let a = document.querySelectorAll("input");
    a.forEach(element => {
      element.value = "";
    });
  };

  inputChange = name => e => {
    const { manufacturerModel } = this.state;
    let object = {
      ManufacturerId: manufacturerModel.ManufacturerId,
      ManufacturerName: manufacturerModel.ManufacturerName,
      ModelId: manufacturerModel.ModelId,
      ModelName: manufacturerModel.ModelName
    }
    if(name == "model") {
      object.ModelName = e.target.value;
      //check if the changed value is the same

    } else {
      //Manu
      object.ManufacturerName = e.target.value;

      //Create new model with exisitng manufacturer
      if(this.props.match.params.id == 0 && this.props.match.params.modelId == 0) {
        let manufacturer = this.state.manufacturerData.find(m=> m.name == e.target.value)
        if(manufacturer) {
          object.ManufacturerId = manufacturer.id;
        } else {
          object.ManufacturerId = "0";
        }
      }
    }

    this.updateTooltipModel(object.ManufacturerId);

    //console.log("select",object);
    //console.log("manData", this.state.manufacturerData);
    //console.log("toolTipManufacturer", this.state.toolTipManufacturerData);
    //console.log("tooltipModelData", this.state.toolTipModelData)
    //console.log("responseData", this.state.responseData)
    //console.log("paramid", this.props.match.params.id);

    this.setState({manufacturerModel: object});
  };

  handleUpdateName(name, id, newName) {
    const that = this;

    console.log(`updateName: '${name}' --> '${newName}' at id: '${id}'`);

    return apiRequest(`/masters/${name}/${id}?name=${newName}`, "put")
      .then(x => {
        //if (x.status > 0) that.fetchData(name);
        if (x.status > 0 && x.message == null){
          console.log(`"update" ${name}: resolve`);

          if( name === "manufactures"){
            that.props.showMessageBox(`Manufacturer SUCCESS`);
          }

        }
        else {
          console.log(`"update" ${name}: reject`);
          return Promise.reject( x.message );
        }
        return x;
      })
      .catch(err => {
        console.log(`ERROR "update" ${name}: ${err}`);
        this.addShowErrorMessage( this.errorText( "rename", this.state.errorCategories[name] ));
      });
  }

  handleSave() {
    const { manufacturerModel } = this.state;

    console.log("handling save...");

    //Edit - Rename
    if(this.props.match.params.id != 0) {
      console.log(`"save" edit`);

      this.handleUpdateName("manufactures", manufacturerModel.ManufacturerId, manufacturerModel.ManufacturerName)
      .then(x=> {

        if(this.props.match.params.modelId != 0 && manufacturerModel.ModelName !== this.state.originalModel)
          this.handleUpdateName("assetmodels", manufacturerModel.ModelId, manufacturerModel.ModelName)
          .then(r=> {
            console.log( `original model:"${this.state.originalModel}" non-equal to:"${manufacturerModel.ModelName}"`);
            //this.props.history.push("/master/data/manage")
            this.redirectNoError();
            }
          );
        else
          //console.log("Other redirect here...")
          //this.props.history.push("/master/data/manage");
          this.redirectNoError();
      });

    } else {
      //Create new Manufacturer-Model pair
      if(manufacturerModel.ManufacturerId == 0 &&
        manufacturerModel.ManufacturerName != "" &&
        manufacturerModel.ModelId == 0 &&
        manufacturerModel.ModelName != "") {
          console.log(`"save" create new man-mod pair`);

          const that = this;
          apiRequest(`/masters/addmanufactures?name=${manufacturerModel.ManufacturerName}`, "post")
          .then(response=> {
            //console.log("resp",response)
            if(response.data && response.status > 0) {
              apiRequest(
                `/masters/addassetmodels?manfactureId=${
                  response.data.toString()
                }&model=${manufacturerModel.ModelName}`,
                "post"
              )
              .then(r => {
                if (r.status > 0 && r.message == null){
                  console.log(`"save" create new mod: resolve`)
                  return Promise.resolve();
                }
                else{
                  console.log(`"save" create new mod: reject`)
                  return Promise.reject( r.message );
                }
                  //this.props.history.push("/master/data/manage");
              })
              .then(x => {
                  this.redirectNoError();
              })
              .catch(err => {
                console.log(`ERROR "save" mod creation failed at new pair: ${err}`);
                this.addShowErrorMessage( /*this.errorText("add", "model")*/ err );
              });
            } else {
              console.log( `ERROR "save" man creation failed at new pair: ${response.message}` )
              this.addShowErrorMessage( /*this.errorText("add", "manufacturer")*/  response.message);
            }
          })
          .catch(err => {
            console.log("API ERROR! #12");
            this.addErrorMessage(`ERROR when Saving Manufacturer`);
            this.addShowErrorMessage(`${err}`);
          });
          
        }
      //Create new Manufacturer Only
      else if(manufacturerModel.ManufacturerId == 0 && manufacturerModel.ManufacturerName != "") {
        console.log(`"save" create man only`);

        const that = this;
        apiRequest(`/masters/addmanufactures?name=${manufacturerModel.ManufacturerName}`, "post")
        .then(x => {
          if (x.status > 0 && x.message == null){
            console.log(`"save" create man only: resolve`);
            return Promise.resolve();
          }
          else{
            console.log(`"save" create man only: reject`);
            return Promise.reject( x.message );
          }
          //this.props.history.push("/master/data/manage");
        })
        .then( x => {
          this.redirectNoError();
        })
        .catch(err => {
          console.log(`ERROR "save" create man only: ${err}`);
          this.addShowErrorMessage( /*this.errorText("add", "manufacturer")*/err );
        });
      }
      //Create new Model with existing Manufacturer
      else if(manufacturerModel.ManufacturerId != 0 && manufacturerModel.ModelName != "") {
        console.log(`"save" new mod with existing man`);

        const that = this;
        apiRequest(
          `/masters/addassetmodels?manfactureId=${
            manufacturerModel.ManufacturerId
          }&model=${manufacturerModel.ModelName}`,
          "post"
        )
        .then(x => {
          if (x.status > 0 && x.message == null){
            console.log(`"save" create new mod to existing man: resolve`);
            return Promise.resolve();
          }
          else{
            console.log(`"save" create new mod to existing man: reject`);
            return Promise.reject( x.message );
          }
          }).then( x=>{
            this.redirectNoError();
          })
          .catch(err => {
            console.log(`ERROR "save" create new mod to existing man: ${err}`);
            this.addShowErrorMessage( /*this.errorText("add", "model")*/err );
          });
      }
      //No input in the manufacturer field
      else if(manufacturerModel.ManufacturerName == ""){
        console.log("ERROR empty manu");
        this.addShowErrorMessage("Unable to add empty manufacturer");
      }
      //The manufacturer already exist
      else if(manufacturerModel.ManufacturerId != 0 && manufacturerModel.ManufacturerName != ""){
        console.log("ERROR manu already exists");
        this.addShowErrorMessage( `ERROR: ${this.errorText("add", "manufacturer")}` );
      }
    }
  }

  handleClickOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleReset = (index, name) => {
    if (name === "manufacturer") this.newManufacture = true;
    else if (name === "model") this.newModel = true;
    else if (name === "type") this.newType = true;

    this.setState(
      {
        [name]: { ...this.state.default }
      },
      () => {
        if (name === "manufacturer") {
          this.setState({
            modelData: []
          });
          //this.handleReset(1,"model");
          let a = document.querySelectorAll("input");
          a[index + 1].value = "";
        }
      }
    );

    let a = document.querySelectorAll("input");
    a[index].value = "";
  };

  render() {
    const { classes } = this.props;
    const { manufacturerData, modelData, typeData, manufacturermodels, manufacturerModel, toolTipManufacturerData, toolTipModelData } = this.state;
    return (
      <div
        id="content"
        style={{
          //paddingTop: "60px",
          transition: "0.25s",
          paddingLeft: "0px",
        }}
      >
        <AlertDialog
          open={this.state.openAlert}
          onClickYes={
            this.state.actionType == "ADD"
              ? this.add
              : this.state.actionType == "RENAME"
              ? this.rename
              : this.deleteItem
          }
          action={
            this.state.actionType == "ADD"
              ? "Save"
              : this.state.actionType == "RENAME"
              ? "Rename"
              : "Delete"
          }
          subText={
            this.state.actionType == "ADD"
            ? ""
            : this.state.actionType == "RENAME"
              ? `This action will affect all the existing devices having this ${this.state.rename_type} name`
              : this.state.name != null && this.state.name === "manufacturer"
                ? "This action will delete all the existing device models linked to this manufacturer and affect existing devices"
                : `This action will affect all the existing devices having this ${this.state.name} name`
          }
          close={() => {
            this.setState({ openAlert: false });
          }}
        />

        {/** ERROR dialog for displaying error messages */}
        <AlertDialog
          open ={ this.state.openError }
          isError ={ true }
          subText ={ " " }
          close ={  ()=> { 
            this.setState({ openError: false });
            this.setState({ errorMessages:[] });
          }}
        >
          { this.state.errorMessages.length > 0 &&
            this.state.errorMessages.map( (msg) => {
              return <div>{msg}</div>
            })
          }
        </AlertDialog>
        
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={this.state.open}
          onClose={() => {
            this.handleClose();
          }}
        >
          <DialogTitle>Rename</DialogTitle>
          <DialogContent>
            <TextField
              id="standard-uncontrolled"
              label="Rename"
              className={classes.textField}
              margin="dense"
              onChange={(e) => {
                this.setState({ rename_value: e.target.value });
              }}
              value={this.state.rename_value}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.handleClose();
              }}
              color="primary"
              style={{
                color: "#4b2e83",
                margin: 10,
                height: 45,
              }}
              className="button1"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                this.setState({
                  actionType: "RENAME",
                  openAlert: true,
                });
              }}
              color="primary"
              style={{
                color: "#4b2e83",
                margin: 10,
                height: 45,
              }}
              className="button1"
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        <Grid
              container
              spacing={2}
              style={{margin: "0px 27px", width: "auto"}
              }
            >
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <div className="float-left">
                  <h5
                    style={{
                      color: "#4b2e83",
                      width: "100%",
                      // paddingLeft: 2,
                      // paddingTop: 8
                    }}
                  >
                    {" "}
                    <Tooltip title="Go Back">
                      <IconButton
                        style={{
                          color: "#4b2e83",
                          opacity: "1",
                          outline: "none"
                        }}
                        aria-label="Go Back"
                        onClick={() => this.props.history.push("/master/data/manage")}
                      >
                        <GoBackIcon />
                      </IconButton>
                    </Tooltip>
                    Go back to previous screen
                  </h5>
                </div>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} style={{display: "flex", justifyContent: "end"}}>
              <Tooltip title={this.props.match.params.id == 0 ? "Create" : "Save"}>
            <IconButton
              // style={{
              //   color: "#4b2e83",
              //   opacity: "1",
              //   outline: "none"
              // }}
              style={{alignSelf: "center"}}
              color="primary"
              aria-label="Create"
              onClick={() => this.handleSave()}
            >
              <SubmitIcon />
            </IconButton>
          </Tooltip>
                </Grid>
            </Grid>

        <Paper
          style={{
            padding: "18px 30px",
            margin: "15px 30px",
          }}
        >
          <div style={{display: "flex" }}>
            <div style={{width: "100%" }}>

          <Grid
            container
            spacing={2}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid item xs={12} md={2} lg={2}>
              <h5>Manufacturer</h5>
            </Grid>
            <Grid item xs={12} md={5} lg={5}>
              <input
                /* Manufacturer input field - HERE... */
                list="text_editors1"
                className={classes.input}
                onChange={this.inputChange("manufacturer")}
                value={manufacturerModel.ManufacturerName}
                disabled={this.props.match.params.id != 0 || this.props.match.params.modelId != 0}
              />
              {/*this.props.match.params.id == 0 && this.props.match.params.modelId == 0 && */
              this.state.toolTipManufacturerData != [] &&
              <datalist id="text_editors1">
                <select>
                  {toolTipManufacturerData != 0 && toolTipManufacturerData.map((user) => (
                    <option key={user.id} value={user.name}>
                      {user.name}
                    </option>
                  ))}
                </select>
              </datalist>}
              {/* <Tooltip title="Reset">
                <IconButton
                  style={{ color: "#4b2e83", opacity: "1", outline: "none" }}
                  aria-label="Reset"
                  onClick={() => {
                    this.handleReset(0, "manufacturer");
                  }}
                >
                  <ResetIcon />
                </IconButton>
              </Tooltip> */}
            </Grid>
            <Grid item xs={12} md={1} lg={1}>
              {/* <Button
                variant="contained"
                color="primary"
                style={{
                  margin: "0px 7px",
                  backgroundColor: "white",
                  color: "#4b2e83",
                  outline: "none",
                }}
                className="button1"
                disabled={!this.newManufacture}
                onClick={() => {
                  if (this.state.manufacturer.name == "") {
                    this.props.showMessageBox("Field is empty");
                    return;
                  }
                  this.setState({
                    actionType: "ADD",
                    name: "manufacturer",
                    openAlert: true,
                  });
                }}
              >
                Add
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{
                  margin: "0px 7px",
                  backgroundColor: "white",
                  color: "#4b2e83",
                  outline: "none",
                }}
                className="button1"
                disabled={
                  this.newManufacture || this.state.manufacturer.name === ""
                }
                onClick={() => {
                  this.setState({
                    rename_type: "manufacturer",
                  });
                  this.handleClickOpen();
                }}
              >
                Rename
              </Button> */}
              {!(this.props.match.params.modelId == 0 && this.props.match.params.id == 0) &&
              <Tooltip title="Delete">
                <IconButton
                  aria-label="Delete"
                  color="primary"
                  onClick={() => {
                    this.setState({
                      actionType: "DELETE",
                      name: "manufacturer",
                      openAlert: true,
                    });
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Tooltip>}
            </Grid>
          </Grid>

          { !(this.props.match.params.modelId == 0 && this.props.match.params.id != 0) && (
            <Grid
              container
              spacing={2}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 10,
                marginTop: 10,
              }}
            >
              <Grid item xs={12} md={2} lg={2}>
                <h5>Model</h5>
              </Grid>
              <Grid item xs={12} md={5} lg={5}>
                <input
                  /* Model input field - HERE... */
                  disabled={!manufacturerModel.ManufacturerName && (this.props.match.params.modelId == 0 && this.props.match.params.id == 0)
                    || this.props.match.params.id != 0 || this.props.match.params.modelId != 0}
                  list="text_editors2"
                  className={classes.input}
                  onChange={this.inputChange("model")}
                  value={manufacturerModel.ModelName}
                />
                {/*this.props.match.params.id != 0 && this.props.match.params.modelId == 0 && */
                this.state.toolTipModelData != [] &&
                <datalist id="text_editors2">
                <select multiple>
                  {toolTipModelData != 0 && toolTipModelData.map((user) => (
                    <option key={user.id} value={user.name}>
                      {user.name}
                    </option>
                  ))}
                </select>
              </datalist>}
                {/* <Tooltip title="Reset">
                  <IconButton
                    style={{ color: "#4b2e83", opacity: "1", outline: "none" }}
                    aria-label="Reset"
                    onClick={() => {
                      this.handleReset(1, "model");
                    }}
                  >
                    <ResetIcon />
                  </IconButton>
                </Tooltip> */}
              </Grid>
              <Grid item xs={12} md={1} lg={1}>
                {/* <Button
                  variant="contained"
                  color="primary"
                  style={{
                    margin: "0px 7px",
                    backgroundColor: "white",
                    color: "#4b2e83",
                    outline: "none",
                  }}
                  onClick={() => {
                    if (this.state.manufacturer.name == "") {
                      this.props.showMessageBox(
                        "Please first select a Manufacturer to add Model"
                      );
                      return;
                    }
                    if (this.state.model.name == "") {
                      this.props.showMessageBox("Field is empty");
                      return;
                    }
                    this.setState({
                      actionType: "ADD",
                      name: "model",
                      openAlert: true,
                    });
                  }}
                  className="button1"
                  disabled={!this.newModel}
                >
                  Add
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    margin: "0px 7px",
                    backgroundColor: "white",
                    color: "#4b2e83",
                    outline: "none",
                  }}
                  onClick={() => {
                    this.setState({
                      rename_type: "model",
                    });
                    this.handleClickOpen();
                  }}
                  className="button1"
                  disabled={this.newModel || this.state.model.name === ""}
                >
                  Rename
                </Button> */}
                {!(this.props.match.params.modelId == 0 && this.props.match.params.id == 0) &&
                <Tooltip title="Delete">
                <IconButton
                  aria-label="Delete"
                  color="primary"
                  onClick={() => {
                    this.setState({
                      actionType: "DELETE",
                      name: "model",
                      openAlert: true,
                    });
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Tooltip>}
              </Grid>
            </Grid>
          )}
          </div>
          {/* <Tooltip title={this.props.match.params.id == 0 ? "Create" : "Save"}>
            <IconButton
              // style={{
              //   color: "#4b2e83",
              //   opacity: "1",
              //   outline: "none"
              // }}
              style={{alignSelf: "center"}}
              color="primary"
              aria-label="Create"
              onClick={() => this.handleSave()}
            >
              <SubmitIcon />
            </IconButton>
          </Tooltip> */}
          </div>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    showMessageBox: message => dispatch(showMessage(message))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(MasterDataManagementManufacturerModel);
