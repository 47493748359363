import $ from "jquery";
import Cookies from "js-cookie";

let entityId = -1;
let locationId = -1;
export let access = [];
export let isPortalAdmin = "";

export const API_BASE_URL = window.BASE_URL;
//export const admintoken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0IjoxNTg0NjIyNTQwLCJ2YWx1ZSI6IjhmM2QxNjMyZjk2YjU0ZGFhNmViZTIxMDc0OTExM2FhY2Y4OWM1MmUifQ.2Yh6hloUnqF4SOodgza6ymCGFDheFpYYAqBGRe-1yXI"
export const admintoken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0IjoxNTg1MTUxMDkzLCJ2YWx1ZSI6IjU1NTQ5OTdjZDc2ODgwYTI4NzE1YjJiZDQ2ZWFlOTZiYzZlMDgwODcifQ.6zmgDTp6-zONGze3jCiXHW9C7jYaBDiCNB1mf_QP_XM";
//export const admintoken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0IjoxNjkyODc5MjM5LCJ2YWx1ZSI6IjJmNzhhYmI4ZGViNzFiNzJlOGM5YWFjNWM1YzFmNzA0M2Y4MTQ4ZDYifQ.45ks0WJO4tIPNG1cgXPQFGTrW3QStxCzLcV5I1gj9l4" //pressmen
export const meridianLocationId = "5103333125914624";
//export const meridianLocationId = "6127263713853440"; //pressmen
//export const token = "6c09d265b1413fc768f4e08447ea95c8923a9c78"
export const token = admintoken;
export function getOption(method = 'get') {
  let bearer = `Token ${admintoken}`;
  let headers = new Headers();
  headers.append('Authorization', bearer);
  headers.append('Content-Type', 'application/json');
  var option = {
    method: method,
    headers: headers
  };
return option;
}    
export function byName(a,b) {
  const alc = a.name? a.name.toLowerCase(): '';
  const blc = b.name? b.name.toLowerCase(): '';
  if(alc < blc)
  {
    return -1;
  }
  else if(alc > blc)
  {
    return 1;
  }
  else {
    return 0;
  }
}
export function byPropertyCalled(property="name") {
  return function(a,b) {
    const alc = a[property]? a[property].toLowerCase(): '';
    const blc = b[property]? b[property].toLowerCase(): '';
    if(alc < blc)
    {
      return -1;
    }
    else if(alc > blc)
    {
      return 1;
    }
    else {
      return 0;
    }
  }
}

export function formatDate(date) {
  return date.getFullYear() + "-" + ("00" + (date.getMonth() + 1)).slice(-2)
  + "-" + ("00" + date.getDate()).slice(-2)
  + " "
  + ("00" + date.getHours()).slice(-2) + ":"
  + ("00" + date.getMinutes()).slice(-2)
  + ":" + ("00" + date.getSeconds()).slice(-2);
}

export function storeUser(userName) {
  localStorage.setItem("user", userName);
}

export function getUser() {
  return localStorage.getItem("user");
}

export function storeEntityId(data) {
  entityId = parseInt(data);
}

export function getEntityId() {
  return entityId;
}

export function getlocationId() {
  return locationId;
}

export function storelocationId(data) {
  locationId = parseInt(data);
}

export function setAccess(accessLevels) {
  access = accessLevels;
}

export function getAccess() {
  return access;
}

export function getMaxEntityAccess() {
  let maxAccess = 100;
  let data = [];

  data = access.filter(item => item.resourceType == "global");
  if (data.length > 0) {
    isPortalAdmin = true;
    return data[0].access_level;
  }
  isPortalAdmin = false;
  data = access.filter(item => item.resourceType == "entity");
  if (data.length === 0) maxAccess = 100;
  else if (data.length === 1) maxAccess = data[0].access_level;
  else
    maxAccess = data
      .map(el => el.access_level)
      .reduce((a, b) => Math.max(a, b));
  return maxAccess;
}

export function getMaxLocationAccess() {
  let maxAccess = 100;
  const data = access.filter(item => item.resourceType == "location");
  if (data.length === 0) maxAccess = 100;
  else if (data.length === 1) maxAccess = data[0].access_level;
  else
    maxAccess = data
      .map(el => el.access_level)
      .reduce((a, b) => Math.max(a, b));
  return maxAccess;
}

export function getMaxDepartmentAccess() {
  let maxAccess = 100;
  const data = access.filter(
    item =>
      item.resourceType == "department" || item.resourceType == "collection"
  );
  if (data.length === 0) maxAccess = 100;
  else if (data.length === 1) maxAccess = data[0].access_level;
  else
    maxAccess = data
      .map(el => el.access_level)
      .reduce((a, b) => Math.max(a, b));
  return maxAccess;
}

export function fetchRequest(url, method, next = null) {
  const URL = API_BASE_URL + url;

  const tokenJson = Cookies.getJSON(`owAuth${window.location.port}`) || "";
  if (tokenJson == "") {
    localStorage.setItem("lastUrl", window.location.pathname);
    return new Promise((resolve, reject) => {
    $.ajax({
      method: "post",
      url: `${API_BASE_URL}/autologout?userId=${getUser()}`,
      cache: false,
      dataType: "json",
      success: res => {
        resolve(res);
        window.location = "/";
      },
      error: err => {
        reject(err);
        window.location = "/";
      }
    });
    return;
  });
  }
  const token = tokenJson.token;

  let bearer = `Bearer ${token}`;
  let headers = new Headers();
  headers.append('Authorization', bearer);
  headers.append('Content-Type', 'application/json');
  headers.append('username',localStorage.getItem("user"));
  if(next) {
    Object.entries(next).forEach(itm=> headers.append(itm[0], itm[1]));
  }
  var option = {
    method: method,
    headers: headers
  };
  return fetch(URL, option);
}

export function apiRequest(url, method, data = "") {
  const URL = API_BASE_URL + url;
  const username = localStorage.getItem("user");
  return new Promise((resolve, reject) => {
    const tokenJson = Cookies.getJSON(`owAuth${window.location.port}`) || "";
    if (tokenJson == "") {
      localStorage.setItem("lastUrl", window.location.pathname);
      $.ajax({
        method: "post",
        url: `${API_BASE_URL}/autologout?userId=${getUser()}`,
        cache: false,
        dataType: "json",
        success: res => {
          resolve(res);
          window.location = "/";
        },
        error: err => {
          reject(err);
          window.location = "/";
        }
      });
      return;
    }
    const token = tokenJson.token;

    $.ajax({
      url: URL,
      method,
      cache: false,
      dataType: "json",
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
        username: localStorage.getItem("user")
      },
      success: function(x) {
        // console.log(
        //   "url:",
        //   URL,
        //   " method:",
        //   method,
        //   " data:",
        //   JSON.stringify(data)
        // );
        // console.log("uow-response", x);
        resolve(x);
      },
      error: function(err) {
        reject(err);
      }
    });
  });
}
export function atLastPageScrollToTop(datalength, rowsPerPage, currentpage)
{
  //currentpage start from 0
  var a = Math.floor(datalength / rowsPerPage);
  var b = datalength % rowsPerPage;
  if((b!==0 && a == currentpage) ||(b==0 && a-1 == currentpage))
  {
    window.scrollTo(0, 0);
  }
}

export const defaultImage = 'https://files.meridianapps.com/meridian-web-sdk/0.25.0/files/tags/generic.svg';

export const allowedDomains = ["@uw.edu","@hpe.com","@hpepngalway.onmicrosoft.com"];

export function getEnvironment() {
  switch(API_BASE_URL) {
    case "https://assettrackerapi.azurewebsites.net/api":
      return "prod";
    case "https://uwmc-api.azurewebsites.net/api":
      return "dev";
    case "http://localhost:11903/api":
      return "local";
    default:
      return "none";
  }
}

export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$_&@%]).{8,15}$/;
export const PASSWORD_ERROR = "Password does not meet the criteria";
