import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";

import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import SubmitIcon from "@material-ui/icons/CheckCircleOutline";

import { getMaxEntityAccess } from "../utils/Utils";
import { TextField } from "@material-ui/core";

const styles = theme => ({
    container: {
        padding: "12px",
    },
    titleheader: {
        textAlign: "center",
        borderBottom: "2px solid",
        
        '&:hover': {
            borderBottom: "2px solid #4b2e83"
        }
    },
    text: {
        color: "#4b2e83",
        display: "flex", 
        justifyContent: "start",
        padding: "12px",
    },
    savebutton: {
        display: "flex", 
        justifyContent: "end",
    },
    textField: {
        width: "100%",
        background: "white",
      },
});

class AlertBannerManage extends Component{
    render(){

        const { classes } = this.props;

        return(
            <div className={classes.container}>

                {(getMaxEntityAccess() > 500) ? 
                (
                    <Grid container spacing={2} style={{width: "auto"}}>
                        <Grid item xs={6}>
                            <div className={classes.titleheader}>
                                <h5>
                                    Add Alert Banner
                                </h5>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.titleheader}>
                                <h5>
                                    View All Alert Banners
                                </h5>
                            </div>
                        </Grid>


                        <Grid container spacing={1} className={classes.savebutton}>
                            <Grid item xs={6} style={{display:"flex", justifyContent: "space-between", padding: "12px"}}> 
                                <div className={classes.text} >
                                    <h5> 
                                        Type 
                                    </h5>
                                </div> 
                                <div>
                                    <TextField label="Banner Type" variant="outlined" margin="dense" />
                                </div>
                            </Grid>

                            <Grid item xs={4} sm={4} md={4} lg={4} style={{}}>
                                <Tooltip title={"Create"}>
                                    <IconButton
                                        style={{alignSelf: "center"}}
                                        color="primary"
                                        aria-label="Create"
                                        onClick={() => console.log("SAVE CLICKED!")}>
                                        <SubmitIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>

                        </Grid>

                    </Grid>
                    
                )
                :
                (
                    <h3>
                        Permission missing!
                    </h3>
                )
                }

            </div>
        );
    }
}

export default compose(withStyles(styles)) (AlertBannerManage);