import React from 'react';
import packageJson from '../package.json';
global.appVersion = packageJson.version;

// version from response - first param, local version second param
const semverGreaterThan = (versionA, versionB) => {
    const versionsA = versionA.split(/\./g);

    const versionsB = versionB.split(/\./g);
    while (versionsA.length || versionsB.length) {
        const a = Number(versionsA.shift());

        const b = Number(versionsB.shift());
        // eslint-disable-next-line no-continue
        if (a === b) continue;
        // eslint-disable-next-line no-restricted-globals
        return a > b || isNaN(b);
    }
    return false;
};

class CacheBuster extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            isLatestVersion: false,
            refreshCacheAndReload: () => {
                console.log(' buster Clearing cache and hard reloading...')
                if (caches) {
                    //Service worker cache should be cleared with caches.delete()
                    caches.keys().then(function (names) {
                        for (let name of names) {
                            console.log("Deleting cache from ", name, "in", names);
                            caches.delete(name);
                        }
                    });
                }

                // delete browser cache and hard reload
                //window.location.reload(true);
                // eslint-disable-next-line no-self-assign
                window.location.href = window.location.href;
            }
        };
    }

    componentDidMount() {
        var path = `/meta${global.appVersion}.json`;
        fetch(/*'/meta.json'*/path)
            .then((response) => response.json())
            .then((meta) => {
                const latestVersion = meta.version;
                const currentVersion = global.appVersion;
                const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
                console.log("buster", latestVersion, currentVersion, shouldForceRefresh);
                if (caches) {
                    //Service worker cache should be cleared with caches.delete()
                    caches.keys().then(function (names) {
                        for (let name of names) {
                            console.log("Deleting cache from ", name, "in", names);
                            caches.delete(name);
                        }
                    });
                }
                if (shouldForceRefresh) {
                    console.log(`buster We have a new version - ${latestVersion}. Should force refresh`);
                    this.setState({ loading: false, isLatestVersion: false });
                } else {
                    console.log(`buster You already have the latest version - ${latestVersion}. No cache refresh needed.`);
                    this.setState({ loading: false, isLatestVersion: true });
                }
            })
            .catch(error=> {
                console.log("buster-error", error);
                this.state.refreshCacheAndReload();
            });
    }
    render() {
        const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
        console.log("buster", this.state);
        return this.props.children({ loading, isLatestVersion, refreshCacheAndReload });
    }
}

export default CacheBuster;