import React, { Component } from "react";
import Navbar from "../components/Navbar";
import Cookies from "js-cookie";
import { withRouter } from "react-router";
import { getMaxEntityAccess, isPortalAdmin } from "../utils/Utils";
import { getEntity, getLocations, getCollections } from "../actions/index";
import { compose } from "recompose";
import { connect } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { clearMessage } from "../actions/index";
import { apiRequest, setAccess } from "../utils/Utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import PrimaryFilter from "../components/PrimaryFilter";
import AlertContext from "../components/AlertContext";

const styles = (theme) => ({
  input: {
    height: 38,
  },
  label: {
    marginTop: "-5px",
  },
  textField: {
    width: "100%",
    background: "white",
  },
});
class Layout extends Component {
  state = {
    padding: 74,
    open: false,
    message: "",
    loadNav: false,
    entities: [],
    locationData: [],
    departmentData: [],
    collectionData: [],
    entity: sessionStorage.getItem("entityID")
      ? sessionStorage.getItem("entityID")
      : "",
    loc: sessionStorage.getItem("locationID")
      ? sessionStorage.getItem("locationID")
      : "",
    dept: sessionStorage.getItem("departmentID")
      ? sessionStorage.getItem("departmentID")
      : "",
    coll: sessionStorage.getItem("collectionID")
      ? sessionStorage.getItem("collectionID")
      : "",
    deptChanged: false,

    isActiveAlertChanged: false,
    hasActiveAlert: false
  };

  handleAlert=(value)=> this.setState({isActiveAlertChanged: value});

  handleActiveAlert=(value)=> {this.setState({hasActiveAlert: value});}

  handlePadding = (Value) => {
    this.setState({ padding: Value });
  };

  handleClose = (event, reason) => {
    this.setState({ open: false }, () => {
      this.props.clearMessages();
    });
  };

  componentWillMount() {
    console.log(caches,"available");
    if (caches) {
      //Service worker cache should be cleared with caches.delete()
      caches.keys().then(function (names) {
        for (let name of names) {
          console.log("Deleting cache from ", name, "in", names);
          caches.delete(name);
        }
      });
    }
    this.name = localStorage.getItem("user");
    window.addEventListener("focus", this.onFocus2);
    this.getAccess();
  }
  // componentDidMount()
  // {
  //   this.props.getEntities();
  // }
  componentWillReceiveProps({ entities, locations, collections, message }) {
    console.log("koll", collections);
    this.setState({
      entities: entities,
      //locationData: locations,
      //collectionData: collections,
    });
    this.name = localStorage.getItem("user");
    if (message !== "") {
      this.setState({
        open: true,
      });
    }
  }

  componentWilUnmount() {
    window.removeEventListener("focus", this.onFocus2);
  }

  onFocus2 = () => {
    if (this.name != localStorage.getItem("user")) {
      this.name = localStorage.getItem("user");
      this.setState({
        loadNav: false,
      });
      this.getAccess();
    }
  };

  getAccess() {
    const { pathname, search } = this.props.location;
    if (Cookies.get(`owAuth${window.location.port}`)) {
      apiRequest(`/accesscontrol`, "get")
        .then((x) => {
          setAccess(x.data);
          this.setState({
            loadNav: true,
          });
          if (x.data.length == 0) {
            this.props.history.push("/noaccess");
            //redirect the user to no access to view page
          } else if (getMaxEntityAccess() <= 300) {
            if (pathname.includes("/asset"))
            {
              this.props.history.push(pathname);
            } else {
              this.props.history.push("/devices/viewtable");
            }
          } else {
            if (pathname.includes("options/Modify/collection")) {
              if (search.includes("type=dept"))
                this.props.history.push(
                  "/options/Modify/collection/0?type=dept"
                );
              else
                this.props.history.push(
                  "/options/Modify/collection/0?type=col"
                );
            } else if (pathname.includes("options/Create/collection")) {
              if (search.includes("type=dept"))
                this.props.history.push(
                  "/options/Create/collection/0?type=dept"
                );
              else
                this.props.history.push(
                  "/options/Create/collection/0?type=col"
                );
            } else if (pathname.includes("options/Modify/loc"))
              this.props.history.push("/options/Modify/loc/0");
            else {
              if(pathname.includes("/upload")) {
                if(isPortalAdmin)
                  this.props.history.push(pathname);
                else
                  this.props.history.push("/devices/viewtable");
              }
              else
                this.props.history.push(pathname);
            }
          }
        })
        .catch((err) => {
          Cookies.remove(`owAuth${window.location.port}`);
          this.setState({
            loadNav: true,
          });
          this.props.history.push("/");
        });
    } else {
      this.setState({
        loadNav: true,
      });
      this.props.history.push("/");
    }
  }
  handleChange = (name) => (event) => {
    this.setState(
      {
        [name]: event.target.value,
      },
      () => {
        if (name === "entity") {
          sessionStorage.setItem("entityID", event.target.value);
          sessionStorage.setItem("collectionID", "");
          //this.props.getLocation(event.target.value);
          this.getLoc(event.target.value);
          //this.props.getCollection(event.target.value);
          this.getColl(event.target.value);
        } else if (name === "loc") {
          sessionStorage.setItem("locationID", event.target.value);
          this.setState({ loc: event.target.value });
          //this.props.getCollection(this.state.entity, event.target.value);
          this.getDept(this.state.entity, event.target.value);
        } else if (name === "dept") {
          sessionStorage.setItem("departmentID", event.target.value);
          this.setState({ dept: event.target.value });
        } else if (name === "coll") {
          sessionStorage.setItem("collectionID", event.target.value);
          this.setState({ coll: event.target.value });
        }
      }
    );
    const current = this.props.location.pathname + this.props.location.search;
    this.props.history.replace(`/reload`);
    setTimeout(() => {
      this.props.history.replace(current);
    });
  };
  getLoc(entityId) {
    apiRequest(`/entities/${entityId}/locations`, "get")
      .then((response) => {
        if (response.status > 0) {
          const locationData = response.data.map((c) => {
            return {
              id: c.LocationId,
              name: c.LocationName,
              entityId: c.EntityId,
            };
          });
          this.setState({ locationData: locationData });
        } else {
          this.setState({ locationData: [] });
        }
      })
      .catch((error) => console.log(error));
  }
  getDept(entityId, locationId) {
    const url =
      locationId === ""
        ? `/entities/${entityId}/collections`
        : `/entities/${entityId}/locations/${locationId}/collections`;
    apiRequest(url, "get")
      .then((response) => {
        if (response.status > 0) {
          const departmentData = response.data.map((c) => {
            return {
              id: c.CollectionId,
              name: c.CollectionName,
              location: c.LocationName,
              locationId: c.LocationId,
              entityId: c.EntityId,
              collectionType: c.CollectionType,
            };
          });
          this.setState({ departmentData: departmentData });
        } else {
          this.setState({ departmentData: [] });
        }
      })
      .catch((error) => console.log(error));
  }
  getColl(entityId, locationId = "") {
    const url =
      locationId === ""
        ? `/entities/${entityId}/collections`
        : `/entities/${entityId}/locations/${locationId}/collections`;
    apiRequest(url, "get")
      .then((response) => {
        if (response.status > 0) {
          const collectionData = response.data.map((c) => {
            return {
              id: c.CollectionId,
              name: c.CollectionName,
              location: c.LocationName,
              locationId: c.LocationId,
              entityId: c.EntityId,
              collectionType: c.CollectionType,
            };
          });
          this.setState({ collectionData: collectionData });
        } else {
          this.setState({ collectionData: [] });
        }
      })
      .catch((error) => console.log(error));
  }

  render() {
    const { pathname, search } = this.props.location;
    const { classes } = this.props;
    console.log(
      "benc",
      this.state,
      this.props,
      sessionStorage.getItem("entityID")
    );
    const current = this.props.location.pathname + this.props.location.search;
    return (
      <React.Fragment>
        {this.state.loadNav ? (
          <React.Fragment>
            <AlertContext.Provider value={{isActiveAlertChanged: this.state.isActiveAlertChanged, change: this.handleAlert, hasActiveAlert: this.state.hasActiveAlert, changeHasActiveAlert: this.handleActiveAlert}}>
            {pathname !== "/" ? (
              <Navbar
                data={this.handlePadding}
                pathName={pathname}
                search={search}
                isActiveAlertChanged={this.state.isActiveAlertChanged}
                change={this.handleAlert}
                hasActiveAlert={this.state.hasActiveAlert}
                changeHasActiveAlert={this.handleActiveAlert}
              />
            ) : null}

            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              open={this.state.open}
              autoHideDuration={5000}
              onClose={this.handleClose}
              ContentProps={{
                "aria-describedby": "message-id",
              }}
              message={<span id="message-id">{this.props.message}</span>}
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={this.handleClose}
                >
                  <CloseIcon />
                </IconButton>,
              ]}
            />
            <div
              style={{ paddingLeft: this.state.padding, paddingTop: this.state.hasActiveAlert? "0px" :"60px", height: this.state.hasActiveAlert? "calc(100vh - 150px)" : "calc(100vh - 30px)", overflowx: "hidden" }}
            >
              <div id="mainComp" style={{height: "100%", overflowY: "auto"}}>
              {pathname !== "/" &&
              pathname !== "/noaccess" &&
              pathname !== "/options/users/permissions" &&
              pathname !== "/master/data/manage" &&
              pathname !== "/user/profile" &&
              pathname != "/devices/map" &&
              pathname != "/devices/upload" &&
              pathname != "/options/users/upload" &&
              pathname != "/options/users/create" &&
              pathname != "/options/alertbanner/manage" &&
              pathname != "/options/Create/alert" &&
              pathname != "/options/View/alert" &&
              !pathname.includes("asset") &&
              !pathname.includes("/master/data/manage") &&
              !pathname.includes("log/") ? (
                <PrimaryFilter notVisible={pathname == "/options/Modify/entity" || pathname == "/options/Create/entity" || pathname == "/options/roles" || pathname == "/options/users"} >{this.props.children}</PrimaryFilter>
              ) : (
                this.props.children
              )}
              </div>
              {/*<div id="test" style={{background: "#f0f0f0", paddingLeft: "14px", marginBottom: "15px"}}>
                <Grid container spacing={8} style={{width: "100%"}}>
                  <Grid item xs={2}>
                  <TextField
                    width="100%"
                    id="outlined-select"
                    select
                    required
                    margin="dense"
                    label="Organization"
                    className={classes.textField}
                    value={this.state.entity}
                    onChange={this.handleChange("entity")}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    InputProps={{ className: classes.input }}
                    InputLabelProps={{
                      className: classes.label
                    }}
                    variant="outlined"
                  >
                    {this.state.entities.map(option => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                    </Grid>
                    {!current.includes("col") && 
                    <Grid item xs={2}>
                  <TextField
                    width="100%"
                    id="outlined-select"
                    select
                    required
                    margin="dense"
                    label="Building"
                    className={classes.textField}
                    value={this.state.loc}
                    onChange={this.handleChange("loc")}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    InputProps={{ className: classes.input }}
                    InputLabelProps={{
                      className: classes.label
                    }}
                    variant="outlined"
                  >
                    {this.state.locationData.map(option => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                    </Grid> }
                    {!current.includes("col") && 
                    <Grid item xs={2}>
                  <TextField
                    width="100%"
                    id="outlined-select"
                    select
                    required
                    margin="dense"
                    label="Department"
                    className={classes.textField}
                    value={this.state.dept}
                    onChange={this.handleChange("dept")}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    InputProps={{ className: classes.input }}
                    InputLabelProps={{
                      className: classes.label
                    }}
                    variant="outlined"
                  >
                    {this.state.departmentData.map(option => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                    </Grid>}
                    {!current.includes("dept") && 
                    <Grid item xs={2}>
                    <TextField
                    width="100%"
                    id="outlined-select"
                    select
                    required
                    margin="dense"
                    label="Collection"
                    className={classes.textField}
                    value={this.state.coll}
                    onChange={this.handleChange("coll")}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    InputProps={{ className: classes.input }}
                    InputLabelProps={{
                      className: classes.label
                    }}
                    variant="outlined"
                  >
                    {this.state.collectionData.map(option => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                    </Grid> }
                    {(!current.includes("col") && !current.includes("dept")) &&
                    <Grid item xs={2}>
                  <TextField
                    width="100%"
                    id="outlined-select"
                    select
                    required
                    margin="dense"
                    label="Floor"
                    className={classes.textField}
                    value={""}
                    onChange={this.handleChange("entity")}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    InputProps={{ className: classes.input }}
                    InputLabelProps={{
                      className: classes.label
                    }}
                    variant="outlined"
                  >
                    {this.state.entities.map(option => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                    </Grid>}
                    <Grid xs={1}/>
                    <Grid xs={1} style={{alignSelf: "center"}}>
                      <Button>
                        
                        </Button>
                      </Grid>
                  </Grid>
                    </div>*/}
            </div>
            </AlertContext.Provider>
          </React.Fragment>
        ) : (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress
              style={{ width: "70px", height: "70px", color: "#4b2e83" }}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { entities, locations, collections, message } = state;
  return {
    entities,
    locations,
    collections,
    message,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEntities: () => getEntity(dispatch),
    //storeEntity: () => getEntity(dispatch),
    getLocation: (entityId) => getLocations(dispatch, entityId),
    getCollection: (entityId, locationId = "") => {
      console.log("kollGetColl");
      getCollections(dispatch, entityId, locationId);
    },

    clearMessages: () => dispatch(clearMessage()),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withStyles(styles)
)(Layout);
