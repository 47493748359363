import React, { Component } from "react";
import "./Page2.css";
import "../components/SideBar.css";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import MapView from "../components/MapView.jsx";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { apiRequest } from "../utils/Utils";
import {PFContext} from "../components/PrimaryFilterContext";

const styles = theme => ({});

class About extends Component {
  static contextType = PFContext;
  constructor() {
    super();
    this.state = {
      collapse: "true",
      isHidden: true,
      value: 1,
      haveNoAcess: false
    };
  }
  handleChange = (event, value) => {
    this.setState({ value });
  };

  componentDidMount() {
    apiRequest(`/accesscontrol`, "get").then(x => {
      if (x.data.length == 0) {
        this.props.history.push("/noaccess");
      } else {
      }
    });
  }
  render() {
    return (
      <div>
        <div
          id="content"
          style={{
            //paddingTop: "60px",
            transition: "0.25s",
            paddingLeft: "0px"
          }}
        >
          <div className="container-fluid">
            <div className="row">
              <div
                className="col-lg-12"
                style={{
                  height: "100%",
                  width: "100%"
                }}
              >
                {/* <Grid container spacing={8}>
                  <Grid item xs={6}>
                    <div
                      style={{
                        textAlign: "center",
                        borderBottom: "2px solid lightgrey"
                      }}
                    >
                      <Link className="nav-link" to="/devices/viewtable">
                        <h5
                          style={{
                            color: "lightgrey"
                          }}
                        >
                          Tabular View
                        </h5>
                      </Link>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div
                      style={{
                        textAlign: "center",
                        borderBottom: "2px solid #4b2e83"
                      }}
                    >
                      <div className="nav-link">
                        <h5
                          style={{
                            color: "#4b2e83"
                          }}
                        >
                          Map View
                        </h5>{" "}
                      </div>
                    </div>
                  </Grid>
                </Grid>
 */}
                 <PFContext.Consumer>
                  {(data) => (
                <MapView values={data} dummy={2}/>)}
                </PFContext.Consumer>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(About);
