import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import Login from "./pages/Login.jsx";
import Home from "./pages/DashBoard.jsx";
import Page2 from "./pages/ViewDevicesTable.jsx";
import Page2Map from "./pages/ViewDevicesMap.jsx";
import Page3 from "./pages/ManageDevicesPage.jsx";
import Page3Col from "./pages/ManageDevicesPageCollections.jsx";
import Alert from "./pages/OptionsAlert.jsx";
import Page4Entity from "./pages/OptionsEntity.jsx";
import Page4Loc from "./pages/OptionsLocation.jsx";
import Page4Dept from "./pages/OptionsDepartment.jsx";
import ManageUsers from "./pages/ManageUsers.jsx";
import CreateUsers from "./pages/CreateUsers.jsx";
import Layout from "./pages/Layout.jsx";
import MasterDataManagement from "./pages/MasterDataManagement.jsx";
import MasterDataManagementType from "./pages/MasterDataManagementType.jsx";
import MasterDataManagementManufacturerModel from "./pages/MasterDataManagementManufacturerModel.jsx";
import MapDevices from "./pages/MapDevicesToEntity";
import NoAccessHome from "./pages/NoAccess";
import UserPermissions from "./pages/UserPermissions";
import UserPermissionsHandler from "./pages/UserPermissionsHandler.jsx";
import UserProfile from "./pages/UserProfile";
import CacheBuster from './CacheBuster';
import Configure from './components/ConfigureSingleComponent';
import AlertBannerManage from "./pages/ManageAlertBannerPage.jsx";
import TagBulkUpload from './components/TagBulkUpload';
import UserBulkUpload from './components/UserBulkUpload';
import UsersListContainer from "./pages/UsersListContainer";
import VievActivityLog from "./pages/VievActivityLog.jsx";

// AUTH
// import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
// import Cookies from "js-cookie";

// class AuthCheck extends Component {
//   check() {
//     let cookie = Cookies.get(`owAuth${window.location.port}`);
//     if(cookie) {
//       return(this.props.children);
//     }
//     else {
//       return (
//         <AuthenticatedTemplate>
//         {this.props.children}
//       </AuthenticatedTemplate>
//       );
//     }
//   }
//   render() {    
//     // return (
//     //   <AuthenticatedTemplate>
//     //     {this.props.children}
//     //   </AuthenticatedTemplate>
//     // );
//     return(this.props.children);
//   }
// }

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
        <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
            if (loading) return null;
            // if (!loading && !isLatestVersion) {
            //     refreshCacheAndReload();
            // }
            return (
              <Layout>
                <Switch>
                  <Route exact path="/" component={Login} />
                  {/*<AuthCheck>*/}
                    <Route path="/home" component={Home} />
                    <Route path="/devices/viewtable" component={Page2} />
                    <Route path="/devices/viewmap" component={Page2Map} />
                    <Route path="/devices/manage/dept" component={Page3} />
                    <Route path="/devices/manage/col" component={Page3Col} />
                    <Route path="/asset/:id" component={Configure} />
                    <Route
                      path="/log/activity"
                      component={VievActivityLog}
                    />
                    <Route
                      path="/options/:function/alert"
                      component={Alert}
                    />
                    <Route
                      path="/options/:function/entity"
                      component={Page4Entity}
                    />
                    <Route
                      path="/options/:function/loc/:id"
                      component={Page4Loc}
                    />
                    <Route
                      path="/options/:function/collection/:id"
                      component={Page4Dept}
                    />

                    <Route
                      path="/options/roles"
                      component={ManageUsers}
                    />
                    <Route
                      path="/options/users/create"
                      component={CreateUsers}
                    />

                    <Route path="/options/users/upload" component={UserBulkUpload} />

                    <Route path="/options/users" component={UsersListContainer}/>

                    <Route
                      path="/master/data/manage/manufacturer/:id/model/:modelId"
                      component={MasterDataManagementManufacturerModel}
                    />

                    <Route
                      path="/master/data/manage/type/:id"
                      component={MasterDataManagementType}
                    />

                    <Route
                      path="/options/alertbanner/manage"
                      component={AlertBannerManage}
                    />

                    <Route
                      path="/master/data/manage"
                      component={MasterDataManagement}
                    />

                    <Route path="/devices/map" component={MapDevices} />
                    <Route path="/noaccess" component={NoAccessHome} />
                    <Route
                      path="/options/users/permissions"
                      component={UserPermissionsHandler}
                    />
                    <Route path="/user/profile" component={UserProfile} />
                    <Route path="/devices/upload" component={TagBulkUpload} />
                  {/*</AuthCheck>*/}
                </Switch>
                {/* </ThemeProvider> */}
              </Layout>
            );
                  }}
              </CacheBuster>
        </Switch>
      </Router>
    );
  }
}

export default App;
