import { apiRequest, isPortalAdmin, getMaxEntityAccess } from "../utils/Utils";
import React, { Component } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import UserPermissions from "./UserPermissions";
import UserPermissionsAdminView from "./UserPermissionsAdminView.jsx";

class UserPermissionsHandler extends Component {
    state ={};
    render() {
        return (
            <div>
            {isPortalAdmin ? (  
                <UserPermissionsAdminView>
                </UserPermissionsAdminView>) 
                :
                (<UserPermissions>
                    </UserPermissions>)
            }
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {};
  };
  
  const mapDispatchToProps = dispatch => {
    return {
      //showMessageBox: message => dispatch(showMessage(message))
    };
  };
  
  export default compose(
    connect(
      mapStateToProps,
      mapDispatchToProps
    ),
    //withStyles(styles)
  )(UserPermissionsHandler);