import React, { Component } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import Grid from "@material-ui/core/Grid";
import MissingIcon from "@material-ui/icons/Help";
import OrphanedIcon from "@material-ui/icons/Error";
import Paper from "@material-ui/core/Paper";
import { getUser, allowedDomains, apiRequest} from "../utils/Utils";
import { MsalContext, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

const percentage = 66;
class About extends Component {
  static contextType = MsalContext;
  render() {
    let user = getUser();
    return (
      <div>
        <div>
          <div
            id="content"
            style={{
              paddingTop: "60px",
              transition: "0.25s",
              paddingLeft: "0px"
            }}
          >
            <div className="container-fluid">
              <div
                className="col-lg-12"
                style={{
                  height: "100%",
                  width: "100%"
                }}
              />

              <div className="pt-3 pb-2 mb-3">
                <Paper
                  style={{
                    marginTop: "10px",
                    width: "100%",
                    height: "500px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 30
                  }}
                >
                  {this.context.accounts.length > 0 && user && !allowedDomains.some(d=> user.endsWith(d)) ?
                  (
                    <h4>User not authorized to access the application.</h4>
                  )
                  :
                  (
                    <h4>You don't have access to any resources. Please contact <a href="mailto:assettracker@uw.edu"> System Administrator</a> to request access.</h4>
                  )}
                </Paper>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
