import React, { Component } from "react";
import PropTypes from "prop-types";
import ModifyUsers from "../components/ModifyUsersComp.jsx";
import { Link } from "react-router-dom";

import Grid from "@material-ui/core/Grid";

class DeviceManage extends Component {
  state = {};
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });

  };
  render() {
    return (
      <div>
        <div
          id="content"
          style={{
            //paddingTop: "60px",
            transition: "0.25s",
            paddingLeft: "0px"
          }}
        >
          <div className="container-fluid">
            <div className="row">
              <div
                className="col-lg-12"
                style={{
                  height: "100%",
                  width: "100%"
                }}
              >
                <ModifyUsers />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default DeviceManage;
