import React, { Component } from "react";
import ManageDevices from "../components/ManageDevicesComp.jsx";
import {PFContext} from "../components/PrimaryFilterContext";

class DeviceManage extends Component {
  state = {};
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };
  render() {
    return (
      <div>
        <div
          id="content"
          style={{
            //paddingTop: "60px",
            transition: "0.25s",
            paddingLeft: "0px"
          }}
        >
          <div className="container-fluid">
            <div className="row">
              <div
                className="col-lg-12"
                style={{
                  height: "100%",
                  width: "100%"
                }}
              >
                <div
                  style={{
                    paddingBottom: "20px"
                  }}
                >
                  <PFContext.Consumer>
                    {data => 
                  <ManageDevices params={this.props.location.search} values={data}/>
                    }
                  </PFContext.Consumer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DeviceManage;
