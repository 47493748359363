import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#56dbb2',
      main: '#4b2e83',
      dark: '#007955',
      contrastText: '#fff',
    },
  },
});

export default theme;