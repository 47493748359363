import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default class AlertDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open
    };
  }

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  componentWillReceiveProps({ open }) {
    this.setState({
      open
    });
  }

  render() {
    return (
      <div>
        <Dialog
          open={this.state.open}
          //onClose={this.handleClose}
          onClose={this.props.close}
          //onBackdropClick={this.props.close}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >

          { this.props.isError ? 
            <DialogTitle>
              {`ERROR!`}
            </DialogTitle>

            :

            <DialogTitle
              id="alert-dialog-title"
              style={{ width: 320 }}
            >
              { this.props.action && this.props.action != "Replace" ?
                `Confirm ${this.props.action}`
                : 
                'Replace'
              }
            </DialogTitle>
          }

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.props.children ? "" : (this.props.subText == "" ||
              this.props.subText == undefined ||
              this.props.subText == null)
                ? "Are you sure?"
                : this.props.subText}
            </DialogContentText>
            {this.props.children}
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => {
                this.handleClose();
                this.props.close();
              }}
              hidden={this.props.isError}
              color="primary"
              style={{
                color: "#4b2e83",
                margin: 10,
                height: 45
              }}
              className="button1"
            >
              {this.props.children ? "Cancel" : "No"}
            </Button>

            <Button
              onClick={() => {
                if(this.props.isError === true){
                  this.handleClose();
                  this.props.close();
                }
                else{
                  this.props.onClickYes();
                }
              }}
              color="primary"
              style={{
                color: "#4b2e83",
                margin: 10,
                height: 45
              }}
              className="button1"
              autoFocus
            >
              {this.props.children ? "Ok" : "Yes"}
            </Button>
          </DialogActions>

        </Dialog>
      </div>
    );
  }
}
