import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import ResetIcon from "@material-ui/icons/Refresh";
import SubmitIcon from "@material-ui/icons/CheckCircleOutline";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import {
  getlocationId,
  getEntityId,
  storeEntityId,
  storelocationId,
  getMaxDepartmentAccess,
  getMaxEntityAccess,
  getMaxLocationAccess,
  byName
} from "../utils/Utils";
import {
  getEntity,
  getLocations,
  getCollections,
  cleanData,
  showMessage
} from "../actions/index";
import { compose } from "recompose";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import GoBackIcon from "@material-ui/icons/ArrowBack";
import { apiRequest } from "../utils/Utils";
import AlertDialog from "./AlertDialog";
import Button from "@material-ui/core/Button";

const styles = theme => ({
  rootcheckbox: {
    color: "#4b2e83",
    "&$checked": {
      color: "#4b2e83"
    }
  },
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
    flexGrow: 1
  },
  root2: {
    ...theme.mixins.gutters(),
    paddingBottom: theme.spacing.unit * 1
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  textField: { width: "100%" },
  dense: {
    marginTop: 16
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing.unit,
    backgroundColor: "#4b2e83",
    width: "250px"
  },
  input: {
    height: 38
  },
  label: {
    marginTop: "-5px"
  }
});

const collectiontype = [
  { id: 1, value: "Department", label: "Department" },
  { id: 2, value: "User Defined Collection", label: "User Defined Collection" }
];

class CreateDepartment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stateModel: {
        EntityId: sessionStorage.getItem("entityID") ? sessionStorage.getItem("entityID") : "",
        EntityName: "",
        LocationId: "",
        LocationName: "",
        CollectionId: "",
        CollectionName: "",
        CollectionType: props.location.search.includes("type=col")
          ? collectiontype[1].label
          : collectiontype[0].label,
        Description: "",
        CollectionTypeId: props.location.search.includes("type=col")
          ? collectiontype[1].id
          : collectiontype[0].id,
        IsActive: false
      },
      serverModel: {
        EntityId: "",
        EntityName: "",
        LocationId: "",
        LocationName: "",
        CollectionId: "",
        CollectionName: "",
        CollectionType: props.location.search.includes("type=col")
          ? collectiontype[1].label
          : collectiontype[0].label,
        Description: "",
        CollectionTypeId: props.location.search.includes("type=col")
          ? collectiontype[1].id
          : collectiontype[0].id,
        IsActive: false
      },

      entityData: [],
      locationData: [],
      collectionData: [],
      isSubmitting: false,
      disableFields: false,
      openAlert: false,
      disableEntity: props.location.search.includes("xyz") ? true : false,
      showRedField: false
    };
    this.page = props.params;
    this.type = this.props.location.search;
  }

  componentWillMount() {
    //Navigate from Department/Collcetion Management - this.props.id = 0
    //Navigate from Organization/Building Management - this.props.id > 0 && getEntityId 
    this.k = 1;
    if (this.props.params === "Create" && getEntityId() > 0) {
      this.props.getLocation(getEntityId());
      this.setState({
        stateModel: {
          ...this.state.stateModel,
          EntityId: getEntityId(),
          LocationId: getlocationId() > -1 ? getlocationId() : 0
        }
      });
    }
    const that = this;
    //Navigate from Organization/Building Management
    if (this.props.id !== "0" &&  sessionStorage.getItem("entityID")!= "") {
      storeEntityId(sessionStorage.getItem("entityID"));
    //if (this.props.id !== "0" && getEntityId() > -1) {
      this.props.getLocation(getEntityId());
      this.props.getCollection(getEntityId(), this.props.id);

      let location_id = 0;
      if (getlocationId() > -1) {
        location_id = getlocationId();
      }

      apiRequest(
        `/entities/${getEntityId()}/locations/${location_id}/collections/${
          this.props.id
        }`,
        "get"
      )
        .then(response => {
          if (response.status > 0)
            this.setState({
              stateModel: response.data[0] || this.state.serverModel
            });
        })
        .catch(error => console.log(error));
      setTimeout(() => {
        this.props.showUsers(
          true,
          this.state.stateModel.EntityId,
          this.state.stateModel.LocationId,
          this.state.stateModel.CollectionId
        );
      }, 1000);
    }
    //Navigate from Department/Collcetion Management
    else if(this.state.stateModel.EntityId) {
        this.props.getEntities();
        this.props.getLocation(this.state.stateModel.EntityId);
        this.props.getCollection(this.state.stateModel.EntityId);
    }
  }

  handleChange = name => event => {
    const that = this;
    this.old_CollectionId = this.state.stateModel.CollectionId;
    this.setState(
      {
        stateModel: {
          ...this.state.stateModel,
          [name]: event.target.value
        }
      },
      () => {
        if (name == "EntityId") {
          this.props.getLocation(event.target.value);
          storeEntityId(event.target.value);
        }
        if (name === "LocationId" && this.props.params === "Create") {
          this.checkAccess();
          storelocationId(event.target.value);
        }

        if (name === "CollectionId" && this.props.params === "Modify") {
          this.checkAccess();
        }

        if (this.props.params === "Modify") {
          if (name == "EntityId") {
            if (this.props.location.search.includes("type=col"))
              this.props.getCollection(event.target.value);
            const selectedEntity = this.state.entityData.filter(
              item => item.id === event.target.value
            );
            this.setState({
              stateModel: {
                ...this.state.stateModel,
                EntityName: selectedEntity[0].name
              }
            });
            return;
          }

          if (
            name === "CollectionId" &&
            this.state.stateModel.CollectionId !== this.CollectionId
          ) {
            this.props.showUsers(
              true,
              this.state.stateModel.EntityId,
              this.state.stateModel.LocationId,
              this.state.stateModel.CollectionId
            );
            var selectedCollection = this.state.collectionData.filter(
              item => item.id === this.state.stateModel.CollectionId
            );

            apiRequest(
              `/entities/${
                this.state.stateModel.EntityId
              }/locations/${selectedCollection[0].locationId ||
                0}/collections/${this.state.stateModel.CollectionId}`,
              "get"
            )
              .then(response => {
                this.setState(
                  {
                    stateModel: this.state.serverModel
                  },
                  () => {
                    this.setState({
                      stateModel: response.data[0] || this.state.serverModel
                    });
                  }
                );
              })
              .catch(error => console.log("error", error));

            this.setState({
              stateModel: {
                ...this.state.stateModel,
                EntityName: selectedCollection[0].name
              }
            });
          }
          if (name === "LocationId") {
            storelocationId(event.target.value);
            this.props.getCollection(
              this.state.stateModel.EntityId,
              event.target.value
            );
          }
        }
        if (name === "CollectionType") {
          if (
            this.state.stateModel.CollectionType === "Department" &&
            (this.state.stateModel.LocationId === "" ||
              this.state.stateModel.LocationId < 0)
          ) {
            this.props.showMessageBox(
              "For Department collectionType, Location must be selected."
            );
          }
        }
      }
    );
  };

  checkAccess() {
    const { CollectionId, EntityId, LocationId } = this.state.stateModel;
    let type = "",
      id = "";
    if (this.props.params === "Create") {
      if (LocationId == "") {
        type = "entity";
        id = EntityId;
      } else {
        type = "location";
        id = LocationId;
      }
    } else if (this.props.params === "Modify") {
      type = "collection";
      id = CollectionId;
    }
    apiRequest(`/accesscontrol?resourceType=${type}&resourceId=${id}`, "get")
      .then(response => {
        if (response.data[0] < 300) {
          this.setState({
            disableFields: true
          });
        } else {
          this.setState({
            disableFields: false
          });
        }
      })
      .catch(error => console.log("error", error));
  }

  handleReset = () => {
    const stateModel = this.state.serverModel;
    this.setState({
      disableFields: false,
      //locationData: [],
      //collectionData: [],
      disableEntity: false,
      stateModel: {
        ...this.state.serverModel,
        //EntityId: sessionStorage.getItem("entityID") ? sessionStorage.getItem("entityID") : "",
        EntityId: this.props.entity,
        CollectionType: this.props.location.search.includes("type=col")
          ? collectiontype[1].label
          : collectiontype[0].label,
        CollectionTypeId: this.props.location.search.includes("type=col")
          ? collectiontype[1].id
          : collectiontype[0].id
      }
    });
    this.props.showUsers(false);
  };

  handleSubmt = () => {
    const THAT = this;
    THAT.setState({
      isSubmitting: true,
      openAlert: false,
      showRedField: false
    });
    const data = this.state.stateModel;
    let url = "";
    let method = "";
    if (this.props.params === "Create") {
      if (this.props.location.search.includes("type=col"))
        url = `/entities/${
          this.state.stateModel.EntityId
        }/locations/0/collections`;
      else
        url = `/entities/${this.state.stateModel.EntityId}/locations/${this
          .state.stateModel.LocationId || 0}/collections`;
      method = "post";
    } else if (this.props.params === "Modify") {
      if (this.props.location.search.includes("type=col"))
        url = `/entities/${
          this.state.stateModel.EntityId
        }/locations/0/collections/${this.state.stateModel.CollectionId}`;
      else
        url = `/entities/${this.state.stateModel.EntityId}/locations/${
          this.state.stateModel.LocationId
        }/collections/${this.state.stateModel.CollectionId}`;
      method = "put";
    }

    apiRequest(url, method, data)
      .then(x => {
        THAT.setState({
          isSubmitting: false
        });
        if (x.status == 0) {
          THAT.props.showMessageBox(x.message);
          return;
        }
        if (THAT.props.params === "Create") {
          this.setState({
            stateModel : {
              ...this.state.stateModel,
              CollectionId:x.data[0]
            }
          })
          THAT.props.showUsers(
            true,
            THAT.state.stateModel.EntityId,
            THAT.state.stateModel.LocationId,
            THAT.state.stateModel.CollectionId
          );
          if (this.props.location.search.includes("type=col"))
            THAT.props.history.push(
              `/options/Modify/collection/${x.data[0]}?type=col`
            );
          else
            THAT.props.history.push(
              `/options/Modify/collection/${x.data[0]}?type=dept`
            );
          THAT.props.getCollection(
            THAT.state.stateModel.EntityId,
            THAT.state.stateModel.LocationId
          );
        }
        if (THAT.props.params === "Modify") {
          THAT.props.getCollection(
            THAT.state.stateModel.EntityId,
            this.props.location.search.includes("type=col") ? "" : THAT.state.stateModel.LocationId
          );
        }
        THAT.props.showMessageBox("SUCCESS");
      })
      .catch(err => {
        THAT.setState({
          isSubmitting: false
        });
        THAT.props.showMessageBox("ERROR");
      });
  };

  stateChange() {
    const { serverModel, stateModel } = this.state;
    return JSON.stringify(serverModel) === JSON.stringify(stateModel)
      ? false
      : true;
  }

  componentWillReceiveProps({
    params,
    entities = [],
    locations = [],
    collections = [],
    location,
    entity,
  }) {
    if (this.page !== params || this.type !== location.search) {
      this.k = 1;
      this.handleReset();
      this.page = params;
      this.type = location.search;
      if(entity) {
        //get collection when change new/edit
        this.props.getCollection(entity);
      }
      this.setState({
        disableFields: false,
        stateModel: {
          ...this.state.serverModel,
          //EntityId: sessionStorage.getItem("entityID") ? sessionStorage.getItem("entityID") : "",
          EntityId: entity,
          CollectionType: location.search.includes("type=col")
            ? collectiontype[1].label
            : collectiontype[0].label,
          CollectionTypeId: location.search.includes("type=col")
            ? collectiontype[1].id
            : collectiontype[0].id
        }
      });
      return
    }

    if (locations.length === 0) {
      this.setState({
        stateModel: {
          ...this.state.serverModel,
          EntityId: this.state.stateModel.EntityId
        }
      });
    }

    if (collections.length === 0) {
      this.setState({
        stateModel: {
          ...this.state.serverModel,
          EntityId: this.state.stateModel.EntityId,
          LocationId: this.state.stateModel.LocationId,
          CollectionType: location.search.includes("type=col")
            ? collectiontype[1].label
            : collectiontype[0].label,
          CollectionTypeId: location.search.includes("type=col")
            ? collectiontype[1].id
            : collectiontype[0].id
        }
      });
    }
    this.setState(
      {
        entityData: entities,
        locationData: locations.sort(byName),
        collectionData: collections.filter(item => {
          if (location.search.includes("type=col"))
            return item.collectionType == "User Defined Collection";
          else return item.collectionType == "Department";
        }).sort(byName)
      },
      () => {
        if (this.props.params === "Create" && getEntityId() > 0) {
          this.setState({
            stateModel: {
              ...this.state.stateModel,
              EntityId: getEntityId(),
              LocationId: getlocationId() > -1 ? getlocationId() : 0
            }
          });
        }
      }
    );
    if(entity != this.state.stateModel.EntityId) {
      if(entity) {
        this.props.getCollection(entity);
      }
      this.setState({
        stateModel: {
          ...this.state.stateModel,
          EntityId: this.state.stateModel.EntityId
        }
      }, ()=> {      storeEntityId(entity);
        this.handleReset();});
      // storeEntityId(entity);
      // this.handleReset();
    }
    else if (
      this.props.params === "Modify" &&
      this.props.id != "0" &&
      this.state.stateModel.CollectionId === "" &&
      getEntityId() > -1
    ) {
      if (this.k == 1) {
        this.k = 2;
        let location_id = 0;
        if (getlocationId() > -1) {
          location_id = getlocationId();
        }

        this.props.getLocation(getEntityId());
        if (location.search.includes("type=col"))
          this.props.getCollection(getEntityId());
        else this.props.getCollection(getEntityId(), location_id);
        apiRequest(
          `/entities/${getEntityId()}/locations/${location_id}/collections/${
            this.props.id
          }`,
          "get"
        )
          .then(response => {
            if (response.status > 0) {
              this.setState({
                stateModel: response.data[0] || this.state.serverModel
              });
            }
          })
          .catch(error => console.log(error));
        setTimeout(() => {
          this.props.showUsers(
            true,
            this.state.stateModel.EntityId,
            this.state.stateModel.LocationId,
            this.state.stateModel.CollectionId
          );
        }, 1000);
      }
    }
  }

  handleDelete = () => {
    this.setState({
      isSubmitting: true,
      openAlert: false
    });

    apiRequest(`/collections/${this.state.stateModel.CollectionId}`, "delete")
      .then(x => {
        this.setState({
          isSubmitting: false
        });
        if (x.status > 0) {
          if(this.props.location.search.includes("type=col"))
            this.props.getCollection(this.state.stateModel.EntityId);
          this.handleReset()
        }

        if (x.status > 0 && x.message == null)
          this.props.showMessageBox("SUCCESS");
        else this.props.showMessageBox(x.message);
      })
      .catch(err => {
        this.props.showMessageBox("ERROR");
        this.setState({
          isSubmitting: false
        });
      });
  };

  render() {
    const { classes } = this.props;
    let headingText = "";
    if (this.props.params === "Create") {
      if (this.props.location.search.includes("type=col")) {
        headingText = "New Collection";
      } else {
        headingText = "New Department";
      }
    } else {
      if (this.props.location.search.includes("type=col")) {
        headingText = "Edit Collection";
      } else {
        headingText = "Edit Department";
      }
    }

    return (
      <div
        className="justify-content-between align-items-center pb-2 mb-3"
        style={{
          paddingLeft: "12px",
          paddingRight: "12px"
        }}
      >
        <AlertDialog
          open={this.state.openAlert}
          onClickYes={
            this.state.actionType == "DELETE"
              ? this.handleDelete
              : this.handleSubmt
          }
          action={this.state.actionType == "DELETE" ? "Delete" : "Save"}
          close={() => {
            this.setState({ openAlert: false });
          }}
        />
        {this.props.location.search.includes("type=col") ||
        (getMaxEntityAccess() >= 300 ||
          getMaxLocationAccess() >= 300 ||
          getMaxDepartmentAccess() > 500) ? (
          this.props.params === "Create" ? (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div
                  style={{
                    textAlign: "center",
                    borderBottom: "2px solid #4b2e83"
                  }}
                >
                  <div className="nav-link">
                    <h5
                      style={{
                        color: "#4b2e83"
                      }}
                    >
                      {this.props.location.search.includes("type=col")
                        ? "Add Collection"
                        : "Add Department"}
                    </h5>{" "}
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div
                  style={{
                    textAlign: "center",
                    borderBottom: "2px solid lightgrey"
                  }}
                >
                  <Link
                    onClick={()=> this.setState({showRedField: false})}
                    className="nav-link"
                    to={
                      this.props.location.search.includes("type=col")
                        ? "/options/Modify/collection/0?type=col"
                        : "/options/Modify/collection/0?type=dept"
                    }
                  >
                    <h5
                      style={{
                        color: "lightgrey"
                      }}
                    >
                      {this.props.location.search.includes("type=col")
                        ? "Edit Collection"
                        : "Edit Department"}
                    </h5>
                  </Link>
                </div>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div
                  style={{
                    textAlign: "center",
                    borderBottom: "2px solid lightgrey"
                  }}
                >
                  <Link
                    onClick={()=> this.setState({showRedField: false})}
                    className="nav-link"
                    to={
                      this.props.location.search.includes("type=col")
                        ? "/options/Create/collection/0?type=col"
                        : "/options/Create/collection/0?type=dept"
                    }
                  >
                    <h5
                      style={{
                        color: "lightgrey"
                      }}
                    >
                      {this.props.location.search.includes("type=col")
                        ? "Add Collection"
                        : "Add Department"}
                    </h5>
                  </Link>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div
                  style={{
                    textAlign: "center",
                    borderBottom: "2px solid #4b2e83"
                  }}
                >
                  <div className="nav-link">
                    <h5
                      style={{
                        color: "#4b2e83"
                      }}
                    >
                      {this.props.location.search.includes("type=col")
                        ? "Edit Collection"
                        : "Edit Department"}
                    </h5>{" "}
                  </div>
                </div>
              </Grid>
            </Grid>
          )
        ) : (
          <Grid item xs={12}>
            <div
              style={{
                textAlign: "center",
                borderBottom: "2px solid #4b2e83"
              }}
            >
              <div className="nav-link">
                <h5
                  style={{
                    color: "#4b2e83"
                  }}
                >
                  {this.props.location.search.includes("type=col")
                    ? "Edit Collection"
                    : "Edit Department"}
                </h5>{" "}
              </div>
            </div>
          </Grid>
        )}
        <Paper className={classes.root} style={{ padding: 10 }}>
          <div>
            <Grid
              container
              spacing={2}
              style={
                this.props.params === "Modify"
                  ? { marginBottom: "-13px", marginTop: "-13px" }
                  : {
                      marginBottom: "-13px",
                      marginTop: "-13px",
                      display: "flex",
                      justifyContent: "space-between"
                    }
              }
            >
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <div className="float-left">
                  <h5
                    style={{
                      color: "#4b2e83",
                      width: "100%",
                      paddingLeft: 2,
                      paddingTop: 8
                    }}
                  >
                    {" "}
                    <Tooltip title="Go Back">
                      <IconButton
                        style={{
                          color: "#4b2e83",
                          opacity: "1",
                          outline: "none"
                        }}
                        aria-label="Go Back"
                        onClick={() => this.props.history.goBack()}
                      >
                        <GoBackIcon />
                      </IconButton>
                    </Tooltip>
                    Go back to previous screen
                  </h5>
                </div>
              </Grid>

              {!this.state.isSubmitting ? (
                <Grid item style={{ width: "33%" }}>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    {this.props.params === "Modify" ? (
                      <Button
                        color="primary"
                        onClick={() =>
                          this.setState({
                            openAlert: true,
                            actionType: "DELETE"
                          })
                        }
                        disabled={this.state.stateModel.CollectionId == ""}
                        style={
                          !this.state.stateModel.CollectionId == ""
                            ? {
                                color: "#4b2e83",
                                heignt: 40,
                                fontSize: 16,
                                opacity: "1",
                                outline: "none"
                              }
                            : {
                                color: "#343a40",
                                opacity: "0.4"
                              }
                        }
                      >
                        DELETE
                      </Button>
                    ) : null}
                    <Tooltip title="Reset">
                      <IconButton
                        style={
                          this.stateChange()
                            ? {
                                color: "#4b2e83",
                                opacity: "1",
                                outline: "none"
                              }
                            : { color: "#343a40", opacity: "0.4" }
                        }
                        disabled={!this.stateChange()}
                        aria-label="Reset"
                        onClick={this.handleReset}
                      >
                        <ResetIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Create">
                      <IconButton
                        style={
                          this.stateChange() && !this.state.disableFields
                            ? {
                                color: "#4b2e83",
                                opacity: "1",
                                outline: "none"
                              }
                            : { color: "#343a40", opacity: "0.4" }
                        }
                        disabled={
                          !this.stateChange() || this.state.disableFields
                        }
                        aria-label="Create"
                        onClick={() => {
                          if (this.state.stateModel.EntityId == "") {
                            this.props.showMessageBox("Please Select Organization");
                            this.setState({
                              showRedField: true
                            });
                            return;
                          }

                          if (
                            this.state.stateModel.LocationId == "" &&
                            this.props.location.search.includes("type=dept")
                          ) {
                            this.props.showMessageBox(
                              "Please Select Campus"
                            );
                            this.setState({
                              showRedField: true
                            });
                            return;
                          }
                          if (
                            this.state.stateModel.CollectionId == "" &&
                            this.props.params == "Modify"
                          ) {
                            if (
                              this.props.location.search.includes("type=dept")
                            )
                              this.props.showMessageBox(
                                "Please Select Department"
                              );
                            else
                              this.props.showMessageBox(
                                "Please Select Collection"
                              );
                              this.setState({
                                showRedField: true
                              });
                            return;
                          }
                          if (this.state.stateModel.CollectionName == "") {
                            this.props.showMessageBox(
                              "Please fill the Name field"
                            );
                            this.setState({
                              showRedField: true
                            });
                            return;
                          }
                          this.setState({
                            openAlert: true,
                            actionType: "SAVE"
                          });
                        }}
                      >
                        <SubmitIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </Grid>
              ) : (
                <Grid item xs={4}>
                  <div
                    style={{
                      width: "100%",
                      marginTop: 5,
                      display: "flex",
                      justifyContent: "flex-end"
                    }}
                  >
                    <CircularProgress className={classes.progress} />
                  </div>
                </Grid>
              )}
            </Grid>
          </div>
          <hr />
          <Grid container spacing={2}>
            {/*<Grid item xs={12} sm={6} md={4} lg={4}>
              <TextField
                width="100%"
                id="outlined-select"
                select
                style={{
                  marginTop: 5,
                  marginBottom: 8
                }}
                disabled={this.state.disableEntity || this.state.disableFields}
                
                required
                margin="dense"
                label="Organization"
                className={classes.textField}
                value={this.state.stateModel.EntityId}
                error={
                  this.state.showRedField && this.state.stateModel.EntityId == ""
                    ? true
                    : false
                }
                onChange={this.handleChange("EntityId")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                InputProps={{ className: classes.input }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.label
                }}
                variant="outlined"
              >
                {this.state.entityData.map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              
            </Grid>
                */}
            {this.props.location.search.includes("type=col") ? null : (
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <TextField
                  width="100%"
                  id="outlined-select"
                  select
                  style={{
                    marginTop: 5,
                    marginBottom: 8
                  }}
                  required
                  disabled={
                    (/*this.props.params === "Modify" &&*/
                      this.state.stateModel.EntityId === "") ||
                    this.state.disableFields
                  }
                  margin="dense"
                  label="Campus"
                  className={classes.textField}
                  error={
                    this.state.showRedField && this.state.stateModel.LocationId == ""
                      ? true
                      : false
                  }
                  value={this.state.stateModel.LocationId}
                  onChange={this.handleChange("LocationId")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  InputProps={{ className: classes.input }}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.label
                  }}
                  variant="outlined"
                >
                  {this.state.locationData.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
            {this.props.params === "Modify" ? (
              <Grid item xs={8} sm={6} md={4} lg={4}>
                <TextField
                  width="100%"
                  id="outlined-select"
                  select
                  required
                  label={
                    this.props.location.search.includes("type=col")
                      ? "Collection"
                      : "Department"
                  }
                  margin="dense"
                  style={{
                    marginTop: 5,
                    marginBottom: 8
                  }}
                  className={classes.textField}
                  error={
                    this.state.showRedField && this.state.stateModel.CollectionId == ""
                      ? true
                      : false
                  }
                  value={this.state.stateModel.CollectionId}
                  onChange={this.handleChange("CollectionId")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  disabled={
                    (/*this.props.params === "Modify" &&*/
                      this.state.stateModel.EntityId === "") ||
                      (this.state.stateModel.LocationId ==="" && this.props.location.search.includes("type=dept"))  ||
                    this.state.disableFields
                  }
                  InputProps={{ className: classes.input }}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.label
                  }}
                  variant="outlined"
                >
                  {this.state.collectionData.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            ) : null}
            
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <h5
                style={{
                  color: "#4b2e83",
                  width: "100%",
                  paddingLeft: 2,
                  paddingTop: 5
                }}
              >
                Info
              </h5>
              <hr />
              <TextField
                id="outlined-name"
                label="Name"
                error={
                  this.state.showRedField && this.state.stateModel.CollectionName == ""
                    ? true
                    : false
                }
                value={this.state.stateModel.CollectionName}
                type="text"
                style={{
                  width: "100%",
                  borderColor: "#4b2e83"
                }}
                disabled={
                  (this.props.params === "Modify" &&
                    this.state.stateModel.CollectionId === "") ||
                  this.state.disableFields
                }
                required
                onChange={this.handleChange("CollectionName")}
                margin="dense"
                variant="outlined"
                placeholder={
                  (this.props.location.search.includes("type=col")
                    ? "Collection"
                    : "Department") + " Name"
                }
                InputProps={{ className: classes.input }}
                InputLabelProps={{ shrink: true, className: classes.label }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={8} lg={8}>
              <h4
                style={{
                  color: "#4b2e83",
                  width: "100%",
                  paddingLeft: 2,
                  paddingTop: 5
                }}
              >
                {" "}
              </h4>
              <hr
                style={{
                  marginTop: 39
                }}
              />

              <TextField
                id="outlined-name"
                label="Description"
                value={this.state.stateModel.Description}
                type="text"
                style={{
                  width: "100%",
                  borderColor: "#4b2e83"
                }}
                disabled={
                  (this.props.params === "Modify" &&
                    this.state.stateModel.CollectionId === "") ||
                  this.state.disableFields
                }
                onChange={this.handleChange("Description")}
                margin="dense"
                multiline
                rows="3"
                variant="outlined"
                placeholder="Description"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
        </Paper>

        
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { entities, collections, locations } = state;
  return {
    entities,
    collections,
    locations
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getEntities: () => getEntity(dispatch),
    getLocation: entityId => getLocations(dispatch, entityId),
    getCollection: (entityId, locId) =>
      getCollections(dispatch, entityId, locId),
    resetData: () => dispatch(cleanData()),
    showMessageBox: message => dispatch(showMessage(message))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(CreateDepartment);
