import React, { Component } from "react";
import Home from "./Home.jsx";
import {PFContext} from "../components/PrimaryFilterContext";

class About extends Component {
  //static contextType = PFContext;
  constructor() {
    super();
    this.state = {};
  }

  render() {
    //const { classes } = this.props;
    //const { value } = this.state;

    return (
      <PFContext.Consumer>
        {data=> 
          <Home primaryFilter={data}/>
        }
      </PFContext.Consumer>
    );
  }
}

export default About;
