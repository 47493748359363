import { apiRequest } from "../utils/Utils";

export const STORE_ENTITIES = "STORE_ENTITIES";
export const STORE_LOCATIONS = "STORE_LOCATIONS";
export const STORE_COLLECTIONS = "STORE_COLLECTIONS";
export const STORE_DEVICE_FLAGS = "STORE_DEVICE_FLAGS";
export const STORE_ASSET_TYPES = "STORE_ASSET_TYPES";
export const STORE_ASSET_MANUFACTURERS = "STORE_ASSET_MANUFACTURERS";
export const CLEAN_DATA = "CLEAN_DATA";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const STORE_ASSETS = "STORE_ASSETS";
export const STORE_ASSET_MODELS = "STORE_ASSET_MODELS";
export const CLEAN_ASSETS = "CLEAN_ASSETS";
export const STORE_ACCESS_LEVELS = "STORE_ACCESS_LEVELS";
export const HIDE_LOADER = "HIDE_LOADER";

function storeEntities(entities) {
  return {
    type: "STORE_ENTITIES",
    entities
  };
}

function storeLocations(locations) {
  return {
    type: "STORE_LOCATIONS",
    locations
  };
}

function storeCollections(collections) {
  return {
    type: "STORE_COLLECTIONS",
    collections
  };
}

function storeAssets(assets) {
  return {
    type: "STORE_ASSETS",
    assets
  };
}

function storeAssetModels(assetmodels) {
  return {
    type: "STORE_ASSET_MODELS",
    assetmodels
  };
}

function storeAccessLevels(accessLevels) {
  return {
    type: "STORE_ACCESS_LEVELS",
    accessLevels
  };
}

function storeDeviceFlags(flags) {
  return {
    type: "STORE_DEVICE_FLAGS",
    flags
  };
}

function storeAssetTypes(assetsTypes) {
  return {
    type: "STORE_ASSET_TYPES",
    assetsTypes
  };
}

function storeAssetManufacturers(assetManufacturers) {
  return {
    type: "STORE_ASSET_MANUFACTURERS",
    assetManufacturers
  };
}

export function cleanData() {
  return {
    type: "CLEAN_DATA"
  };
}

export function showMessage(message) {
  return {
    type: "SHOW_MESSAGE",
    message
  };
}

export function clearMessage() {
  return {
    type: "CLEAR_MESSAGE"
  };
}

export function cleanAssets() {
  return {
    type: "CLEAN_ASSETS"
  };
}

export function hideDevicesLoader(hide) {
  return {
    type: "HIDE_LOADER",
    hide
  };
}

export function getEntity(dispatch) {
  dispatch(cleanData());

  apiRequest(`/entities`, "get")
    .then(response => {
      if (response.status > 0) {
        const entityData = response.data.map(c => {
          return {
            id: c.EntityId,
            name: c.Name,
            short_name: c.ShortName
          };
        });
        dispatch(storeEntities(entityData));
      }
    })
    .catch(error => console.log(error));
}

export function getLocations(dispatch, entityId) {
  apiRequest(`/entities/${entityId}/locations`, "get")
    .then(response => {
      if (response.status > 0) {
        const locationData = response.data.map(c => {
          return {
            id: c.LocationId,
            name: c.LocationName,
            entityId: c.EntityId
          };
        });
        dispatch(storeLocations(locationData));
      }
    })
    .catch(error => console.log(error));
}

export function getCollections(dispatch, entityId, locationId = "") {
  const url =
    locationId === ""
      ? `/entities/${entityId}/collections`
      : `/entities/${entityId}/locations/${locationId}/collections`;

  apiRequest(url, "get")
    .then(response => {
      if (response.status > 0) {
        const collectionData = response.data.map(c => {
          return {
            id: c.CollectionId,
            name: c.CollectionName,
            location: c.LocationName,
            locationId: c.LocationId,
            entityId: c.EntityId,
            collectionType: c.CollectionType
          };
        });
        dispatch(storeCollections(collectionData));
      }
    })
    .catch(error => console.log(error));
}

export function getDeviceFlags(dispatch) {
  apiRequest("/masters/GetDeviceFlags", "get")
    .then(response => {
      if (response.status > 0) {
        const flagsData = response.data.map(c => {
          return {
            id: c.ID,
            name: c.Value.toUpperCase()
          };
        });
        dispatch(storeDeviceFlags(flagsData));
      }
    })
    .catch(error => console.log(error));
}

export function getAssetManufacturer(dispatch) {
  apiRequest("/masters/manufactures", "get")
    .then(response => {
      if (response.status > 0) {
        const assetManufacturer = response.data.map(c => {
          return {
            id: c.ID,
            name: c.Value
          };
        });
        dispatch(storeAssetManufacturers(assetManufacturer));
      }
    })
    .catch(error => console.log(error));
}

export function getAssetType(dispatch) {
  apiRequest("/masters/assettypes", "get")
    .then(response => {
      if (response.status > 0) {
        const assetType = response.data.map(c => {
          return {
            id: c.ID,
            name: c.Value
          };
        });
        dispatch(storeAssetTypes(assetType));
      }
    })
    .catch(error => console.log(error));
}

window.y = 0;
export function getAssets(dispatch, id = "") {
  dispatch(cleanAssets());
  dispatch(hideDevicesLoader(false));
  let url = `/assets/search?${id}`;
  window.z = window.y;

  apiRequest(url, "get")
    .then(response => {
      dispatch(hideDevicesLoader(true));
      window.y++;
      dispatch(storeAssets(response.data));
      window.z = window.y;
    })
    .catch(error => console.log(error));
}

export function getAssetModel(dispatch) {
  apiRequest("/masters/assetmodels", "get")
    .then(response => {
      if (response.status > 0) {
        const assetModel = response.data.map(c => {
          return {
            id: c.ID,
            name: c.Value
          };
        });
        dispatch(storeAssetModels(assetModel));
      }
    })
    .catch(error => console.log(error));
}

export function getAccessLevels(dispatch) {
  apiRequest("/masters/permissiontypes", "get")
    .then(response => {
      if (response.status > 0) {
        const acessLevels = response.data
          .filter(c => c.ID != 700)
          .map(c => {
            return {
              id: c.ID,
              name: c.Value
            };
          });
        dispatch(storeAccessLevels(acessLevels));
      }
    })
    .catch(error => console.log(error));
}
