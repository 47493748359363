import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import ResetIcon from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { showMessage } from "../actions/index";
import { compose } from "recompose";
import { connect } from "react-redux";
import { apiRequest, byName, getMaxDepartmentAccess, isPortalAdmin, getMaxEntityAccess, getMaxLocationAccess } from "../utils/Utils";
import AlertDialog from "../components/AlertDialog";
import TableForMetadata from "../components/TableForMetadata.jsx";
import { Link } from "react-router-dom";

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
    paddingTop: 10,
    paddingBottom: 10
  },
  input: {
    width: "85%",
    padding: "3px",
    borderRadius: "5px",
    outline: "none",
    border: "1px solid lightgrey"
  },
  textField: { width: "100%" },
  menu: {
    width: "100%"
  }
});

class MasterDataManagement extends Component {
  newManufacture = true;
  newModel = true;
  newType = true;
  state = {
    manufacturer: {
      id: "",
      name: ""
    },
    model: {
      id: "",
      name: ""
    },
    type: {
      id: "",
      name: ""
    },
    default: {
      id: "",
      name: ""
    },
    open: false,
    rename_type: "",
    rename_value: "",
    manufacturerData: [],
    modelData: [],
    typeData: [],
    openAlert: false,
    actionType: "",
    name: "",
    manufacturermodels: [],
    disableButton: false
  };

  componentWillMount() {
    this.fetchData("manufactures");
    this.fetchData("assetmodels");
    this.fetchData("assettypes");

    this.fetchData("manufacturermodels");
    this.checkAccess();
  }

  checkAccessOld() {
    let resourceType = "entity";
    let resourceId = 1;
    apiRequest(
      `/accesscontrol?resourceType=${resourceType}&resourceId=${resourceId}`,
      "get"
    )
      .then(response => {
        if (response.data[0] == 300) {
          this.setState({
            disableButton: true
          });
        }
        if (response.data[0] <= 100) {
          this.setState({
            disableButton: true
          });
        }
      })
      .catch(error => console.log("error", error));
  }

  checkAccess() {
    if(isPortalAdmin) {
      //ok
    } 
    else if (getMaxEntityAccess() < 500 && getMaxLocationAccess() < 500 && getMaxDepartmentAccess() < 500) {
      this.setState({disableButton: true});
    }
  }

  fetchData(dataType, id = "") {
    const That = this;
    let url =
      dataType === "assetmodels"
        ? `/masters/${dataType}/${id}`
        : `/masters/${dataType}`;

    //console.log("apiRequest url:", url);

    apiRequest(url, "get")
      .then(response => {

        const data = response.data.map(c => {
          return {
            id: c.ID,
            name: c.Value
          };
        });

        console.log(`response to '${dataType}'`, response);

        switch (dataType) {
          case "manufactures":

            //console.log("resp data", data.sort(byName));
            this.setState(
              {
                manufacturerData: data.sort(byName)
              },
              () => {
                let id = this.state.manufacturer.id;
                if (id !== "") this.fetchData("assetmodels", id);
              }
            );
            break;

          case "assetmodels":

            //console.log("resp assetmodel modelData", data.sort(byName));
            this.setState({
              modelData: data.sort(byName)
            });
            break;

          case "assettypes":

            //console.log("resp assettype typeData", data.sort(byName));
            this.setState({
              typeData: data.sort(byName)
            });
            break;

          case "manufacturermodels":

            console.log("resp manufacturermodels manufacturermodels", response.data);
            this.setState({manufacturermodels: response.data})
            break;

        }
      })
      .catch(error => console.log(error));
  }

  add = () => {
    this.setState({
      openAlert: false
    });
    const that = this;
    const name = this.state.name;
    if (name === "manufacturer") {
      const Manufacturer = this.state.manufacturer.name;

      apiRequest(`/masters/addmanufactures?name=${Manufacturer}`, "post")
        .then(x => {
          if (x.status > 0) that.fetchData("manufactures");
          if (x.status > 0 && x.message == null)
            that.props.showMessageBox("SUCCESS");
          else that.props.showMessageBox(x.message);
        })
        .catch(err => {
          that.props.showMessageBox("ERROR");
        });
    } else if (name === "model") {
      const DeviceModel = this.state.model.name;
      
      console.log("TODO", `/masters/addassetmodels?manfactureId=${
        that.state.manufacturer.id
      }&model=${DeviceModel}`);
      
      apiRequest(
        `/masters/addassetmodels?manfactureId=${
          that.state.manufacturer.id
        }&model=${DeviceModel}`,
        "post"
      )
        .then(x => {
          if (x.status > 0)
            that.fetchData("assetmodels", that.state.manufacturer.id);
          if (x.status > 0 && x.message == null)
            that.props.showMessageBox("SUCCESS");
          else that.props.showMessageBox(x.message);
        })
        .catch(err => {
          that.props.showMessageBox("ERROR");
        });
    } else if (name === "type") {
      const DeviceType = this.state.type.name;

      apiRequest(`/masters/addassettypes?name=${DeviceType}`, "post")
        .then(x => {
          if (x.status > 0) that.fetchData("assettypes");
          if (x.status > 0 && x.message == null)
            that.props.showMessageBox("SUCCESS");
          else that.props.showMessageBox(x.message);
        })
        .catch(err => {
          that.props.showMessageBox("ERROR");
        });
    }
    setTimeout(() => {
      this.setState({
        actionType: ""
      });
    }, 1000);
  };

  rename = () => {
    this.setState({
      openAlert: false
    });
    const that = this;
    const { rename_type, rename_value } = this.state;
    if (rename_type === "manufacturer") {
      const old_data = this.state.manufacturer;
      this.handleRenameRequest("manufactures", old_data);
      this.newManufacture = true;
    } else if (rename_type === "model") {
      const old_data = this.state.model;
      this.handleRenameRequest("assetmodels", old_data);
      this.newModel = true;
    } else if (rename_type === "type") {
      const old_data = this.state.type;
      this.handleRenameRequest("assettypes", old_data);
      this.newType = true;
    }
    this.setState({
      actionType: "",
      rename_value: "",
      [rename_type]: this.state.default
    });
    this.handleClose();
    let a = document.querySelectorAll("input");
    a.forEach(element => {
      element.value = "";
    });
  };

  handleRenameRequest(name, data) {
    const { rename_value } = this.state;
    const that = this;

    apiRequest(`/masters/${name}/${data.id}?name=${rename_value}`, "put")
      .then(x => {
        if (x.status > 0) that.fetchData(name);
        if (x.status > 0 && x.message == null)
          that.props.showMessageBox("SUCCESS");
        else that.props.showMessageBox(x.message);
      })
      .catch(err => {
        that.props.showMessageBox("ERROR");
      });
  }

  delete = () => {
    this.setState(
      {
        openAlert: false
      },
      () => {
        this.setState({
          actionType: ""
        });
      }
    );
    const { name } = this.state;
    const that = this;
    if (name === "manufacturer") {
      const new_data = this.state.manufacturer;
      this.handleDeleteRequest("manufactures", new_data);
      this.newManufacture = true;
    } else if (name === "model") {
      const new_data = this.state.model;
      this.handleDeleteRequest("assetmodels", new_data);
      this.newModel = true;
    } else if (name === "type") {
      const new_data = this.state.type;
      this.handleDeleteRequest("assettypes", new_data);
      this.newType = true;
    }
    let a = document.querySelectorAll("input");
    a.forEach(element => {
      element.value = "";
    });
  };

  handleDeleteRequest(name, data) {
    const that = this;

    apiRequest(`/masters/${name}/${data.id}`, "delete")
      .then(x => {
        if (x.status > 0) that.fetchData(name);
        if (x.status > 0 && x.message == null)
          that.props.showMessageBox("SUCCESS");
        else that.props.showMessageBox(x.message);
      })
      .catch(err => {
        that.props.showMessageBox("ERROR");
      });
  }

  inputChange = name => e => {
    let data = [];
    if (name === "manufacturer") data = this.state.manufacturerData;
    else if (name === "model") data = this.state.modelData;
    else if (name === "type") data = this.state.typeData;
    if(data.length === 0) {
      if (name === "manufacturer") this.newManufacture = true;
      else if (name === "model") this.newModel = true;
      else if (name === "type") this.newType = true;
      this.setState({
        [name]: { name: e.target.value, id: data.length }
      });
    }
    else {
      for (var element of data) {
        if (element.name == e.target.value) {
          if (name === "manufacturer") this.newManufacture = false;
          else if (name === "model") this.newModel = false;
          else if (name === "type") this.newType = false;
          this.setState(
            {
              [name]: { name: element.name, id: element.id }
            },
            () => {
              if (name === "manufacturer") {
                let id = this.state.manufacturer.id;
                if (id === "") {
                  this.setState({
                    modelData: [],
                    model: { ...this.state.default }
                  });
                } else {
                  this.setState({
                    model: { ...this.state.default }
                  });
                  this.fetchData("assetmodels", id);
                }
              }
            }
          );
          break;
        } else {
          if (name === "manufacturer") this.newManufacture = true;
          else if (name === "model") this.newModel = true;
          else if (name === "type") this.newType = true;
          this.setState({
            [name]: { name: e.target.value, id: data.length }
          });
        }
      }
    }

  };

  handleClickOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleReset = (index, name) => {
    if (name === "manufacturer") this.newManufacture = true;
    else if (name === "model") this.newModel = true;
    else if (name === "type") this.newType = true;

    this.setState(
      {
        [name]: { ...this.state.default }
      },
      () => {
        if (name === "manufacturer") {
          this.setState({
            modelData: []
          });
          //this.handleReset(1,"model");
          let a = document.querySelectorAll("input");
          a[index + 1].value = "";
        }
      }
    );

    let a = document.querySelectorAll("input");
    a[index].value = "";
  };

  render() {
    const { classes } = this.props;
    const { manufacturerData, modelData, typeData, manufacturermodels } = this.state;
    return (
      <div
        id="content"
        style={{
          //paddingTop: "60px",
          transition: "0.25s",
          paddingLeft: "0px",
        }}
      >
        <AlertDialog
          open={this.state.openAlert}
          onClickYes={
            this.state.actionType == "ADD"
              ? this.add
              : this.state.actionType == "RENAME"
              ? this.rename
              : this.delete
          }
          action={
            this.state.actionType == "ADD"
              ? "Save"
              : this.state.actionType == "RENAME"
              ? "Rename"
              : "Delete"
          }
          subText={
            this.state.actionType == "ADD"
              ? ""
              : `This action will affect all the existing devices having this ${
                  this.state.actionType == "RENAME"
                    ? this.state.rename_type
                    : this.state.name
                } name`
          }
          close={() => {
            this.setState({ openAlert: false });
          }}
        />
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={this.state.open}
          onClose={() => {
            this.handleClose();
          }}
        >
          <DialogTitle>Rename</DialogTitle>
          <DialogContent>
            <TextField
              id="standard-uncontrolled"
              label="Rename"
              className={classes.textField}
              margin="dense"
              onChange={(e) => {
                this.setState({ rename_value: e.target.value });
              }}
              value={this.state.rename_value}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.handleClose();
              }}
              color="primary"
              style={{
                color: "#4b2e83",
                margin: 10,
                height: 45,
              }}
              className="button1"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                this.setState({
                  actionType: "RENAME",
                  openAlert: true,
                });
              }}
              color="primary"
              style={{
                color: "#4b2e83",
                margin: 10,
                height: 45,
              }}
              className="button1"
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>


        <Paper
          style={{
            padding: "18px 30px",
            margin: "15px 30px",
            display: "none"
          }}
        >
          <Grid
            container
            spacing={2}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid item xs={12} md={2} lg={2}>
              <h5>Manufacturer</h5>
            </Grid>
            <Grid item xs={12} md={5} lg={5}>
              <input
                list="text_editors1"
                className={classes.input}
                onChange={this.inputChange("manufacturer")}
              />
              <datalist id="text_editors1">
                <select multiple>
                  {manufacturerData.map((user) => (
                    <option key={user.id} value={user.name}>
                      {user.name}
                    </option>
                  ))}
                </select>
              </datalist>
              <Tooltip title="Reset">
                <IconButton
                  style={{ color: "#4b2e83", opacity: "1", outline: "none" }}
                  aria-label="Reset"
                  onClick={() => {
                    this.handleReset(0, "manufacturer");
                  }}
                >
                  <ResetIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={5} lg={5}>
              <Button
                variant="contained"
                color="primary"
                style={{
                  margin: "0px 7px",
                  backgroundColor: "white",
                  color: "#4b2e83",
                  outline: "none",
                }}
                className="button1"
                disabled={!this.newManufacture}
                onClick={() => {
                  if (this.state.manufacturer.name == "") {
                    this.props.showMessageBox("Field is empty");
                    return;
                  }
                  this.setState({
                    actionType: "ADD",
                    name: "manufacturer",
                    openAlert: true,
                  });
                }}
              >
                Add
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{
                  margin: "0px 7px",
                  backgroundColor: "white",
                  color: "#4b2e83",
                  outline: "none",
                }}
                className="button1"
                disabled={
                  this.newManufacture || this.state.manufacturer.name === ""
                }
                onClick={() => {
                  this.setState({
                    rename_type: "manufacturer",
                  });
                  this.handleClickOpen();
                }}
              >
                Rename
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{
                  margin: "0px 7px",
                  backgroundColor: "white",
                  color: "#4b2e83",
                  outline: "none",
                }}
                className="button1"
                disabled={
                  this.newManufacture || this.state.manufacturer.name === ""
                }
                onClick={() => {
                  this.setState({
                    actionType: "DELETE",
                    name: "manufacturer",
                    openAlert: true,
                  });
                }}
              >
                Delete
              </Button>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 10,
              marginTop: 10,
            }}
          >
            <Grid item xs={12} md={2} lg={2}>
              <h5>Model</h5>
            </Grid>
            <Grid item xs={12} md={5} lg={5}>
              <input
                list="text_editors2"
                className={classes.input}
                onChange={this.inputChange("model")}
              />
              <datalist id="text_editors2">
                <select multiple>
                  {modelData.map((user) => (
                    <option key={user.id} value={user.name}>
                      {user.name}
                    </option>
                  ))}
                </select>
              </datalist>
              <Tooltip title="Reset">
                <IconButton
                  style={{ color: "#4b2e83", opacity: "1", outline: "none" }}
                  aria-label="Reset"
                  onClick={() => {
                    this.handleReset(1, "model");
                  }}
                >
                  <ResetIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={5} lg={5}>
              <Button
                variant="contained"
                color="primary"
                style={{
                  margin: "0px 7px",
                  backgroundColor: "white",
                  color: "#4b2e83",
                  outline: "none",
                }}
                onClick={() => {
                  if (this.state.manufacturer.name == "") {
                    this.props.showMessageBox(
                      "Please first select a Manufacturer to add Model"
                    );
                    return;
                  }
                  if (this.state.model.name == "") {
                    this.props.showMessageBox("Field is empty");
                    return;
                  }
                  this.setState({
                    actionType: "ADD",
                    name: "model",
                    openAlert: true,
                  });
                }}
                className="button1"
                disabled={!this.newModel}
              >
                Add
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{
                  margin: "0px 7px",
                  backgroundColor: "white",
                  color: "#4b2e83",
                  outline: "none",
                }}
                onClick={() => {
                  this.setState({
                    rename_type: "model",
                  });
                  this.handleClickOpen();
                }}
                className="button1"
                disabled={this.newModel || this.state.model.name === ""}
              >
                Rename
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{
                  margin: "0px 7px",
                  backgroundColor: "white",
                  color: "#4b2e83",
                  outline: "none",
                }}
                onClick={() => {
                  this.setState({
                    actionType: "DELETE",
                    name: "model",
                    openAlert: true,
                  });
                }}
                className="button1"
                disabled={this.newModel || this.state.model.name === ""}
              >
                Delete
              </Button>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid item xs={12} md={2} lg={2}>
              <h5>Type</h5>
            </Grid>
            <Grid item xs={12} md={5} lg={5}>
              <input
                list="text_editors3"
                className={classes.input}
                onChange={this.inputChange("type")}
              />
              <datalist id="text_editors3">
                <select multiple>
                  {typeData.map((user) => (
                    <option key={user.id} value={user.name}>
                      {user.name}
                    </option>
                  ))}
                </select>
              </datalist>
              <Tooltip title="Reset">
                <IconButton
                  style={{ color: "#4b2e83", opacity: "1", outline: "none" }}
                  aria-label="Reset"
                  onClick={() => {
                    this.handleReset(2, "type");
                  }}
                >
                  <ResetIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={5} lg={5}>
              <Button
                variant="contained"
                color="primary"
                style={{
                  margin: "0px 7px",
                  backgroundColor: "white",
                  color: "#4b2e83",
                  outline: "none",
                }}
                className="button1"
                disabled={!this.newType}
                onClick={() => {
                  if (
                    this.state.type.name == "" ||
                    this.state.type.name == null
                  ) {
                    this.props.showMessageBox("Field is empty");
                    return;
                  }
                  this.setState({
                    actionType: "ADD",
                    name: "type",
                    openAlert: true,
                  });
                }}
              >
                Add
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{
                  margin: "0px 7px",
                  borderColor: "#4b2e83",
                  backgroundColor: "white",
                  color: "#4b2e83",
                  outline: "none",
                }}
                className="button1"
                disabled={this.newType || this.state.type.name === ""}
                onClick={() => {
                  this.setState({
                    rename_type: "type",
                  });
                  this.handleClickOpen();
                }}
              >
                Rename
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{
                  margin: "0px 7px",
                  backgroundColor: "white",
                  color: "#4b2e83",
                  outline: "none",
                }}
                className="button1"
                disabled={this.newType || this.state.type.name === ""}
                onClick={() => {
                  this.setState({
                    actionType: "DELETE",
                    name: "type",
                    openAlert: true,
                  });
                }}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <Grid
          container
          spacing={4}
          style={{
            paddingLeft: "24px",
            //paddingRight: "24px",
            marginTop: 6,
            width: "100%"
          }}
        >
          <Grid item xs={12} md={7} lg={7} style={{paddingTop: 0}}>
            <div style={{display: "flex", "justify-content": "space-between", "align-items": "center"}}>
                <h5 style={{ color: "#4b2e83" }}>Manufacturer & Model</h5>
                <Link
                  style={{
                      "text-decoration": "none"
                  }}
                  to={"manage/manufacturer/0/model/0"}
                  hidden={this.state.disableButton}
                  disabled={this.state.disableButton}
                >
                <Button
                      variant="outlined"
                      color="primary"
                      style={{
                        color: "#4b2e83",
                        marginBottom: 10,
                        height: 45,
                        "text-decoration": "none"
                      }}
                      className="button1"
                      //size="small"
                      disabled={this.state.disableButton}
                      hidden={this.state.disableButton}
                    >
                      New
                    </Button>
                    </Link>
            </div>
            <TableForMetadata
              data={manufacturermodels}
              header={[
                { id: "ManufacturerName", name: "Manufacturer Name" },
                { id: "ModelName", name: "Model Name" },
              ]}
              tableName={"Building"}
              entityId={1}
              type={"entity"}
              locationId=""
              //singlePagePath="manage/manufacturermodel"
              editable={false}
              history={this.props.history}
            />
          </Grid>

          <Grid item xs={12} md={5} lg={5} style={{paddingTop: 0}}>
            <div style={{display: "flex", "justify-content": "space-between", "align-items": "center"}}>
                <h5 style={{ color: "#4b2e83" }}>Type</h5>
                <Link
                  style={{
                      "text-decoration": "none"
                  }}
                  to={"manage/type/0"}
                  hidden={this.state.disableButton}
                  disabled={this.state.disableButton}
                >
                <Button
                      variant="outlined"
                      color="primary"
                      style={{
                        color: "#4b2e83",
                        marginBottom: 10,
                        height: 45,
                        "text-decoration": "none"
                      }}
                      className="button1"
                      //size="small"
                      disabled={this.state.disableButton}
                      hidden={this.state.disableButton}
                    >
                      New
                    </Button>
                    </Link>
            </div>
            <TableForMetadata
              data={typeData}
              header={[{ id: "name", name: "Type Name" }]}
              tableName={"Department"}
              entityId={1}
              type={"entity"}
              locationId=""
              singlePagePath="manage/type"
              editable={false}
              history={this.props.history}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    showMessageBox: message => dispatch(showMessage(message))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(MasterDataManagement);
