import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { Button } from "@material-ui/core";
import { getLocations, getCollections, showMessage } from "../actions/index";
import { connect } from "react-redux";
import { compose } from "recompose";
import CircularProgress from "@material-ui/core/CircularProgress";
import { apiRequest } from "../utils/Utils";
import AlertDialog from "./AlertDialog";

const cellStyle = theme => ({
  root: {
    //padding: 0,
    //width: "20%"
    "word-break": "break-all",
    //cursor: (props)=> props.pointer ? "pointer" : ""
  },
});
const TableCellS = withStyles(cellStyle)(TableCell);
//const TableCellS = withStyles(cellStyle)((props) => <TableCell {...props} />);


const styles = theme => ({
  root: {
    width: "100%",
    overflowX: "auto"
  },
  table: {
    minWidth: 300
  },
  button: {
    margin: "0px",
    padding: "0px",
    color: "#4b2e83",
    border: "1px solid #4b2e83",

    "&:hover": {
      color: "#4b2e83",
      border: "1px solid #4b2e83",
    }
  },
  icons: {
    outline: "none !important",
    margin: "-10px -1px"
  },
  progress: {
    color: "#4b2e83"
  },
  noPadding: {
    padding: 0,
    background: "red"
  }
});

class TableForMetadata extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      header: props.header,
      tableName: props.tableName,
      page: 0,
      rowsPerPage: 20,
      showLoader: false,
      disableButton: false,
      disableAllButtons: !props.editable,
      access: props.access,
      openAlert: false,
      rowToDelete: {}
    };
  }
  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    if(event.target.value * this.state.page > this.props.data.length && this.state.page != 0)
      this.setState({ rowsPerPage: event.target.value, page: 0 });
    else
      this.setState({ rowsPerPage: event.target.value });
  };

  handleDelete = () => {
    const item = this.state.rowToDelete;
    const That = this;
    this.setState({
      showLoader: true,
      openAlert: false
    });
    let url = `/entities/${item.entityId}/locations/`;
    if (this.state.tableName == "Department") {
      url = `/collections/${item.id}`;
    } else {
      url += `${item.id}`;
    }
    const THAT = this;

    apiRequest(url, "delete")
      .then(x => {
        if (x.status > 0) {
          if (THAT.state.tableName === "Department") {
            if (item.locationId != "" && item.locationId != null)
              THAT.props.getCollection(item.entityId, item.locationId);
            else THAT.props.getCollection(item.entityId, 0);
          }
          if (THAT.state.tableName === "Building")
            THAT.props.getLocation(item.entityId);
        }
        setTimeout(() => {
          THAT.setState({
            showLoader: false
          });
        }, 500);

        if (x.status > 0 && x.message == null)
          THAT.props.showMessageBox("SUCCESS");
        else THAT.props.showMessageBox(x.message);
      })
      .catch(err => {
        THAT.props.showMessageBox("ERROR");
        THAT.setState({
          showLoader: false
        });
      });
  };

  componentWillMount() {
    this.checkAccess();
  }

  componentWillReceiveProps(newProps) {
    this.setState(
      {
        data: newProps.data,
        access: newProps.access
      },
      () => {
        this.checkAccess();
      }
    );
  }

  checkAccess() {
    const { entityId, locationId = "" } = this.props;
    let resourceType = "";
    let resourceId = "";
    if (locationId == "") {
      resourceType = "entity";
      resourceId = entityId;
    } else {
      resourceType = "location";
      resourceId = locationId;
    }
    apiRequest(
      `/accesscontrol?resourceType=${resourceType}&resourceId=${resourceId}`,
      "get"
    )
      .then(response => {
        if (response.data[0] == 300) {
          this.setState({
            disableButton: true
          });
        }
        if (response.data[0] <= 100) {
          this.setState({
            disableAllButtons: true,
            disableButton: true
          });
        }
      })
      .catch(error => console.log("error", error));
  }

  render() {
    const { classes } = this.props;
    const { data, header, rowsPerPage, page } = this.state;
    return (
      <Paper className={classes.root}>
        <AlertDialog
          open={this.state.openAlert}
          onClickYes={this.handleDelete}
          action={"Delete"}
          close={() => {
            this.setState({ openAlert: false });
          }}
        />
        <Table className={classes.table}>
          <TableHead>
            <TableRow style={{ height: 40 }}>
            {header.map(item => (
                <TableCellS key={item.id} /*align="right"*/ /*style={{ width: "33%" }}*/>
                  {item.name}
                </TableCellS>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => (
                <TableRow style={{ height: 40, cursor: "pointer" }} key={row.id} 
                onClick={()=> {
                  let path = this.props.singlePagePath ? `${this.props.singlePagePath}/${row.id}` : `manage/manufacturer/${row.ManufacturerId}/model/${row.ModelId}`;
                  if(this.props.history)
                    //this.props.history.push(`manage/manufacturer/${row.ManufacturerId}/model/${row.ModelId}`)
                    this.props.history.push(path);
                    }}>
             
                  {header.map(item => (
                    <TableCellS /*align="right"*/ /*style={{whiteSpace: "nowrap"}}*/ >
                      {row[item.id]}
                    </TableCellS>
                    
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={false}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

const mapStateToProps = state => {
  const { entities } = state;
  return {
    entities
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getLocation: entityId => getLocations(dispatch, entityId),
    getCollection: (entityId, locId) =>
      getCollections(dispatch, entityId, locId),
    showMessageBox: message => dispatch(showMessage(message))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(TableForMetadata);
