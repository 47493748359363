import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";


export default class PopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open
    };
  }

  componentWillReceiveProps({ open }) {
    this.setState({
      open
    });
  }

  render() {
    return (
      <div>
        <Dialog
          //style={{width: "320px"}}
          open={this.state.open}
          onClose={this.props.handleClose}
          //onClose={this.props.close}
          //onBackdropClick={this.props.close}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{ width: 350 }}
          >
            {this.props.title}
          </DialogTitle>
          <DialogContent style={{padding: "24px 24px"}}>
            {this.props.children}
          </DialogContent>
          <DialogActions style={{padding: "8px 24px"}}>
          <Button
            variant="outlined"
            onClick={this.props.handleClose}
            style={{color: "#4b2e83"}}>
            Cancel
          </Button>
          <Button
            disabled={this.props.assignDisabled}
            variant="outlined"
            onClick={this.props.handleAssign}
            style={{ height: 38.5}}
            className="button1"
            >
              
            Assign
          </Button>
        </DialogActions>
        </Dialog>
      </div>
    );
  }
}
