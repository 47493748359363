import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import CreateEntity from "../components/CreateEntity.jsx";
import TableForOption from "../components/TableForOption.jsx";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import AssignOrRemoveUsers from "../components/AssignOrRemoveUsers";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import {PFContext} from "../components/PrimaryFilterContext";
import {  byName } from "../utils/Utils";
const LocationHeader = [
  { id: 0, name: "Campus Id" },
  { id: 1, name: "Campus Name" }
];
const DepartmentHeader = [
  { id: 0, name: "Department Id" },
  { id: 1, name: "Department Name" },
  { id: 2, name: "Campus" }
];

class Settings extends Component {
  //static contextType = PFContext;
  constructor() {
    super();
  }

  state = {
    showTables: false,
    collections: [],
    locations: [],
    entityId: "",
    locationId: "",
    collectionId: ""
  };

  showTable = (arg, entityId = "", locationId = "", collectionId = "") => {
    this.setState({ showTables: arg, entityId, locationId, collectionId });
  };

  componentWillReceiveProps(props) {
    const { locations, collections } = props;
    this.setState({ locations: locations.sort(byName), collections: collections.sort(byName) });
  }

  render() {
    const { entityId, locationId, collectionId } = this.state;
    return (
      <div
        id="content"
        style={{
          //paddingTop: "60px",
          transition: "0.25s",
          paddingLeft: "0px"
        }}
      >
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-lg-12"
              style={{
                height: "100%",
                width: "100%"
              }}
            >
              <PFContext.Consumer>
                  {data => 
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <CreateEntity
                      params={this.props.match.params.function}
                      showTables={this.showTable}
                      history={this.props.history}
                      id={data.entity}
                    />
                  </Grid>
                </Grid>

                {this.state.showTables &&
                this.props.match.params.function === "Modify" ? (
                  <Grid
                    container
                    spacing={2}
                    style={{
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      marginTop: 6
                    }}
                  >
                    <Grid item xs={12} md={5} lg={5}>
                      <h5 style={{ color: "#4b2e83" }}>Campus</h5>
                      <TableForOption
                        data={this.state.locations}
                        header={LocationHeader}
                        tableName={"Campus"}
                        entityId={data.entity}
                        rowsperpage={5}
                        type={"entity"}
                        locationId={locationId}
                      />
                    </Grid>

                    <Grid item xs={12} md={7} lg={7}>
                      <h5 style={{ color: "#4b2e83" }}>Departments</h5>
                      <TableForOption
                        data={this.state.collections}
                        header={DepartmentHeader}
                        tableName={"Department"}
                        entityId={data.entity}
                        rowsperpage={5}
                        type={"entity"}
                        locationId={locationId}
                      />
                    </Grid>
                  </Grid>
                ) : null}

                {this.state.showTables ? (
                  <AssignOrRemoveUsers
                    type={"entity"}
                    entityId={data.entity}
                    locationId={locationId}
                    collectionId={collectionId}
                  />
                ) : null}
              </div>
  }
  </PFContext.Consumer>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { locations, collections } = state;
  return {
    locations,
    collections
  };
};

export default connect(mapStateToProps)(Settings);
