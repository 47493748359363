import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import "./ManageUsers.css";
import TableMapped from "./TableMappedUsers.jsx";
import TableUnMapped from "./TableUnmappedUsers.jsx";
import ResetIcon from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { Divider } from "@material-ui/core";
import {
  getEntity,
  getLocations,
  getCollections,
  cleanData,
  showMessage
} from "../actions/index";
import { compose } from "recompose";
import { connect } from "react-redux";
import Collapse from "@material-ui/core/Collapse";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getMaxEntityAccess, apiRequest, byName } from "../utils/Utils";

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
    paddingTop: 10,
    paddingBottom: 10
  },
  input: {
    height: 38
  },
  label: {
    //marginTop: "-5px"
  },
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,

    flexGrow: 1
  },
  root2: {
    ...theme.mixins.gutters(),
    paddingBottom: theme.spacing.unit * 1
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  textField: { width: "100%" },
  dense: {
    marginTop: 16
  },
  menu: {
    width: 200
  }
});

class ManageDevices extends Component {
  state = {
    entity: sessionStorage.getItem("entityID") ? sessionStorage.getItem("entityID") : "" ,
    dept: "",
    loc: "",
    flag: "",
    type: "",
    search: "",
    phone: "",
    username: "",
    fname: "",
    showFilters: false,
    showMappingTable: false,
    showAlreadyassgined: false,
    showUnassigned: false,
    loadingMapped: false,
    loadingunMapped: false,
    selectedRows: "0",
    entityData: [],
    collectionData: [],
    locationData: [],
    users: [],
    MappedUserData: [],
    disableSubmit: true
  };
  componentDidMount() {
    if(this.state.entity && this.state.entity !=="")
    {
      this.props.getLocation(this.state.entity);
    }
  }

  handleChange = name => event => {
    this.setState(
      {
        [name]: event.target.value
      },
      () => {
        if (name === "entity") {
          sessionStorage.setItem("entityID", event.target.value);
          this.props.getLocation(event.target.value);
          this.props.getCollection(event.target.value);
        } else if (name === "loc") {
          this.props.getCollection(this.state.entity, event.target.value);
        } else if (name === "dept") {
          this.setState({
            disableSubmit: false
          });
        }
      }
    );
  };

  handleSearch = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleNext = () => {
    if (this.state.entity == "" || this.state.dept == "") {
    } else {
      this.fetchUnMappedUsers();
      this.fetchMappedUsers();
      this.setState({
        showAlreadyassgined: true,
        showUnassigned: true,
        showFilters: true
      });
    }
  };

  handleReset = () => {
    this.setState({
      showFilters: false,
      showMappingTable: false,
      //entity: "",
      dept: "",
      loc: "",
      showAlreadyassgined: false,
      showUnassigned: false,
      disableSubmit: true
    });
    //this.props.resetData();
  };

  handleSelected = (value, accessLevel, idx) => {
    const that = this;
    const { entity, dept, loc } = this.state;
    const data = {
      Name: value[idx].FullName,
      AccessLevel: accessLevel,
      EntityId: entity,
      LocationId: loc,
      CollectionId: dept,
      CreatedBy: localStorage.getItem("user"),
      UserName: value[idx].UserId
    };

    apiRequest("/accesscontrol", "post", data)
      .then(x => {
        if (x.status > 0) that.fetchMappedUsers();
        if (x.status > 0 && x.message == null)
        {
          that.props.showMessageBox("SUCCESS");
          if(value.length > ++idx)
          {
            this.handleSelected(value, accessLevel, idx);
          }
        }
        else that.props.showMessageBox(x.message);
      })
      .catch(err => {
        that.props.showMessageBox("ERROR");
      });
  };

  componentWillMount() {
    this.props.storeEntity();
  }

  componentWillReceiveProps(props) {
    const { entities, locations, collections, values } = props;
    if(values)
    {
      if(values.entity !== this.state.entity ) {
        this.setState({entity: values.entity},()=> {
          if(this.state.entity && this.state.entity !=="")
          {
            this.handleReset();
            this.props.getLocation(this.state.entity);
          }
          else {
            this.handleReset();
            this.props.resetData();
          }

        });
      }
    }
    this.setState({
      entityData: entities,
      collectionData: collections.sort(byName),
      locationData: locations.sort(byName)
    });
  }

  fetchUnMappedUsers() {
    apiRequest(`/users?is_active=true`, "get").then(x => {
      this.setState({
        users: x.data,
        loadingunMapped: true
      });
    });
  }

  fetchMappedUsers = () => {
    const { dept } = this.state;
    let url = `/collections/${dept}/members`;

    apiRequest(url, "get")
      .then(x => {
        this.setState({
          MappedUserData: x.data,
          loadingMapped: true
        });
      })
      .catch(err => console.log(err));
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div>
          <Paper
            className={classes.root}
            style={{
              padding: "2px 10px 2px 10px",
              width: "100%",
              height: "100%",
              flexDirection: "column",
              display: "flex",
              justifyContent: "center"
            }}
          >
            <div
              style={{ paddingBottom: "5px", paddingTop: "5px", width: "100%" }}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={1}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <h4
                    style={{
                      color: "#4b2e83",
                      width: "100%"
                    }}
                  >
                    Select
                  </h4>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    width="100%"
                    id="outlined-select"
                    select
                    margin="dense"
                    style={{}}
                    required
                    disabled={this.state.showFilters}
                    label="Organization"
                    className={classes.textField}
                    value={this.state.entity}
                    onChange={evt=> this.props.values.handeChange(evt.target.value)/*this.handleChange("entity")*/}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    InputProps={{ className: classes.input }}
                    InputLabelProps={{
                      className: classes.label
                    }}
                    variant="outlined"
                  >
                    {this.state.entityData.map(option => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    width="100%"
                    id="outlined-select"
                    select
                    margin="dense"
                    disabled={
                      this.state.showFilters ||
                      this.state.locationData.length === 0
                    }
                    required
                    label="Campus"
                    className={classes.textField}
                    value={this.state.loc}
                    onChange={this.handleChange("loc")}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    InputProps={{ className: classes.input }}
                    InputLabelProps={{
                      className: classes.label
                    }}
                    variant="outlined"
                  >
                    {this.state.locationData.map(option => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    width="100%"
                    id="outlined-select"
                    select
                    margin="dense"
                    disabled={
                      this.state.showFilters ||
                      this.state.collectionData.length === 0
                    }
                    required
                    style={{}}
                    label="User Group/Department"
                    className={classes.textField}
                    value={this.state.dept}
                    onChange={this.handleChange("dept")}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    InputProps={{ className: classes.input }}
                    InputLabelProps={{
                      className: classes.label
                    }}
                    variant="outlined"
                  >
                    {this.state.collectionData.map(option => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {false &&<Grid item xs={3}></Grid>}
                <Grid
                  item
                  xs={1}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Tooltip title="Reset Filters">
                    <IconButton
                      style={{ color: "#4b2e83" }}
                      aria-label="Reset Filters"
                      onClick={this.handleReset}
                    >
                      <ResetIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Button
                    onClick={this.handleNext}
                    disabled={
                      this.state.showFilters || this.state.disableSubmit
                    }
                    style={
                      !this.state.showFilters && !this.state.disableSubmit
                        ? {
                            color: "#4b2e83",
                            opacity: "1",
                            outline: "none"
                          }
                        : { color: "#4b2e83", opacity: "0.4" }
                    }
                    //className="buttonnobg float-center"
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Paper>
          <Collapse in={this.state.showUnassigned}>
            {this.state.loadingunMapped ? (
              <div style={{ marginTop: "10px" }}>
                <TableUnMapped
                  users={this.state.users}
                  selectedUsers={this.handleSelected}
                />
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  marginTop: 150,
                  marginBottom: 150,
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <CircularProgress
                  style={{ width: "80px", height: "80px", color: "#4b2e83" }}
                  className={classes.progress}
                />
                <h4 style={{ marginTop: "25px", marginLeft: "5px" }}>
                  Loading Unassigned Users
                </h4>
              </div>
            )}
          </Collapse>

          {this.state.showAlreadyassgined ? (
            <div>
              {this.state.loadingMapped ? (
                <div style={{ marginTop: "20px" }}>
                  <TableMapped
                    data={this.state.MappedUserData}
                    fetchUsers={this.fetchMappedUsers}
                    deptId={this.state.dept}
                  />
                </div>
              ) : (
                <div
                  style={{
                    width: "100%",
                    marginTop: 150,
                    marginBottom: 150,
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <CircularProgress
                    style={{ width: "80px", height: "80px", color: "#4b2e83" }}
                    className={classes.progress}
                  />
                  <h4 style={{ marginTop: "25px", marginLeft: "5px" }}>
                    Loading Assigned Users
                  </h4>
                </div>
              )}
            </div>
          ) : (
            <Paper
              style={{
                marginTop: "20px",
                width: "100%",
                height: "300px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 30
              }}
            >
              <h4>
                Please select the Organization, Campus and
                Department to see already assigned users
              </h4>
            </Paper>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { entities, locations, collections } = state;
  return {
    entities,
    locations,
    collections
  };
};

const mapDispatchToProps = dispatch => {
  return {
    storeEntity: () => getEntity(dispatch),
    getLocation: entityId => getLocations(dispatch, entityId),
    getCollection: (entityId, locationId = "") =>
      getCollections(dispatch, entityId, locationId),
    resetData: () => dispatch(cleanData()),
    showMessageBox: message => dispatch(showMessage(message))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(ManageDevices);
