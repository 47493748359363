import React, { Component } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import ResetIcon from "@material-ui/icons/Refresh";
import ResetPwd from "@material-ui/icons/VpnKey";
import SubmitIcon from "@material-ui/icons/CheckCircleOutline";
import Tooltip from "@material-ui/core/Tooltip";
import Toolbar from "@material-ui/core/Toolbar";
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { apiRequest, isPortalAdmin, atLastPageScrollToTop, PASSWORD_REGEX, PASSWORD_ERROR } from "../utils/Utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import { showMessage } from "../actions/index";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import PropTypes from "prop-types";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import BackIcon from "@material-ui/icons/ArrowBackIos";
import Collapse from "@material-ui/core/Collapse";
import AlertDialog from "../components/AlertDialog";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Link } from "react-router-dom";
import DropButton from "../components/DropButton";

const tableHeader = [
  { id: "0", label: "Resource Name" },
  { id: "1", label: "Resource Type" },
  { id: "2", label: "Access Level" },
  { id: "3", label: "Action" }
];

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
    paddingTop: 10,
    paddingBottom: 10
  },
  // input: {
  //   borderRadius: "2px",
  //   width: "100%",
  //   padding: "5px",
  //   borderRadius: "5px",
  //   outline: "none",
  //   border: "1px solid lightgrey",
  // },
  textField: { width: "100%" },
  menu: {
    width: "100%"
  },
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 2
  },
  root1: {
    width: "100%",
    marginTop: 5,
    marginBottom: 35
  },
  table: {},
  tableWrapper: {
    overflowX: "auto"
  },
  noPadding: {
    padding: 0
  }
});

const rows = [
  { id: "UserImage", disablePadding: false, label: "IMAGE" },
  { id: "FullName", disablePadding: false, label: "NAME" },
  { id: "UserId", disablePadding: false, label: "USER NAME" },
  { id: "Email", disablePadding: false, label: "EMAIL" },
  { id: "Admin", disablePadding: false, label: "ADMIN" },
  { id: "ReadOnly", disablePadding: false, label: "READONLY" },
  { id: "Auth", disablePadding: false, label: "AUTH" }
];

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

function desc(a, b, orderBy) {
  let cmpa = a[orderBy] ? a[orderBy].toLowerCase() : "";
  let cmpb = b[orderBy] ? b[orderBy].toLowerCase() : "";
  if (cmpb < cmpa) {
    return -1;
  }
  if (cmpb > cmpa) {
    return 1;
  }
  return 0;
}

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount
    } = this.props;

    return (
      <TableHead>
        <TableRow style={{ height: 45 }}>
          {rows.map(
            row => (
              <TableCell
                style={{ color: "#4b2e83", whiteSpace: "nowrap" }}
                key={row.id}
                align={row.numeric ? "right" : "left"}
                padding={row.disablePadding ? "none" : "default"}
                sortDirection={orderBy === row.id ? order : false}
              >
                {row.id != "map" && row.id != "UserImage" ? (
                  <Tooltip
                    title="Sort"
                    placement={row.numeric ? "bottom-end" : "bottom-start"}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      style={{ color: "#4b2e83" }}
                      active={orderBy === row.id}
                      direction={order}
                      onClick={this.createSortHandler(row.id)}
                    >
                      {row.label}
                    </TableSortLabel>
                  </Tooltip>
                ) : (
                  row.label
                )}
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  numSelected: PropTypes.number.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

class UserPermissionsAdminView extends Component {
  state = {
    order: "asc",
    orderBy: "sno",
    page: 0,
    rowsPerPage: 5,
    isHiddenDetails: true,
    isLoaded: false,
    users: [],
    filteredUsers: [],
    searchText: "",
    selectedUser: "",
    name: "",
    imageUrl: "",
    email: "",
    permissions: [],
    open: false,
    openAlert: false,
    actionType: "",
    rowToDelete: {},
    openNewPwd: false,
    newPwd: "",
    cnfPwd: "",
    passwordType: "random_password"
  };

  componentWillMount() {
    // this.fetchUsers();
  }

  componentDidMount() {
    // this.setState({
    //   isLoaded: true
    // });
    this.fetchUsers();
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  fetchUsers() {
    // this.setState({
    //   isLoaded: false
    // })
    apiRequest("/users?is_active=true", "get")
      .then(x => {
        //Build Admin, Readonly Perm lists
        for (var i = 0; i < x.data.length; i++) {
          var Admin = [];
          var ReadOnly = [];
          for (var j = 0; j < x.data[i].Permissiones.length; j++) {
            if (x.data[i].Permissiones[j].PermissionGroupLevel === 500) {
              Admin.push(x.data[i].Permissiones[j].ResourceInfo.Name);
            } else if (x.data[i].Permissiones[j].PermissionGroupLevel === 100) {
              ReadOnly.push(x.data[i].Permissiones[j].ResourceInfo.Name);
            }
          }
          x.data[i].Admin = Admin.toString();
          x.data[i].ReadOnly = ReadOnly.toString();
          x.data[i].Auth = x.data[i].IsOfficeUser ? "O365" : "Local";
        }

        this.setState({
          users: x.data,
          filteredUsers: x.data,
          isLoaded: true
        });
      })
      .catch(err => console.log(err));
  }

  selectUser = event => {
    this.setState({
      selectedUser: event.target.value
    });
  };

  setSearchText = e => {
    this.setState({
      searchText: e.target.value,
      page: 0
    });
    this.setState({
      filteredUsers:
        e.target.value === ""
          ? this.state.users
          : this.state.users.filter(
              user =>
                user.UserId.toLowerCase().includes(
                  e.target.value.toLowerCase()
                ) ||
                user.FullName.toLowerCase().includes(
                  e.target.value.toLowerCase()
                ) ||
                user.Email.toLowerCase().includes(
                  e.target.value.toLowerCase()
                ) ||
                user.Admin
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase()) ||
                user.ReadOnly
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase()) ||
                //AUTH
                (user.IsOfficeUser && e.target.value.toLowerCase() == "o365") ||
                (!user.IsOfficeUser && e.target.value.toLowerCase() == "local")
                // user.Auth
                //   .toLowerCase()
                //   .includes(e.target.value.toLowerCase())
            )
    });
    if (this.wait != "") clearTimeout(this.wait);

    this.wait = setTimeout(() => {
      this.setState({ searchText2: this.state.searchText });
    }, 700);
  };

  fetchUserData(id) {
    /* if (id == "") {
      this.props.showMessageBox("Please select a user");
      return;
    } */

    const isValidUser = this.state.users.filter(user => user.UserId == id);
    if (isValidUser.length == 0) {
      this.props.showMessageBox("User not found");
      return;
    }
    this.setState({
      isLoaded: false
    });
    apiRequest(`/users/${id}`, "get")
      .then(response => {
        this.setState({
          name: response.data[0].FullName,
          imageUrl: response.data[0].ImageURLSmall,
          email: response.data[0].Email,
          permissions: response.data[0].Permissiones,
          userName: response.data[0].UserId,
          isO365User: response.data[0].IsOfficeUser
        });
        this.setState({
          open: true,
          isLoaded: true
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  handleReset = () => {
    this.setState({
      selectedUser: "",
      open: false,
      isHiddenDetails: true
    });
  };

  handleBackButton = () => {
    this.toggleHiddenDetails("false");
    this.setState({
      open: false
    });
  };

  toggleHiddenDetails(selectedUserDetail) {
    this.setState({
      isHiddenDetails: !this.state.isHiddenDetails
    });
    if (this.state.isHiddenDetails) {
      window.scrollTo(0, 0);
    }

    this.setState({
      selectedUserDetail
    });
  }

  isSelected = id => this.state.selectedUser.indexOf(id) !== -1;

  handleChangePage = (event, page) => {
    this.setState({ page }, ()=>{
      atLastPageScrollToTop(this.state.filteredUsers.length, this.state.rowsPerPage, page);
    });
  };

  handleChangeRowsPerPage = event => {
    if(event.target.value * this.state.page > this.state.filteredUsers.length && this.state.page != 0)
      this.setState({ rowsPerPage: event.target.value, page: 0 });
    else
      this.setState({ rowsPerPage: event.target.value });
  };

  handleDelete = () => {
    const { MembershipId } = this.state.rowToDelete;
    const { ControlLevel } = this.state.rowToDelete.ResourceInfo;
    this.setState(
      {
        openAlert: false
      },
      () => {
        setTimeout(() => {
          this.setState({
            actionType: ""
          });
        }, 500);
      }
    );
    if (ControlLevel >= 500) {
      apiRequest(`/accesscontrol/${MembershipId}`, "delete")
        .then(x => {
          if (x.status > 0) {
            this.fetchUserData(this.state.selectedUser);
          }
          if (x.status > 0 && x.message == null)
            this.props.showMessageBox("SUCCESS");
          else this.props.showMessageBox(x.message);
        })
        .catch(err => console.log(err));
    } else {
      this.props.showMessageBox("Access Denied");
    }
  };

  handleClick = (event, id) => {
    this.setState({ selectedUser: id, isHiddenDetails: false }, ()=> this.fetchUserData(id));
    //this.fetchUserData(id);
  };

  handleResetPassword = () => {
    const { newPwd, cnfPwd } = this.state;
    this.setState(
      {
        openAlert: false
      },
      () => {
        setTimeout(() => {
          this.setState({
            actionType: ""
          });
        }, 500);
      }
    );

    let data;

    if (this.state.passwordType == "random_password") {
      data = {
        UserId: this.state.selectedUser,
        NewPasswordBase64: "",
        NewPasswordConfirmBase64: ""
      };
    } else {
      data = {
        UserId: this.state.selectedUser,
        NewPasswordBase64: btoa(newPwd),
        NewPasswordConfirmBase64: btoa(cnfPwd)
      };
    }
    apiRequest(`/users/${this.state.selectedUser}/password/1`, "post", data)
      .then(res => {
        if (res.status > 0) {
          this.props.showMessageBox("SUCCESS");
        } else {
          this.props.showMessageBox(res.message);
        }
        this.setState({
          openNewPwd: false,
          newPwd: "",
          cnfPwd: "",
        });
      })
      .catch(err => {
        this.props.showMessageBox("ERROR");
      });
  };

  handleClose() {
    this.setState({ openNewPwd: false, cnfPwd: "", newPwd: "" });
  }

  handleChange = event => {
    this.setState({
      passwordType: event.target.value
    });
  };

  removeUser = () => {
    this.setState(
      {
        openAlert: false
      },
      () => {
        setTimeout(() => {
          this.setState({
            actionType: ""
          });
        }, 500);
      }
    );

    apiRequest(`/users/${this.state.selectedUser}`, "delete")
      .then(x => {
        if (x.status > 0 && x.message == null) {
          this.fetchUsers();
          this.setState({
            user: ""
          });
          this.props.showMessageBox("User Removed");
          this.handleReset();
          //this.fetchUserData(this.state.selectedUser);
        } else this.props.showMessageBox(x.message);
      })
      .catch(err => {
        this.props.showMessageBox("ERROR");
      });
  };

  render() {
    const { users, name, imageUrl, email, permissions, userName, isO365User } = this.state;
    const emptyRows =
      this.state.rowsPerPage -
      Math.min(
        this.state.rowsPerPage,
        this.state.users.length - this.state.page * this.state.rowsPerPage
      );
    const { classes } = this.props;
    console.log("state", this.state);

    return (
      <div
        id="content"
        style={{
          //paddingTop: "60px",
          transition: "0.25s",
          paddingLeft: "0px"
        }}
      >
        <AlertDialog
          open={this.state.openAlert}
          onClickYes={
            this.state.actionType == "DELETE"
              ? this.handleDelete
              : this.state.actionType == "REMOVE"
              ? this.removeUser
              : this.handleResetPassword
          }
          action={
            this.state.actionType == "DELETE"
              ? "Remove Permission"
              : this.state.actionType == "REMOVE"
              ? "Remove User"
              : "Password Reset"
          }
          close={() => {
            this.setState({ openAlert: false });
          }}
        />

        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={this.state.openNewPwd}
          onClose={() => {
            this.handleClose();
          }}
        >
          <DialogTitle>Set Password</DialogTitle>
          <DialogContent style={{ paddingBottom: 8 }}>
            <RadioGroup
              className={classes.group}
              value={this.state.passwordType}
              onChange={this.handleChange}
              row
            >
              <FormControlLabel
                value="random_password"
                control={<Radio />}
                label="Random Password"
              />
              <FormControlLabel
                value="custom_password"
                control={<Radio />}
                label="Custom Password"
              />
            </RadioGroup>
            <TextField
              id="standard-uncontrolled"
              label="New Password"
              className={classes.textField}
              margin="dense"
              type="password"
              onChange={e => {
                this.setState({ newPwd: e.target.value });
              }}
              disabled={this.state.passwordType == "random_password"}
              value={this.state.newPwd}
            />
            <TextField
              id="standard-uncontrolled"
              label="Confirm Password"
              className={classes.textField}
              margin="dense"
              type="password"
              disabled={this.state.passwordType == "random_password"}
              onChange={e => {
                this.setState({ cnfPwd: e.target.value });
              }}
              value={this.state.cnfPwd}
            />
            <p style={{ fontSize: "12px", color: "red", marginBottom: 4 }}>
              *Password must be between 8-15 characters. Must contains at least
              ONE special characters (!#$_&@%), 1 numeric, 1 UpperCase, 1
              smallcase letters
            </p>
            <p style={{ fontSize: "12px", color: "red", marginBottom: 0 }}>
              *Email with new password will be sent to the User
            </p>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.handleClose();
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                const { newPwd, cnfPwd } = this.state;

                if (this.state.passwordType == "custom_password") {
                  if (newPwd == "" || cnfPwd == "") {
                    this.props.showMessageBox("Fields should not be empty");
                    return;
                  }
                  else {
                    if (newPwd !== cnfPwd) {
                      this.props.showMessageBox(
                        "Confirm Password should match with New Password"
                      );
                      return;
                    }
                    if(!PASSWORD_REGEX.test(newPwd) || !PASSWORD_REGEX.test(cnfPwd)) {
                      this.props.showMessageBox(PASSWORD_ERROR);
                      return;
                    }
                  }
                }
                this.setState({
                  actionType: "RESET",
                  openAlert: true
                });
              }}
              color="primary"
            >
              Done
            </Button>
          </DialogActions>
        </Dialog>
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-lg-12"
              style={{
                height: "100%",
                width: "100%"
              }}
            >
              <Collapse in={this.state.isHiddenDetails}>
                <Toolbar
                  style={{
                    paddingTop: 3,
                    paddingBottom: 3,
                    paddingLeft: 24, paddingRight: 0
                  }}
                >
                  <div
                    style={{ paddingLeft: 0, paddingTop: "5px" }}
                    className={classes.title}
                  >
                    <h4
                      style={{ color: "#4b2e83" }}
                      variant="h4"
                      id="tableTitle"
                    >
                      Users
                    </h4>
                  </div>
                  <TextField
                    style={{
                      width: "88%",
                      paddingLeft: 30,
                      paddingRight: 30,
                      paddingBottom: "1px"
                    }}
                    value={this.state.searchText}
                    margin="dense"
                    placeholder="Search"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      className: classes.input
                    }}
                    onChange={this.setSearchText}
                  />
                  <DropButton/>
                </Toolbar>
                {!this.state.isLoaded ? (
                <div
                  style={{
                    width: "100%",
                    marginTop: 100,
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <CircularProgress
                    style={{ width: "80px", height: "80px", color: "#4b2e83" }}
                    className={classes.progress}
                  />
                </div>
              ) : (

              
                <Paper
                  className={classes.root1}
                  style={{
                    //padding: "17px",
                    //margin: "15px 12px",
                    // padding: "4px 10px 10px 10px",
                    // margin: "20px 12px"
                  }}
                >
                  {this.state.filteredUsers.length > 0 ? (
                    <div className={classes.tableWrapper}>
                      <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                      >
                        <EnhancedTableHead
                          numSelected={this.state.selectedUser.length}
                          order={this.state.order}
                          orderBy={this.state.orderBy}
                          onRequestSort={this.handleRequestSort}
                          rowCount={this.state.users.length}
                        />

                        <TableBody>
                          {stableSort(
                            this.state.filteredUsers,
                            getSorting(this.state.order, this.state.orderBy)
                          )
                            .slice(
                              this.state.page * this.state.rowsPerPage,
                              this.state.page * this.state.rowsPerPage +
                                this.state.rowsPerPage
                            )
                            .map(n => {
                              const isSelected = this.isSelected(n.UserId);
                              return (
                                <TableRow
                                  hover
                                  aria-checked={isSelected}
                                  tabIndex={-1}
                                  key={n.UserId}
                                  style={{
                                    height: 45 /*whiteSpace: "nowrap"*/
                                  }}
                                  selected={isSelected}
                                  onClick={event =>
                                    this.handleClick(event, n.UserId)
                                  }
                                >
                                  <TableCell
                                  >
                                    <img
                                      className="image"
                                      style={{
                                        borderRadius: "50%"
                                      }}
                                      src={n.ImageURLSmall}
                                      width="30px"
                                      height="30px"
                                      alt=""
                                    />
                                  </TableCell>
                                  <TableCell
                                  >
                                    {n.FullName}
                                  </TableCell>
                                  <TableCell
                                  >
                                    {n.UserId}
                                  </TableCell>
                                  <TableCell
                                  >
                                    {n.Email}
                                  </TableCell>
                                  <TableCell
                                  >
                                    {n.Admin}
                                  </TableCell>
                                  <TableCell
                                  >
                                    {n.ReadOnly}
                                  </TableCell>
                                  <TableCell
                                  >
                                    {/* {n.IsOfficeUser? "O365" : "Local"} */}
                                    {n.Auth}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          {/*emptyRows > 0 && (
                        <TableRow style={{ height: 49 * emptyRows }}>
                          <TableCell colSpan={12} />
                        </TableRow>
                      )*/}
                        </TableBody>
                      </Table>

                      <TablePagination
                    rowsPerPageOptions={[5,10,25, 50, 100]}
                    component="div"
                    count={this.state.filteredUsers.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    backIconButtonProps={{
                      "aria-label": "Previous Page"
                    }}
                    nextIconButtonProps={{
                      "aria-label": "Next Page"
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                    </div>
                  ) : (
                    <div
                      style={{
                        marginTop: "10px",
                        width: "100%",
                        height: "500px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 30
                      }}
                    >
                      <h4>No User Found.</h4>
                    </div>
                  )}
                </Paper>
                )}
              </Collapse>
              <Collapse in={this.state.open}>
                <Paper
                  style={{
                    padding: "4px 10px 20px 10px",
                    margin: "20px 0px"
                  }}
                >
                  {/* <Grid
                  container
                  spacing={16}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 10,
                    marginBottom: 1,
                    marginLeft: 10
                  }}
                >
                  <Grid item xs={12} md={2} lg={2}>
                    <h4 style={{ color: "#4b2e83" }}>Select User</h4>
                  </Grid>
                  <Grid item xs={12} md={4} lg={4}>
                    <input
                      list="text_editors1"
                      className={classes.input}
                      onChange={this.selectUser}
                      value={this.state.selectedUser}
                    />
                    <datalist id="text_editors1" style={{ height: 30 }}>
                      <select multiple>
                        {users.map(user => (
                          <option key={user.UserId} value={user.UserId}>
                            {user.FullName}
                          </option>
                        ))}
                      </select>
                    </datalist>
                  </Grid>
                  <Grid item xs={12} md={2} lg={2}>
                    <Tooltip title="Reset">
                      <IconButton
                        style={{
                          color: "#4b2e83",
                          opacity: "1",
                          outline: "none"
                        }}
                        aria-label="Reset"
                        onClick={() => {
                          this.handleReset();
                        }}
                      >
                        <ResetIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Done">
                      <IconButton
                        style={{
                          color: "#4b2e83",
                          outline: "none"
                        }}
                        aria-label="Done"
                        onClick={() => {
                          this.fetchUserData();
                        }}
                      >
                        <SubmitIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid> 
                </Grid> */}
                  <Grid
                    container
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Grid item lg={6} md={6} xs={12}>
                      <Button
                        //className="button3"
                        style={{
                          color: "#4b2e83",
                          margin: 10,
                          marginLeft: 0,
                          marginTop: 10,
                          paddingTop: 10,
                          paddingBottom: 10
                        }}
                        onClick={this.handleBackButton}
                      >
                        <BackIcon /> Back
                      </Button>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <Button
                        //className="button3"
                        style={
                          isPortalAdmin
                            ? {
                              color: "#4b2e83",
                              float: "right",
                              borderColor: "#4b2e83",
                              opacity: "1",
                              outline: "none",
                              marginLeft: 10
                            }
                            : {
                              color: "#343a40",
                              opacity: "0.4",
                              float: "right",
                              borderColor: "#4b2e83",
                              marginLeft: 10
                            }
                        }
                        disabled={!isPortalAdmin}
                        variant="outlined"
                        onClick={() => {
                          this.setState({
                            actionType: "REMOVE",
                            openAlert: true
                          });
                        }}
                      >
                        REMOVE USER
                      </Button>
                    </Grid>
                  </Grid>
                  <hr />
                  <Grid
                    container
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Grid item lg={1} md={1} xs={12}>
                      <img
                        style={{ /*marginLeft: 20,*/ borderRadius: `50%` }}
                        height={50}
                        width={50}
                        src={imageUrl}
                      />
                    </Grid>
                    <Grid item lg={2} md={2} xs={12} /*style={{ marginLeft: 20 }}*/ >
                      <b>Name:</b>
                      {` ${name}`}
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                      <b>User Name:</b>
                      {` ${userName}`}
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                      <b>Email:</b>
                      {` ${email}`}
                    </Grid>
                    <Grid item lg={1} md={1} xs={12}>
                      <b>Auth: </b>
                      {isO365User? "O365" : "Local"}
                    </Grid>
                  </Grid>

                  <hr />

                  {permissions.length > 0 ? (
                    <React.Fragment>
                      <h4
                        style={{
                          color: "#4b2e83",
                          marginBottom: 25,
                          marginLeft: 20,
                          display: "inline"
                        }}
                      >
                        {`${name}'s Resource Permissions`}
                      </h4>
                      <Button
                        //className="button3"
                        style={
                          isPortalAdmin
                            ? {
                                color: "#4b2e83",
                                float: "right",
                                borderColor: "#4b2e83",
                                opacity: "1",
                                outline: "none"
                              }
                            : {
                                color: "#343a40",
                                opacity: "0.4",
                                float: "right",
                                borderColor: "#4b2e83"
                              }
                        }
                        disabled={!isPortalAdmin || isO365User}
                        hidden={isO365User}
                        variant="outlined"
                        onClick={() => {
                          this.setState({
                            openNewPwd: true
                          });
                        }}
                      >
                        RESET PASSWORD
                      </Button>
                      <hr />
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow style={{ height: 20 }}>
                            {tableHeader.map(row => (
                              <TableCell
                                key={row.id}
                                style={{}}
                                align={row.id == 3 ? "center" : "left"}
                              >
                                {row.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {permissions
                            .filter(item => item.IsActive == true)
                            .map(row => {
                              let policyGroup = "";
                              if (row.PermissionGroupLevel == 700)
                                policyGroup = "Administrator";
                              else if (row.PermissionGroupLevel == 500)
                                policyGroup = "Admin";
                              else if (row.PermissionGroupLevel == 300)
                                policyGroup = "Editor";
                              else if (row.PermissionGroupLevel == 100)
                                policyGroup = "Read Only";
                              return (
                                <TableRow
                                  style={{ height: 40 }}
                                  key={row.MembershipId}
                                >
                                  <TableCell>{row.ResourceInfo.Name}</TableCell>
                                  <TableCell>{row.ResourceInfo.Type}</TableCell>
                                  <TableCell>{policyGroup}</TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    style={{ padding: "0 24px" }}
                                    align="center"
                                  >
                                    <Tooltip title="Remove Membership">
                                      <IconButton
                                        aria-label="Remove Membership"
                                        className={classes.icons}
                                        disabled={!isPortalAdmin}
                                        onClick={() => {
                                          this.setState({
                                            actionType: "DELETE",
                                            openAlert: true,
                                            rowToDelete: row
                                          });
                                        }}
                                        style={
                                          isPortalAdmin
                                            ? {
                                                color: "#4b2e83",
                                                opacity: "1",
                                                outline: "none"
                                              }
                                            : {
                                                color: "#343a40",
                                                opacity: "0.4"
                                              }
                                        }
                                      >
                                        <DeleteIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <h4
                        style={{
                          color: "#4b2e83",
                          marginBottom: 25,
                          marginLeft: 20,
                          display: "inline"
                        }}
                      >
                        {`${name} has no Permissions`}
                      </h4>
                      <Button
                        //className="button3"
                        style={
                          isPortalAdmin
                            ? {
                                color: "#4b2e83",
                                float: "right",
                                borderColor: "#4b2e83",
                                opacity: "1",
                                outline: "none"
                              }
                            : {
                                color: "#343a40",
                                opacity: "0.4",
                                float: "right",
                                borderColor: "#4b2e83"
                              }
                        }
                        disabled={!isPortalAdmin || isO365User}
                        hidden={isO365User}
                        variant="outlined"
                        onClick={() => {
                          this.setState({
                            openNewPwd: true
                          });
                        }}
                      >
                        RESET PASSWORD
                      </Button>
                    </React.Fragment>
                  )}
                </Paper>
              </Collapse>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    showMessageBox: message => dispatch(showMessage(message))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(UserPermissionsAdminView);
